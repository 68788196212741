<footer class="similarProducts">
  <h2>
    {{ ("shop.labels.similar-products" | translate) || "Similar Products" }}
    &hellip;
  </h2>
  <div class="similarProducts--list--wrapper">
    <div class="similarProducts--list" #similarProductWrapper>
      <costes-product-list-item
        *ngFor="let similarProduct of similarProducts"
        [ngClass]="[
          similarProduct.itemSize,
          similarProduct.productImage?.image?.orientation ??
          similarProduct.productImage?.video?.orientation
        ]"
        [data]="similarProduct"
        [showProductImage]="true"
      ></costes-product-list-item>
    </div>
    <button
      *ngIf="!isMobile && hasOverflow"
      [class.hidden]="!(deltaLeft >= 1)"
      (click)="scrollLeft()"
      class="arrow prev"
      [inlineSVG]="'/assets/SVG/Arrow-Left.svg'"
    ></button>
    <button
      *ngIf="!isMobile && hasOverflow"
      [class.hidden]="!(deltaRight >= 1)"
      (click)="scrollRight()"
      class="arrow next"
      [inlineSVG]="'/assets/SVG/Arrow-Right.svg'"
    ></button>
  </div>
</footer>
