import { Component, Input, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { map } from 'rxjs/operators';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ShopState, ToggleCart } from '@costes/library/store/shop';
import { NavigationStateInterface, NavigationState } from '@costes/library/store/app';
import { LanguageState } from '@costes/library/store/language';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';


@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'costes-footer',
  standalone: true,
  imports: [CommonModule, RouterModule, TranslateModule],
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  @Select(LanguageState.getLanguage)
  currentLang$!: Observable<string>;

  onPageNavigation$: Observable<NavigationStateInterface<any[]>>;

  @Select(ShopState.getTotalQty)
  totalQty$!: Observable<number>;

  @Select(ShopState.showCart) showCart$!: Observable<boolean>;

  constructor(private store: Store) {
    this.onPageNavigation$ = this.store
      .select(NavigationState.getNavigation)
      .pipe(map((filterFn: any) => filterFn('onPageMenu')));
  }

  ngOnInit() { }

  toggleCart() {
    this.store.dispatch(new ToggleCart());
  }
}
