import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import {
  State,
  Action,
  StateContext,
  Store,
  NgxsOnInit,
  Selector,
} from '@ngxs/store';
import {
  GetCsrfToken,
  GetAllCurrencies,
  UpdateCart,
  UpdateCartSuccess,
  UpdateCartError,
  RehydrateCart,
  ToggleCart,
  ShowCart,
  HideCart,
  UpdateCurrency,
  AddToCart,
  RegisterUser,
  RegisterUserSuccess,
  LoginUser,
  LoginUserSuccess,
  ApplyCoupon,
  ClearCart,
  DismissCartMessage,
  SetCartMessage,
} from './shop.actions';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError, map, switchMap, tap, take } from 'rxjs/operators';
import { firstValueFrom, throwError } from 'rxjs';
import {
  UpdateCartResponse,
  Cart,
  CartErrors,
  CsrfTokenResponse,
  Currencies,
  CartMessage,
  Currency,
} from 'typings';

import { GetProductCoverGQL } from '@costes/library/graphql';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { isPlatformBrowser } from '@angular/common';
import { LanguageState } from '@costes/library/store/language';
import { ErrorMessage, ErrorMessageDismiss, SendGoogleAnalyticsEvent } from '@costes/library/store/app';

export interface ShopStateModel {
  csrf?:
  | {
    csrfTokenName: string;
    csrfToken: string;
  }
  | CsrfTokenResponse;
  currencies: Currencies;
  cart?: Cart;
  errors?: CartErrors;
  message?: CartMessage;
  userErrors: any;
  showCart: boolean;
}

@State<ShopStateModel>({
  name: 'shop',
  defaults: {
    csrf: undefined,
    currencies: {},
    cart: undefined,
    userErrors: undefined,
    errors: undefined,
    message: undefined,
    showCart: false,
  },
})
@Injectable()
export class ShopState implements NgxsOnInit {
  constructor(
    @Inject(PLATFORM_ID)
    private platformId: Object,
    private http: HttpClient,
    private store: Store,
    private loadingBar: LoadingBarService,
    private getProductCoverGQL: GetProductCoverGQL
  ) { }

  async ngxsOnInit({
    getState,
    patchState,
    dispatch,
  }: StateContext<ShopStateModel>) {
    await dispatch(new GetAllCurrencies()).toPromise();

    if (!isPlatformBrowser(this.platformId)) return;
    await dispatch(new GetCsrfToken()).toPromise();
    const state = getState();
    if (state.cart && state.cart.number)
      dispatch(new RehydrateCart(state.cart.number));
    else
      patchState({
        cart: undefined,
        userErrors: undefined,
        errors: undefined,
        showCart: false,
      });

    // patchState({
    //   csrf: {
    //     csrfTokenName: "CRAFT_CSRF_TOKEN",
    //     csrfToken: "0QWaFObGVIYkeQ7ArKmSurmVqUKSmDGHh7XMbibG042RN58b0u9D1OZD-1aVpQGyHQ1Z-Prsq4rM858p6NRF_u78lQZC8oL46FisebSAAqM=",
    //   },
    //   cart: {
    //     number: "6a85217027440cb6d0b448c89e59ef86",
    //     reference: null,
    //     couponCode: null,
    //     isCompleted: false,
    //     dateOrdered: null,
    //     datePaid: null,
    //     dateAuthorized: null,
    //     currency: "EUR",
    //     gatewayId: 3,
    //     lastIp: "87.150.127.208",
    //     message: null,
    //     returnUrl: null,
    //     cancelUrl: null,
    //     orderStatusId: null,
    //     orderLanguage: "en",
    //     origin: "web",
    //     billingAddressId: null,
    //     shippingAddressId: null,
    //     makePrimaryShippingAddress: null,
    //     makePrimaryBillingAddress: null,
    //     shippingSameAsBilling: false,
    //     billingSameAsShipping: false,
    //     estimatedBillingAddressId: null,
    //     estimatedShippingAddressId: null,
    //     estimatedBillingSameAsShipping: false,
    //     shippingMethodHandle: null,
    //     customerId: 265,
    //     registerUserOnOrderComplete: null,
    //     paymentSourceId: null,
    //     storedTotalPrice: "130.5000",
    //     storedTotalPaid: "0.0000",
    //     storedItemTotal: "130.5000",
    //     storedTotalShippingCost: "0.0000",
    //     storedTotalDiscount: "0.0000",
    //     storedTotalTax: "0.0000",
    //     storedTotalTaxIncluded: "6.8000",
    //     id: 7785,
    //     tempId: null,
    //     draftId: null,
    //     revisionId: null,
    //     uid: "dab8ae1b-22a9-4e65-a7ac-de27748a0109",
    //     fieldLayoutId: 61,
    //     contentId: "13466",
    //     enabled: "1",
    //     archived: "0",
    //     siteId: "2",
    //     title: null,
    //     slug: null,
    //     uri: null,
    //     dateCreated: {
    //       date: "6/24/2020",
    //       time: "2:56 PM",
    //     },
    //     dateUpdated: {
    //       date: "6/24/2020",
    //       time: "2:59 PM",
    //     },
    //     dateDeleted: null,
    //     trashed: false,
    //     propagateAll: false,
    //     newSiteIds: [],
    //     resaving: false,
    //     duplicateOf: null,
    //     previewing: false,
    //     hardDelete: false,
    //     ref: null,
    //     status: "enabled",
    //     structureId: null,
    //     url: null,
    //     collissimoTrackingNumber: null,
    //     shipmentTrackingUrl: "",
    //     orderCancellingReason: {
    //       label: "None",
    //       value: "none",
    //       selected: true,
    //     },
    //     orderCancellingMessage: null,
    //     adjustmentSubtotal: 0,
    //     adjustmentsTotal: 0,
    //     paymentCurrency: "EUR",
    //     email: "",
    //     isPaid: false,
    //     itemSubtotal: 823.5,
    //     itemTotal: 823.5,
    //     lineItems: [
    //       {
    //         id: 116,
    //         weight: 0,
    //         length: 0,
    //         height: 0,
    //         width: 0,
    //         qty: 5,
    //         note: "",
    //         privateNote: "",
    //         purchasableId: "6653",
    //         orderId: 7785,
    //         lineItemStatusId: null,
    //         taxCategoryId: 2,
    //         shippingCategoryId: 1,
    //         dateCreated: "2020-06-24T14:59:02+02:00",
    //         adjustments: [
    //           {
    //             id: 3410,
    //             name: "TVA (Taux Reduit)",
    //             description: "5.5% inc",
    //             type: "tax",
    //             amount: 4.689999999999998,
    //             included: true,
    //             orderId: 7785,
    //             lineItemId: 116,
    //             isEstimated: true,
    //             sourceSnapshot: {
    //               id: "4",
    //               name: "TVA (Taux Reduit)",
    //               code: "vatReducedRate",
    //               rate: "0.0550000000",
    //               include: "1",
    //               isVat: "1",
    //               taxable: "price",
    //               taxCategoryId: "2",
    //               isLite: null,
    //               taxZoneId: "2",
    //             },
    //             amountAsCurrency: "€4.69",
    //           },
    //         ],
    //         description: "SHAMPOOING",
    //         options: [],
    //         optionsSignature: "d751713988987e9331980363e24189ce",
    //         onSale: true,
    //         price: 20,
    //         saleAmount: 2,
    //         salePrice: 18,
    //         sku: "COSTES-00014",
    //         total: 90,
    //         priceAsCurrency: "€20",
    //         saleAmountAsCurrency: "€2",
    //         salePriceAsCurrency: "€18",
    //         subtotalAsCurrency: "€90",
    //         totalAsCurrency: "€90",
    //         subtotal: 90,
    //         snapshot: {
    //           productId: "6652",
    //           isDefault: "1",
    //           sku: "COSTES-00014",
    //           price: 20,
    //           sortOrder: "1",
    //           width: null,
    //           height: null,
    //           length: null,
    //           weight: null,
    //           stock: "0",
    //           hasUnlimitedStock: "1",
    //           minQty: null,
    //           maxQty: null,
    //           deletedWithProduct: false,
    //           id: "6653",
    //           tempId: null,
    //           draftId: null,
    //           revisionId: null,
    //           uid: "e853da10-97a4-42db-bb9a-69d60d98031d",
    //           fieldLayoutId: null,
    //           contentId: "11090",
    //           enabled: "1",
    //           archived: "0",
    //           siteId: "2",
    //           title: "SHAMPOOING",
    //           slug: null,
    //           uri: null,
    //           dateCreated: "2020-05-13T12:21:16+02:00",
    //           dateUpdated: "2020-05-13T12:22:03+02:00",
    //           dateDeleted: null,
    //           trashed: false,
    //           propagateAll: false,
    //           newSiteIds: [],
    //           resaving: false,
    //           duplicateOf: null,
    //           previewing: false,
    //           hardDelete: false,
    //           ref: null,
    //           status: "enabled",
    //           structureId: null,
    //           url: "https://preview.hotelcostes.ynm.studio/en/shop/products/shampooing?variant=6653",
    //           isAvailable: true,
    //           isPromotable: true,
    //           shippingCategoryId: 1,
    //           taxCategoryId: 2,
    //           product: {
    //             postDate: "2020-05-13T12:21:00+02:00",
    //             expiryDate: null,
    //             typeId: "5",
    //             taxCategoryId: "2",
    //             shippingCategoryId: "1",
    //             promotable: "1",
    //             freeShipping: "0",
    //             enabled: "1",
    //             availableForPurchase: "1",
    //             defaultVariantId: "6653",
    //             defaultSku: "COSTES-00014",
    //             defaultPrice: "20.0000",
    //             defaultHeight: "0.0000",
    //             defaultLength: "0.0000",
    //             defaultWidth: "0.0000",
    //             defaultWeight: "0.0000",
    //             taxCategory: null,
    //             name: null,
    //             id: "6652",
    //             tempId: null,
    //             draftId: null,
    //             revisionId: null,
    //             uid: "89929bd6-85fd-47eb-8636-917682765c0f",
    //             fieldLayoutId: "68",
    //             contentId: "11093",
    //             archived: "0",
    //             siteId: "2",
    //             title: "SHAMPOOING",
    //             slug: "shampooing",
    //             uri: "shop/products/shampooing",
    //             dateCreated: "2020-05-13T12:21:16+02:00",
    //             dateUpdated: "2020-05-13T12:22:03+02:00",
    //             dateDeleted: null,
    //             trashed: false,
    //             propagateAll: false,
    //             newSiteIds: [],
    //             resaving: false,
    //             duplicateOf: null,
    //             previewing: false,
    //             hardDelete: false,
    //             ref: null,
    //             status: "live",
    //             structureId: null,
    //             url: "https://preview.hotelcostes.ynm.studio/en/shop/products/shampooing",
    //           },
    //           onSale: true,
    //           cpEditUrl: "#",
    //           description: "SHAMPOOING",
    //           purchasableId: "6653",
    //           options: [],
    //           sales: [
    //             {
    //               id: "1",
    //               name: "Summer Sale",
    //               description: "Save 10% on all our products this summer",
    //               dateFrom: null,
    //               dateTo: null,
    //               apply: "byPercent",
    //               applyAmount: "-0.1000",
    //               ignorePrevious: null,
    //               stopProcessing: null,
    //               allGroups: "1",
    //               allPurchasables: "1",
    //               allCategories: "1",
    //               categoryRelationshipType: "element",
    //               enabled: "1",
    //               sortOrder: null,
    //             },
    //           ],
    //         },
    //       },
    //       {
    //         id: 115,
    //         weight: 0,
    //         length: 0,
    //         height: 0,
    //         width: 0,
    //         qty: 1,
    //         note: "",
    //         privateNote: "",
    //         purchasableId: "6625",
    //         orderId: 7785,
    //         lineItemStatusId: null,
    //         taxCategoryId: 2,
    //         shippingCategoryId: 1,
    //         dateCreated: "2020-06-24T14:56:52+02:00",
    //         adjustments: [
    //           {
    //             id: 3411,
    //             name: "TVA (Taux Reduit)",
    //             description: "5.5% inc",
    //             type: "tax",
    //             amount: 2.1099999999999994,
    //             included: true,
    //             orderId: 7785,
    //             lineItemId: 115,
    //             isEstimated: true,
    //             sourceSnapshot: {
    //               id: "4",
    //               name: "TVA (Taux Reduit)",
    //               code: "vatReducedRate",
    //               rate: "0.0550000000",
    //               include: "1",
    //               isVat: "1",
    //               taxable: "price",
    //               taxCategoryId: "2",
    //               isLite: null,
    //               taxZoneId: "2",
    //             },
    //             amountAsCurrency: "€2.11",
    //           },
    //         ],
    //         description: "PARFUM D’AMBIANCE BLANC",
    //         options: [],
    //         optionsSignature: "d751713988987e9331980363e24189ce",
    //         onSale: true,
    //         price: 45,
    //         saleAmount: 4.5,
    //         salePrice: 40.5,
    //         sku: "COSTES-00011",
    //         total: 40.5,
    //         priceAsCurrency: "€45",
    //         saleAmountAsCurrency: "€4.50",
    //         salePriceAsCurrency: "€40.50",
    //         subtotalAsCurrency: "€40.50",
    //         totalAsCurrency: "€40.50",
    //         subtotal: 40.5,
    //         snapshot: {
    //           productId: "6624",
    //           isDefault: "1",
    //           sku: "COSTES-00011",
    //           price: 45,
    //           sortOrder: "1",
    //           width: null,
    //           height: null,
    //           length: null,
    //           weight: null,
    //           stock: "0",
    //           hasUnlimitedStock: "1",
    //           minQty: null,
    //           maxQty: null,
    //           deletedWithProduct: false,
    //           id: "6625",
    //           tempId: null,
    //           draftId: null,
    //           revisionId: null,
    //           uid: "bc10b939-07a2-4446-b09d-ebd461fb13e9",
    //           fieldLayoutId: null,
    //           contentId: "11002",
    //           enabled: "1",
    //           archived: "0",
    //           siteId: "2",
    //           title: "PARFUM D’AMBIANCE BLANC",
    //           slug: null,
    //           uri: null,
    //           dateCreated: "2020-05-13T12:13:38+02:00",
    //           dateUpdated: "2020-05-13T12:13:38+02:00",
    //           dateDeleted: null,
    //           trashed: false,
    //           propagateAll: false,
    //           newSiteIds: [],
    //           resaving: false,
    //           duplicateOf: null,
    //           previewing: false,
    //           hardDelete: false,
    //           ref: null,
    //           status: "enabled",
    //           structureId: null,
    //           url: "https://preview.hotelcostes.ynm.studio/en/shop/products/parfum-dambiance-blanc?variant=6625",
    //           isAvailable: true,
    //           isPromotable: true,
    //           shippingCategoryId: 1,
    //           taxCategoryId: 2,
    //           product: {
    //             postDate: "2020-05-13T12:13:00+02:00",
    //             expiryDate: null,
    //             typeId: "5",
    //             taxCategoryId: "2",
    //             shippingCategoryId: "1",
    //             promotable: "1",
    //             freeShipping: "0",
    //             enabled: "1",
    //             availableForPurchase: "1",
    //             defaultVariantId: "6625",
    //             defaultSku: "COSTES-00011",
    //             defaultPrice: "45.0000",
    //             defaultHeight: "0.0000",
    //             defaultLength: "0.0000",
    //             defaultWidth: "0.0000",
    //             defaultWeight: "0.0000",
    //             taxCategory: null,
    //             name: null,
    //             id: "6624",
    //             tempId: null,
    //             draftId: null,
    //             revisionId: null,
    //             uid: "629280ab-7941-43b5-b286-3602513e1f43",
    //             fieldLayoutId: "68",
    //             contentId: "11005",
    //             archived: "0",
    //             siteId: "2",
    //             title: "PARFUM D’AMBIANCE BLANC",
    //             slug: "parfum-dambiance-blanc",
    //             uri: "shop/products/parfum-dambiance-blanc",
    //             dateCreated: "2020-05-13T12:13:38+02:00",
    //             dateUpdated: "2020-05-13T12:13:38+02:00",
    //             dateDeleted: null,
    //             trashed: false,
    //             propagateAll: false,
    //             newSiteIds: [],
    //             resaving: false,
    //             duplicateOf: null,
    //             previewing: false,
    //             hardDelete: false,
    //             ref: null,
    //             status: "live",
    //             structureId: null,
    //             url: "https://preview.hotelcostes.ynm.studio/en/shop/products/parfum-dambiance-blanc",
    //           },
    //           onSale: true,
    //           cpEditUrl: "#",
    //           description: "PARFUM D’AMBIANCE BLANC",
    //           purchasableId: "6625",
    //           options: [],
    //           sales: [
    //             {
    //               id: "1",
    //               name: "Summer Sale",
    //               description: "Save 10% on all our products this summer",
    //               dateFrom: null,
    //               dateTo: null,
    //               apply: "byPercent",
    //               applyAmount: "-0.1000",
    //               ignorePrevious: null,
    //               stopProcessing: null,
    //               allGroups: "1",
    //               allPurchasables: "1",
    //               allCategories: "1",
    //               categoryRelationshipType: "element",
    //               enabled: "1",
    //               sortOrder: null,
    //             },
    //           ],
    //         },
    //       },
    //       {
    //         id: 117,
    //         weight: 0,
    //         length: 0,
    //         height: 0,
    //         width: 0,
    //         qty: 1,
    //         note: "",
    //         privateNote: "",
    //         purchasableId: "6681",
    //         orderId: 7785,
    //         lineItemStatusId: null,
    //         taxCategoryId: 2,
    //         shippingCategoryId: 1,
    //         dateCreated: "2020-06-24T14:59:04+02:00",
    //         adjustments: [
    //           {
    //             id: 3412,
    //             name: "TVA (Taux Reduit)",
    //             description: "5.5% inc",
    //             type: "tax",
    //             amount: 36.129999999999995,
    //             included: true,
    //             orderId: 7785,
    //             lineItemId: 117,
    //             isEstimated: true,
    //             sourceSnapshot: {
    //               id: "4",
    //               name: "TVA (Taux Reduit)",
    //               code: "vatReducedRate",
    //               rate: "0.0550000000",
    //               include: "1",
    //               isVat: "1",
    //               taxable: "price",
    //               taxCategoryId: "2",
    //               isLite: null,
    //               taxZoneId: "2",
    //             },
    //             amountAsCurrency: "€36.13",
    //           },
    //         ],
    //         description: "SAC 48H",
    //         options: [],
    //         optionsSignature: "d751713988987e9331980363e24189ce",
    //         onSale: true,
    //         price: 770,
    //         saleAmount: 77,
    //         salePrice: 693,
    //         sku: "COSTES-00022",
    //         total: 693,
    //         priceAsCurrency: "€770",
    //         saleAmountAsCurrency: "€77",
    //         salePriceAsCurrency: "€693",
    //         subtotalAsCurrency: "€693",
    //         totalAsCurrency: "€693",
    //         subtotal: 693,
    //         snapshot: {
    //           productId: "6680",
    //           isDefault: "1",
    //           sku: "COSTES-00022",
    //           price: 770,
    //           sortOrder: "1",
    //           width: null,
    //           height: null,
    //           length: null,
    //           weight: null,
    //           stock: "0",
    //           hasUnlimitedStock: "1",
    //           minQty: null,
    //           maxQty: null,
    //           deletedWithProduct: false,
    //           id: "6681",
    //           tempId: null,
    //           draftId: null,
    //           revisionId: null,
    //           uid: "1dc92551-5582-4ee9-8447-7dbabd22882b",
    //           fieldLayoutId: null,
    //           contentId: "11171",
    //           enabled: "1",
    //           archived: "0",
    //           siteId: "2",
    //           title: "SAC 48H",
    //           slug: null,
    //           uri: null,
    //           dateCreated: "2020-05-13T12:33:50+02:00",
    //           dateUpdated: "2020-05-13T12:33:51+02:00",
    //           dateDeleted: null,
    //           trashed: false,
    //           propagateAll: false,
    //           newSiteIds: [],
    //           resaving: false,
    //           duplicateOf: null,
    //           previewing: false,
    //           hardDelete: false,
    //           ref: null,
    //           status: "enabled",
    //           structureId: null,
    //           url: "https://preview.hotelcostes.ynm.studio/en/shop/products/sac-48h?variant=6681",
    //           isAvailable: true,
    //           isPromotable: true,
    //           shippingCategoryId: 1,
    //           taxCategoryId: 2,
    //           product: {
    //             postDate: "2020-05-13T12:33:00+02:00",
    //             expiryDate: null,
    //             typeId: "5",
    //             taxCategoryId: "2",
    //             shippingCategoryId: "1",
    //             promotable: "1",
    //             freeShipping: "0",
    //             enabled: "1",
    //             availableForPurchase: "1",
    //             defaultVariantId: "6681",
    //             defaultSku: "COSTES-00022",
    //             defaultPrice: "770.0000",
    //             defaultHeight: "0.0000",
    //             defaultLength: "0.0000",
    //             defaultWidth: "0.0000",
    //             defaultWeight: "0.0000",
    //             taxCategory: null,
    //             name: null,
    //             id: "6680",
    //             tempId: null,
    //             draftId: null,
    //             revisionId: null,
    //             uid: "b70b23f1-8d77-4c3d-a40f-bf385e114e38",
    //             fieldLayoutId: "68",
    //             contentId: "11174",
    //             archived: "0",
    //             siteId: "2",
    //             title: "SAC 48H",
    //             slug: "sac-48h",
    //             uri: "shop/products/sac-48h",
    //             dateCreated: "2020-05-13T12:33:50+02:00",
    //             dateUpdated: "2020-05-13T12:33:51+02:00",
    //             dateDeleted: null,
    //             trashed: false,
    //             propagateAll: false,
    //             newSiteIds: [],
    //             resaving: false,
    //             duplicateOf: null,
    //             previewing: false,
    //             hardDelete: false,
    //             ref: null,
    //             status: "live",
    //             structureId: null,
    //             url: "https://preview.hotelcostes.ynm.studio/en/shop/products/sac-48h",
    //           },
    //           onSale: true,
    //           cpEditUrl: "#",
    //           description: "SAC 48H",
    //           purchasableId: "6681",
    //           options: [],
    //           sales: [
    //             {
    //               id: "1",
    //               name: "Summer Sale",
    //               description: "Save 10% on all our products this summer",
    //               dateFrom: null,
    //               dateTo: null,
    //               apply: "byPercent",
    //               applyAmount: "-0.1000",
    //               ignorePrevious: null,
    //               stopProcessing: null,
    //               allGroups: "1",
    //               allPurchasables: "1",
    //               allCategories: "1",
    //               categoryRelationshipType: "element",
    //               enabled: "1",
    //               sortOrder: null,
    //             },
    //           ],
    //         },
    //       },
    //     ],
    //     orderAdjustments: [
    //       {
    //         id: 3413,
    //         name: "TVA (Frais de Port)",
    //         description: "20% inc",
    //         type: "tax",
    //         amount: 0,
    //         included: true,
    //         orderId: 7785,
    //         lineItemId: null,
    //         isEstimated: true,
    //         sourceSnapshot: {
    //           id: "6",
    //           name: "TVA (Frais de Port)",
    //           code: "tvaFraisDePort",
    //           rate: "0.2000000000",
    //           include: "1",
    //           isVat: "1",
    //           taxable: "order_total_shipping",
    //           taxCategoryId: null,
    //           isLite: null,
    //           taxZoneId: "2",
    //         },
    //         amountAsCurrency: 0,
    //       },
    //     ],
    //     outstandingBalance: 823.5,
    //     paidStatus: "unpaid",
    //     recalculationMode: "all",
    //     shortNumber: "6a85217",
    //     totalPaid: 0,
    //     total: 823.5,
    //     totalPrice: 823.5,
    //     totalQty: 7,
    //     totalSaleAmount: 91.5,
    //     totalTaxablePrice: 780.57,
    //     totalWeight: 0,
    //     adjustmentSubtotalAsCurrency: "€0.00",
    //     adjustmentsTotalAsCurrency: "€0.00",
    //     itemSubtotalAsCurrency: "€823.50",
    //     itemTotalAsCurrency: "€823.50",
    //     outstandingBalanceAsCurrency: "€823.50",
    //     totalPaidAsCurrency: "€0.00",
    //     totalAsCurrency: "€823.50",
    //     totalPriceAsCurrency: "€823.50",
    //     totalSaleAmountAsCurrency: "€91.50",
    //     totalTaxablePriceAsCurrency: "€780.57",
    //     totalTaxAsCurrency: "€0.00",
    //     totalTaxIncludedAsCurrency: "€42.93",
    //     totalShippingCostAsCurrency: "€0.00",
    //     totalDiscountAsCurrency: "€0.00",
    //     paidStatusHtml: '<span class="commerceStatusLabel"><span class="status red"></span> Unpaid</span>',
    //     customerLinkHtml: "",
    //     orderStatusHtml: "",
    //     availableShippingMethods: [],
    //     availableShippingMethodOptions: [],
    //     totalTax: 0,
    //     totalTaxIncluded: 42.92999999999999,
    //     totalShippingCost: 0,
    //     totalDiscount: 0,
    //   },
    //   userErrors: null,
    //   errors: null,
    //   showCart: false,
    // });
  }

  @Selector([ShopState])
  public static getCsrfTokenObject(state: ShopStateModel) {
    return state.csrf
      ? { [state.csrf.csrfTokenName]: state.csrf.csrfToken }
      : '';
  }
  @Selector([ShopState])
  public static getCart(state: ShopStateModel) {
    return state.cart;
  }
  @Selector([ShopState])
  public static getCartErrors(state: ShopStateModel) {
    return state.errors;
  }
  @Selector([ShopState])
  public static getCartMessage(state: ShopStateModel) {
    return state.message;
  }

  @Selector([ShopState])
  public static getUserErrors(state: ShopStateModel) {
    return state.userErrors;
  }

  @Selector([ShopState])
  public static getTotalQty(state: ShopStateModel) {
    return state.cart?.totalQty;
  }
  @Selector([ShopState])
  public static showCart(state: ShopStateModel) {
    return state.showCart;
  }
  @Selector()
  static getPaymentCurrency(state: ShopStateModel) {
    return state.cart && state.currencies[state.cart.paymentCurrency]
      ? state.currencies[state.cart.paymentCurrency]
      : state.currencies['EUR'];
  }
  @Selector()
  static getAllPaymentCurrency(state: ShopStateModel) {
    return state.currencies;
  }

  @Action(GetCsrfToken)
  public getCsrfToken(
    { dispatch, patchState }: StateContext<ShopStateModel>,
    { }: GetCsrfToken
  ) {
    return this.http
      .get('/api/security/csrf-token.json', {
        withCredentials: true,
      })
      .pipe(
        tap((resp) => {
          patchState({ csrf: resp as CsrfTokenResponse });
        }),
        catchError((error) => {
          dispatch(new ErrorMessage(error));
          return throwError(() => new Error('Error while receiving page'));
        })
      );
  }

  @Action(GetAllCurrencies)
  public getAllCurrencies(
    { dispatch, patchState }: StateContext<ShopStateModel>,
    { }: GetAllCurrencies
  ) {
    return this.http
      .get('/api/config/shop-currencies.json', {
        withCredentials: true,
      })
      .pipe(
        tap((resp) => {
          Object.values(resp).forEach(
            (currency: Currency) =>
              (currency.rate = parseFloat(currency.rate as any))
          );
          patchState({ currencies: resp as Currencies });
        }),
        catchError((error) => {
          dispatch(new ErrorMessage(error));
          return throwError(() => new Error('Error while receiving page'));
        })
      );
  }

  // /api/config/shop-currencies.json

  @Action(RehydrateCart)
  public rehydrateCart(
    { dispatch, getState }: StateContext<ShopStateModel>,
    { number }: RehydrateCart
  ) {
    const csrf = this.store.selectSnapshot(ShopState.getCsrfTokenObject);
    const currentLanguageSiteEndpoint = this.store.selectSnapshot(
      LanguageState.getCurrentLanguageSiteEndpoint
    );
    const request = {
      action: 'commerce/cart/get-cart',
      number: number,
      mergeCarts: true,
      ...csrf,
      site: currentLanguageSiteEndpoint,
    } as any;
    dispatch(new HideCart());
    return this.http
      .post<UpdateCartResponse>(
        '/action',
        new HttpParams({ fromObject: request }),
        { withCredentials: true }
      )
      .pipe(
        map((resp: UpdateCartResponse) => {
          return dispatch(new UpdateCartSuccess(resp));
        }),
        catchError((error) => {
          dispatch(new GetCsrfToken());
          dispatch(new ClearCart());
          dispatch(new ErrorMessage(error));
          return throwError(() => new Error('Error while receiving page'));
        })
      );
  }

  @Action(ClearCart)
  public clearCart(
    { patchState, dispatch }: StateContext<ShopStateModel>,
    { }: ClearCart
  ) {
    patchState({ cart: undefined, errors: undefined });
    dispatch(new DismissCartMessage());
  }

  @Action(AddToCart)
  public addToCart(
    { dispatch }: StateContext<ShopStateModel>,
    { purchasableId, qty, title, image, productId }: AddToCart
  ) {
    const message: CartMessage = {
      action: 'add_to_cart',
      qty,
      productId,
      purchasableId,
      title,
      image,
      message: `Product ${title} was successfully added to the cart`,
    };
    const body = {
      action: 'commerce/cart/update-cart',
      purchasableId,
      qty,
      cartUpdatedNotice: JSON.stringify(message),
    } as any;
    firstValueFrom(this.getProductCoverGQL
      .watch({
        productId: productId,
        variantId: purchasableId,
      })
      .valueChanges).catch((error) => console.error(error));
    return dispatch(new UpdateCart(body)).pipe(
      switchMap((resp) => {
        return dispatch([
          new SendGoogleAnalyticsEvent(
            'add_to_cart',
            'shop',
            'cart',
            'click',
            10
          ),
        ]);
      })
    );
  }

  @Action(ApplyCoupon)
  public applyCoupon(
    { dispatch }: StateContext<ShopStateModel>,
    { couponCode }: ApplyCoupon
  ) {
    const body = {
      action: 'commerce/cart/update-cart',
      couponCode,
    } as any;
    return dispatch(new UpdateCart(body));
  }

  @Action(UpdateCart)
  public updateCart(
    { dispatch, getState }: StateContext<ShopStateModel>,
    { body }: UpdateCart
  ) {
    this.loadingBar.useRef().start();
    const currentLanguageSiteEndpoint = this.store.selectSnapshot(
      LanguageState.getCurrentLanguageSiteEndpoint
    );
    const csrf = ShopState.getCsrfTokenObject(getState());
    const request = {
      ...body,
      ...csrf,
      site: currentLanguageSiteEndpoint,
    } as any;
    return this.http
      .post<UpdateCartResponse>(
        '/action',
        new HttpParams({ fromObject: request }),
        { withCredentials: true }
      )
      .pipe(
        tap((resp) => this.loadingBar.useRef().increment()),
        map((resp: UpdateCartResponse) => {
          if (typeof resp.success != typeof undefined && !resp.success) {
            return dispatch(new UpdateCartError(resp));
          } else {
            return dispatch(new UpdateCartSuccess(resp));
          }
        }),
        catchError((error) => {
          dispatch(new GetCsrfToken());
          dispatch(new ErrorMessage(error));
          return throwError(() => new Error('Error while receiving page'));
        })
      );
  }

  @Action(UpdateCartSuccess)
  public updateCartSuccess(
    { dispatch, patchState }: StateContext<ShopStateModel>,
    { resp }: UpdateCartSuccess
  ) {
    if (resp.cart.isCompleted) {
      dispatch(new ClearCart());
      this.loadingBar.useRef().complete();
    } else {
      patchState({
        cart: resp.cart,
        errors: resp.errors ? resp.errors : undefined,
      });
      let message = null;
      try {
        message = resp.message
          ? resp.message.startsWith('{') ? JSON.parse(resp.message.replace(/&quot;/g, '"')) : resp.message
          : null
      } catch (error) {
        console.error(error);
      }
      dispatch(new SetCartMessage(message));
      this.loadingBar.useRef().complete();
      dispatch(new ErrorMessageDismiss());
    }
  }
  @Action(UpdateCartError)
  public updateCartError(
    { dispatch, patchState }: StateContext<ShopStateModel>,
    { resp }: UpdateCartError
  ) {
    dispatch(new ErrorMessage(resp.errors));
    patchState({ errors: resp.errors });
    this.loadingBar.useRef().complete();
    return throwError(() => new Error(resp.error));
  }

  @Action(ToggleCart)
  public toggleCart(
    { dispatch, getState }: StateContext<ShopStateModel>,
    { }: ToggleCart
  ) {
    const state = getState();
    state.showCart ? dispatch(new HideCart()) : dispatch(new ShowCart());
  }
  @Action(ShowCart)
  public showCart(
    { dispatch, patchState }: StateContext<ShopStateModel>,
    { }: ShowCart
  ) {
    dispatch([
      new DismissCartMessage(),
      new SendGoogleAnalyticsEvent(
        'view_item_list',
        'shop',
        'cart',
        'click',
        10
      ),
    ]);
    patchState({ showCart: true });
  }
  @Action(HideCart)
  public hideCart(
    { dispatch, patchState }: StateContext<ShopStateModel>,
    { }: HideCart
  ) {
    dispatch(new DismissCartMessage());
    patchState({ showCart: false });
  }

  @Action(UpdateCurrency)
  public updateCurrency(
    { dispatch, getState }: StateContext<ShopStateModel>,
    { code }: UpdateCurrency
  ) {
    const request = {
      action: 'commerce/cart/update-cart',
      paymentCurrency: code,
    } as any;
    dispatch(new UpdateCart(request));
  }

  /**
   * User Registration
   */
  @Action(RegisterUser)
  public registerUser(
    { dispatch, getState }: StateContext<ShopStateModel>,
    { username, email, password }: RegisterUser
  ) {
    const currentLanguageSiteEndpoint = this.store.selectSnapshot(
      LanguageState.getCurrentLanguageSiteEndpoint
    );
    const csrf = ShopState.getCsrfTokenObject(getState());

    const request = {
      action: 'users/save-user',
      username,
      email,
      password,
      ...csrf,
      site: currentLanguageSiteEndpoint,
    } as any;

    return this.http
      .post('/action', new HttpParams({ fromObject: request }), {
        withCredentials: true,
      })
      .pipe(
        map((resp: any) => {
          return dispatch(new RegisterUserSuccess(resp));
        }),
        catchError((error) => {
          dispatch(new GetCsrfToken());
          dispatch(new ErrorMessage(error));
          return throwError(() => new Error('Error while receiving page'));
        })
      );
  }
  @Action(RegisterUserSuccess)
  public registerUserSuccess(
    { dispatch, patchState }: StateContext<ShopStateModel>,
    { resp }: RegisterUserSuccess
  ) {
    if (typeof resp.success != typeof undefined && !resp.success) {
      dispatch(new ErrorMessage(resp.errors));
      patchState({ userErrors: resp.errors });
      return throwError(() => new Error(resp.error));
    } else {
      patchState({ userErrors: resp.errors ? resp.errors : null });
      return dispatch(new ErrorMessageDismiss());
      // this.aRef.tick();
    }
  }
  /**
   * User Login
   */
  @Action(LoginUser)
  public loginUser(
    { dispatch, getState }: StateContext<ShopStateModel>,
    { loginName, password }: LoginUser
  ) {
    const currentLanguageSiteEndpoint = this.store.selectSnapshot(
      LanguageState.getCurrentLanguageSiteEndpoint
    );
    const csrf = ShopState.getCsrfTokenObject(getState());

    const request = {
      action: 'users/login',
      loginName,
      password,
      ...csrf,
      site: currentLanguageSiteEndpoint,
    } as any;

    return this.http
      .post('/action', new HttpParams({ fromObject: request }), {
        withCredentials: true,
      })
      .pipe(
        map((resp: any) => {
          return dispatch(new LoginUserSuccess(resp));
        }),
        catchError((error) => {
          dispatch(new GetCsrfToken());
          dispatch(new ErrorMessage(error));
          return throwError(() => new Error('Error while receiving page'));
        })
      );
  }
  @Action(LoginUserSuccess)
  public loginUserSuccess(
    { dispatch, getState, patchState }: StateContext<ShopStateModel>,
    { resp }: LoginUserSuccess
  ) {
    if (resp.error) {
      dispatch(new ErrorMessage(resp.error));
      patchState({ userErrors: resp.error });
      return throwError(() => new Error(resp.error));
    } else {
      const csrf = {
        ...getState().csrf,
        csrfToken: resp.csrfTokenValue
          ? resp.csrfTokenValue
          : getState().csrf?.csrfToken,
      } as CsrfTokenResponse;
      patchState({
        userErrors: resp.errors ? resp.errors : undefined,
        csrf,
      });
      return dispatch(new ErrorMessageDismiss());
      // this.aRef.tick();
    }
  }

  @Action(SetCartMessage)
  public setCartMessage(
    { patchState, dispatch }: StateContext<ShopStateModel>,
    { message }: SetCartMessage
  ) {
    patchState({
      message,
    });
  }

  @Action(DismissCartMessage)
  public dismissCartMessage(
    { patchState, dispatch }: StateContext<ShopStateModel>,
    { }: DismissCartMessage
  ) {
    patchState({
      message: undefined,
    });
  }
}
