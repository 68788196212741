<costes-header>
  <costes-logo></costes-logo>
  <costes-booking-slideout booking></costes-booking-slideout>
</costes-header>
<ngx-loading-bar color="#000" [diameter]="'16px'"></ngx-loading-bar>
<!-- <costes-cart *ngIf="showCart$ | async" [@cartAnimation]></costes-cart>
<costes-add-to-cart-message></costes-add-to-cart-message> -->
<main
  [ngClass]="animationState"
  [class.ssr-hidden]="!checkPlatform.isPlatformBrowser"
>
  <router-outlet></router-outlet>
</main>
<div class="loading" [class.ssr-hidden]="checkPlatform.isPlatformBrowser">
  <div class="spinner"></div>
</div>
<costes-footer>
  <!-- <costes-audio-soundscape-player left></costes-audio-soundscape-player> -->
  <costes-social-media left></costes-social-media>
</costes-footer>

<costes-error-message></costes-error-message>
<!-- --- RADIO COSTES --- -->
<!-- <audio costesRadioPlayer preload="none" src=""></audio> -->
<!-- --- RADIO COSTES --- -->
