<div class="left">
  <ng-content select="[left]"></ng-content>
</div>

<nav
  id="footerNav"
  role="navigation"
  [attr.aria-label]="'aria-label.footer-navigation' | translate"
  *ngIf="onPageNavigation$ | async as onPageNavigation"
>
  <ul>
    <li *ngFor="let link of onPageNavigation.items" [ngSwitch]="link.type">
      <ng-container
        *ngSwitchCase="'entryLink'"
        [ngTemplateOutlet]="internalLink"
        [ngTemplateOutletContext]="{ link: link }"
      ></ng-container>
      <ng-container
        *ngSwitchCase="'filterLink'"
        [ngTemplateOutlet]="internalLink"
        [ngTemplateOutletContext]="{ link: link }"
      ></ng-container>
      <ng-container
        *ngSwitchCase="'categoryLink'"
        [ngTemplateOutlet]="internalLink"
        [ngTemplateOutletContext]="{ link: link }"
      ></ng-container>
      <ng-container
        *ngSwitchCase="'urlLink'"
        [ngTemplateOutlet]="internalLink"
        [ngTemplateOutletContext]="{ link: link }"
      ></ng-container>
      <ng-container
        *ngSwitchDefault
        [ngTemplateOutlet]="externalLink"
        [ngTemplateOutletContext]="{ link: link }"
      ></ng-container>
    </li>
  </ul>
</nav>

<div class="right">
  <ng-content select="[right]"></ng-content>
</div>
<ng-template #internalLink let-link="link">
  <a
    [routerLink]="[link.uri]"
    [target]="link.target"
    #rla="routerLinkActive"
    [id]="rla.isActive ? 'footer-nav-item--active' : ''"
    [routerLinkActive]="'active'"
    [routerLinkActiveOptions]="{
      exact: !(link.type == 'categoryLink')
    }"
    [ariaCurrentWhenActive]="'page'"
    [queryParams]="link.queryParams"
    [fragment]="link.fragment"
    [innerHTML]="link.text"
  >
  </a>
</ng-template>
<ng-template #externalLink let-link="link">
  <a
    [href]="link.url"
    target="_blank"
    rel="noorigin noreferrer"
    [innerHTML]="link.text"
  >
  </a>
</ng-template>
