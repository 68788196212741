import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
} from '@angular/common/http';
import { Observable } from 'rxjs';

/** Inject Preview Token into the request */
@Injectable()
export class CraftPreviewInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (req.url.includes('/api/') && typeof window !== 'undefined') {
      const urlParams = new URLSearchParams(window.location.search);
      if (urlParams.has('x-craft-live-preview') && urlParams.has('token')) {
        const duplicate = req.clone({
          headers: new HttpHeaders({
            Accept: 'application/json',
            'x-craft-live-preview': urlParams.get('x-craft-live-preview') || '',
            'x-craft-token ': urlParams.get('token') || '',
          }),
        });

        return next.handle(duplicate);
      } else {
        return next.handle(req);
      }
    } else {
      return next.handle(req);
    }
  }
}
