import { Component, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonModule, DatePipe, DOCUMENT, KeyValue } from '@angular/common';
import { AbstractControl, FormBuilder, FormControl, FormGroup, NgModel, ReactiveFormsModule, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { BookingServiceItem, BookingServiceParam } from '../booking-slideout/booking-slideout.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'costes-booking-form',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, TranslateModule],
  providers: [DatePipe],
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class BookingFormComponent implements OnInit, OnDestroy {
  @Input('config') service?: BookingServiceItem;

  bookingLink?: string

  private readonly document: Document = inject(DOCUMENT);
  private readonly datePipe: DatePipe = inject(DatePipe)

  public form!: FormGroup;
  constructor(private fb: FormBuilder, private translate: TranslateService) { }

  today = this.toYYYYMMDD(new Date());

  ngOnInit() {
    this.transformControls();
  }
  ngOnDestroy() { }

  transformControls() {
    this.form = this.fb.group({});
    // console.log('this.content?.pages?.length', this.content?.pages?.length)
    this.service?.params?.forEach((param) => {
      this.form.addControl(param.key, this.createControl(param));
    })

    this.checkDateFields();

    this.form.valueChanges.subscribe(() => this.updateBookingUrl());
    this.updateBookingUrl()
  }

  createControl(param: BookingServiceParam) {
    const { type, required, max, value, validate } = param;
    let validation: any[] = [];
    if (required) {
      validation.push(Validators.required);
    }
    if (type == 'number') {
      validation.push(Validators.min);
      if (max) {
        validation.push(Validators.max);
      }
    }
    return this.fb.control({ disabled: false, value }, validation);
  }

  checkDateFields() {
    const fromParam = this.service?.params?.find((param) => param.validate == 'from');
    const toParam = this.service?.params?.find((param) => param.validate == 'to');
    this.fromControlKey = fromParam?.key ?? '';
    this.toControlKey = toParam?.key ?? '';

    if (!this.fromControlKey && !this.toControlKey) return;

    if (this.fromControlKey && this.toControlKey) {
      this.addDateValidation();
    }

    this.setInitialDates();
  }

  fromControlKey = ''
  toControlKey = ''
  addDateValidation() {
    if (!this.fromControlKey || !this.toControlKey) return;
    this.form.controls[this.fromControlKey].valueChanges.subscribe((from) => {
      this.form.controls[this.toControlKey].
        setValidators(
          [Validators.required, this.minDate(this.toYYYYMMDD(from))]);
      this.validateDates();
    })
    this.form.controls[this.toControlKey].valueChanges.subscribe((to) => {
      this.validateDates();
    })


  }

  setInitialDates() {
    const initialFromDate = new Date();
    const initialToDate = this.addDays(initialFromDate, 3);
    if (this.fromControlKey) this.form.controls[this.fromControlKey].setValue(this.toYYYYMMDD(initialFromDate));
    if (this.toControlKey) this.form.controls[this.toControlKey].setValue(this.toYYYYMMDD(initialToDate));
  }

  addDays(date: Date, days: number) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  updateBookingUrl() {
    if (!this.service?.url) {
      console.error('No url defined for this service')
      this.bookingLink = undefined;
      return;
    }
    const url = new URL(this.translate.instant(this.service?.url) ?? this.service.url);


    this.service?.params?.forEach((param) => {
      if (!this.form.get(param.key)?.value) return;
      if (param.type == 'date') {
        const date = this.datePipe.transform(new Date(this.form.get(param.key)?.value), param.format ?? 'yyyy-MM-dd');
        if (date) {
          url.searchParams.append(param.key, date);
        }
      } else {
        url.searchParams.append(param.key, `${this.form.value[param.key]}`);
      }
    })

    this.bookingLink = url.toString();
  }

  // handleSubmit(event: Event) {
  //   event.preventDefault();
  //   event.stopPropagation();
  //   const window = this.document.defaultView;
  //   if (!window) {
  //     console.error('Server side rendering. Skipping')
  //     return;
  //   }

  //   if (!this.service?.url) {
  //     console.error('No url defined for this service')
  //     return;
  //   }
  //   const url = new URL(this.translate.instant(this.service?.url) ?? this.service.url);


  //   this.service?.params?.forEach((param) => {
  //     if (!this.form.get(param.key)?.value) return;
  //     if (param.type == 'date') {
  //       const date = this.datePipe.transform(new Date(this.form.get(param.key)?.value), param.format ?? 'yyyy-MM-dd');
  //       if (date) {
  //         url.searchParams.append(param.key, date);
  //       }
  //     } else {
  //       url.searchParams.append(param.key, `${this.form.value[param.key]}`);
  //     }
  //   })

  //   window.open(url.toString(), '_blank');
  // }

  // Preserve original property order
  originalOrder = (a?: KeyValue<string, string>, b?: KeyValue<string, string>): number => {
    return 0;
  }

  dateError: boolean = false;
  validateDates(): void {
    let fromDate = this.form.controls[this.fromControlKey].value;
    let toDate = this.form.controls[this.toControlKey].value;
    if (toDate && fromDate && toDate < fromDate) {
      this.form.controls[this.toControlKey].setValue(fromDate);
    }
    this.form.updateValueAndValidity();
  }

  toYYYYMMDD(d: Date): string {
    d = new Date(d)
    var yyyy = d.getFullYear().toString();
    var mm = (d.getMonth() + 101).toString().slice(-2);
    var dd = (d.getDate() + 100).toString().slice(-2);
    return yyyy + '-' + mm + '-' + dd;
  }

  minDate = (minInput: any): ValidatorFn => {
    let value;
    let subscribe = false;
    let minValue = minInput;
    const isForm = minInput instanceof FormControl || minInput instanceof NgModel;
    return (control: AbstractControl): ValidationErrors | null => {

      if (!subscribe && isForm) {
        subscribe = true;
        minInput.valueChanges?.subscribe(() => {
          control.updateValueAndValidity();
        });
      }
      if (isForm) {
        minValue = minInput.value;
      }

      value = this.parseDate(minValue) as any;

      if (!this.isDate(value)) {
        console.log(value)
        if (value == null) {
          return null;
        } else if (isForm) {
          return { minDate: { error: 'minDate is invalid' } };
        } else {
          throw Error('minDate value must be or return a formatted date');
        }
      }

      if (this.isPresent(Validators.required(control))) {
        return null;
      }

      const d = new Date(this.parseDate(control.value)).getTime();
      if (!this.isDate(d)) {
        return { value: true };
      }
      if (value instanceof Function) {
        value = value();
      }

      return d >= new Date(value).getTime()
        ? null
        : (isForm ? { minDate: { control: minInput, value: minInput.value } } : { minDate: { value: minValue, control: undefined } });
    };
  };

  isPresent(obj: any): boolean {
    return obj !== undefined && obj !== null;
  }

  isDate(obj: any): boolean {
    try {
      const date = new Date(obj);
      return !isNaN(date.getTime());
    } catch (e) {
      return false;
    }
  }

  parseDate(obj: any): string {
    try {
      // Moment.js
      if (obj._d instanceof Date) {
        const d = obj._d as Date;
        const month = +d.getMonth() + 1;
        const day = +d.getDate();
        return `${d.getFullYear()}-${this.formatDayOrMonth(month)}-${this.formatDayOrMonth(day)}`;
      }

      // NgbDateStruct
      if (typeof obj === 'object' && obj.year != null && obj.month != null && obj.day != null) {
        const month = +obj.month;
        const day = +obj.day;
        return `${obj.year}-${this.formatDayOrMonth(month)}-${this.formatDayOrMonth(day)}`;
      }
    } catch (e) { }
    return obj;
  }

  formatDayOrMonth(month: number): string | number {
    return month < 10 ? `0${month}` : month;
  }

}
