import { Pipe, PipeTransform } from "@angular/core";
import { FocalPoint } from "typings";

@Pipe({
  name: "focalpoint",
  standalone: true,
})
export class FocalpointPipe implements PipeTransform {
  transform(focalpoint: FocalPoint): string {
    return focalpoint
      ? `${focalpoint.x * 100}% ${focalpoint.y * 100}% `
      : "50% 50%";
  }
}
