import { Component, inject, InjectionToken } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink } from '@angular/router';

import { Store } from '@ngxs/store';
import { Observable, map } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';

import { fadeInOut, moveInFromRight } from '@costes/library/animations';
import { BookingFormComponent } from '../form/form.component';
import { NavigationStateInterface, NavigationState, CloseNavigation } from '@costes/library/store/app';
import { DynamicInnerHtmlDirective } from '@costes/library/directives/dynamic-inner-html';
import { SafePipe } from '@costes/library/pipes/safe';

export const BOOKING_SERVICES = new InjectionToken<Array<BookingServiceItem>>('Booking config', {
  providedIn: 'root',
  factory: () => [],
})
export const BOOKING_NOTE = new InjectionToken<string>('Slideout Note', {
  providedIn: 'root',
  factory: () => "booking.note",
})
export interface BookingServiceParam {
  key: string,
  type: 'number' | 'date' | 'text',
  inputmode?: 'numeric' | 'text',
  name: string,
  format?: string,
  max?: number,
  placeholder?: string,
  value?: any,
  required?: boolean,
  hint?: string,
  class?: string,
  validate?: 'from' | 'to',
}

export interface BookingServiceItem {
  type: 'form' | 'link' | 'entry',
  name: string,
  button: string,
  url: string,
  fragment?: string,
  params?: Array<BookingServiceParam>,
  note?: string
}

@Component({
  selector: 'costes-booking-slideout, costes-booking',
  exportAs: 'slideout',
  standalone: true,
  imports: [CommonModule, TranslateModule, RouterLink, BookingFormComponent, DynamicInnerHtmlDirective, SafePipe],
  templateUrl: './booking-slideout.component.html',
  styleUrls: ['./booking-slideout.component.scss'],
  animations: [moveInFromRight, fadeInOut]
})
export class BookingSlideoutComponent {
  bookingNavigation$?: Observable<NavigationStateInterface<BookingServiceItem[]>>;

  bookingServices: BookingServiceItem[] = inject(BOOKING_SERVICES);
  bookingNote: string = inject(BOOKING_NOTE);

  constructor(private store: Store) {
    this.bookingNavigation$ = this.store
      .select(NavigationState.getNavigation)
      .pipe(
        map(
          (filterFn: any) =>
            filterFn('bookingMenu') as NavigationStateInterface<BookingServiceItem[]>
        )
      );
  }

  closeNavigation() {
    this.store.dispatch(new CloseNavigation());
  }

  // https://www.secure-hotel-booking.com/plage-palace/2QIW/en-US/search?arrivalDate=2023-01-16&amp;departureDate=2023-01-16&amp;selectedAdultCount=1&amp;selectedChildCount=0&amp;_ga=2.194951977.545445960.1673869192-939653822.1673692859
  // https://www.secure-hotel-booking.com/smart/hotel-bourg-tibourg/2963/en/search?arrivalDate=2023-01-22&departureDate=2023-01-28&selectedAdultCount=1&selectedChildCount=0&selectedInfantCount=0
}
