import { Component } from '@angular/core';

@Component({
  selector: 'costes-logo',
  template: ``,
  styleUrls: ['./logo.component.scss'],
  standalone: true,
})
export abstract class LogoBaseComponent {
  constructor() { }
}
