<nav id="shopLink" role="navigation">
  <ul id="shopLink--tools">
    <!-- <li>
    <a class="svg" [routerLink]="['/', currentLang$ | async, 'shop', 'search']">
      <svg
        width="18px"
        height="18px"
        viewBox="0 0 18 18"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
      >
        <g id="Search" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g
            id="Group"
            transform="translate(9.987900, 10.033956) rotate(48.000000) translate(-9.987900, -10.033956) translate(0.987900, 5.033956)"
            stroke="#000000"
          >
            <circle vector-effect="non-scaling-stroke" id="Oval" cx="5" cy="5" r="5"></circle>
            <line vector-effect="non-scaling-stroke" x1="10.5" y1="5" x2="17.5" y2="5" id="Line" stroke-linecap="round"></line>
          </g>
        </g>
      </svg>
    </a>
  </li> -->
    <li>
      <a
        class="svg"
        href="/{{ currentLang$ | async }}/customer"
        rel="noorigin noreferrer"
      >
        <svg
          width="18px"
          height="18px"
          viewBox="0 0 18 18"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <!-- Generator: Sketch 61.2 (89653) - https://sketch.com -->
          <title>Customer</title>
          <g
            id="Customer"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g
              vector-effect="non-scaling-stroke"
              transform="translate(0.500000, 2.000000)"
              stroke="#000000"
            >
              <circle
                vector-effect="non-scaling-stroke"
                id="Oval"
                cx="8.5"
                cy="3.5"
                r="3.5"
              ></circle>
              <path
                vector-effect="non-scaling-stroke"
                d="M16.5,14 C14.9313725,9.66666667 12.2647059,7.5 8.5,7.5 C4.73529412,7.5 2.06862745,9.66666667 0.5,14"
                id="Path-6"
                stroke-linecap="round"
              ></path>
            </g>
          </g>
        </svg>
      </a>
    </li>
    <li>
      <button
        class="svg"
        [class.active]="showCart$ | async"
        (click)="toggleCart()"
      >
        <svg
          width="18px"
          height="18px"
          viewBox="0 0 18 18"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <!-- Generator: Sketch 61.2 (89653) - https://sketch.com -->
          <title>Cart</title>
          <desc>Created with Sketch.</desc>
          <g
            id="Cart"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g
              id="Group"
              transform="translate(0.500000, 0.000000)"
              stroke="#000000"
            >
              <polygon
                vector-effect="non-scaling-stroke"
                id="Path-7"
                stroke-linejoin="round"
                points="2 5 15 5 17 17.5 0 17.5"
              ></polygon>
              <line
                vector-effect="non-scaling-stroke"
                x1="0.5"
                y1="15"
                x2="16.5"
                y2="15"
                id="Line"
                stroke-linecap="square"
              ></line>
              <path
                vector-effect="non-scaling-stroke"
                d="M5.5,9 C5.5,8 5.5,6.5 5.5,4.5 C5.5,1.5 6.5,0.5 8.5,0.5 C10.5,0.5 11.5,1.5 11.5,4.5 C11.5,6.5 11.5,8 11.5,9"
                id="Path-8"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
            </g>
          </g>
        </svg>
        <span *ngIf="totalQty$ | async as totalQty"
          >&nbsp;({{ totalQty }})</span
        >
      </button>
    </li>
  </ul>
  <ul id="shopLink--link">
    <li>
      <a [routerLink]="['/', currentLang$ | async, 'shop']"> Shop </a>
    </li>
  </ul>
</nav>
