import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { GetProductCoverGQL } from '@costes/library/graphql';
import { Observable, map } from 'rxjs';
import { CommonModule } from '@angular/common';
import { MediaComponent } from '../../../../media/src/media.component';

@Component({
  selector: 'costes-cart-image',
  templateUrl: './cart-image.component.html',
  styleUrls: ['./cart-image.component.scss'],
  imports: [CommonModule, MediaComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class CartImageComponent implements OnInit {
  @Input() productId: any;
  @Input() variantId: any;

  productImage$?: Observable<{
    url: string;
    mimeType: string;
    base64: string;
  }>;

  constructor(private getProductCoverGQL: GetProductCoverGQL) { }

  ngOnInit(): void {
    this.productImage$ = this.getProductCoverGQL
      .watch({
        productId: this.productId,
        variantId: this.variantId,
      })
      .valueChanges.pipe(map((result: any) => {
        if (result.data) {
          let productImages = [
            ...(result.data.variant?.coverImage ?? []),
            ...(result.data.product?.coverImage ?? []),
          ];
          return productImages.shift()
        }
      }))
  }
}
