import { Component, OnInit } from '@angular/core';
import { Store, Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { RegisterUser, ShopState } from '@costes/library/store/shop';

@Component({
  selector: 'costes-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
})
export class RegistrationComponent implements OnInit {
  @Select(ShopState.getUserErrors) userErrors$!: Observable<any>;

  constructor(private store: Store) { }

  ngOnInit() { }

  register() {
    // IF ITS NOT WORKING >> PUBLIC REGISTRATION MUST BE ENABLED IN CRAFT CMS
    this.store.dispatch(
      new RegisterUser('DenisYi', 'denisy@posteo.de', '#Test!')
    );
  }
}
