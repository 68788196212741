import { Component, OnInit } from '@angular/core';
import { LogoBaseComponent } from '@costes/library/components/logo';

@Component({
  selector: 'costes-logo',
  templateUrl: './logo.component.svg',
  styleUrls: ['./logo.component.scss'],
  standalone: true,
})
export class LogoComponent extends LogoBaseComponent implements OnInit {
  constructor() {
    super();
  }

  ngOnInit() { }
}
