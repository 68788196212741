import { HttpParams } from '@angular/common/http';
import { Params } from '@angular/router';

export class GetInitialContent {
  public static readonly type = '[Content] Get Initial Content';
  constructor() {}
}

export class TransferStateContent {
  public static readonly type = '[Content] Transfer Content from Backend';
  constructor(public projects: any, public texts: any, public facets: any) {}
}

export class GetContent {
  public static readonly type = '[Content] Get Content';
  constructor(
    public type: string,
    public fields: string,
    public currentPage: string,
    public params?:
      | HttpParams
      | {
          [param: string]: string | string[];
        }
  ) {}
}

export class SetCurrentPageCacheKey {
  public static readonly type = '[Content] Set current Page Cache Key';
  constructor(public cacheKey: string) {}
}

export class SetSectionName {
  public static readonly type = '[Content] Set Section Name';
  constructor(public sectionName: string) {}
}

export class GetHome {
  public static readonly type = '[Content] Get Home';
  constructor() {}
}
export class GetHomeSuccess {
  public static readonly type = '[Content] Retrieving Home';
  constructor(public cacheKey: string, public page: any) {}
}
export class GetPage {
  public static readonly type = '[Content] Get Page';
  constructor(
    public lang: string,
    public uri: string,
    public queryParams: Params,
    public cacheKey: string
  ) {}
}
export class GetPageSuccess {
  public static readonly type = '[Content] Retrieving Page';
  constructor(public cacheKey: string, public page: any) {}
}

export class GetShop {
  public static readonly type = '[Content] Get Shop';
  constructor(public uri: string) {}
}
export class GetShopSuccess {
  public static readonly type = '[Content] Retrieving Shop';
  constructor(public cacheKey: string, public page: any) {}
}
