import { Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Injectable({ providedIn: 'root' })
export class InterfaceService {
  constructor(private titleService: Title, private metaService: Meta) {
    this.metaService = metaService;
  }

  public updateTitle(title?: string, metaTitle?: string) {
    let newTitle = metaTitle || title || this.titleService.getTitle();

    this.titleService.setTitle(this.decodeHtmlEntity(newTitle));
  }

  private decodeHtmlEntity(str: string) {
    return str.replace(/&#(\d+);/g, (match, dec) => {
      return String.fromCharCode(dec);
    });
  }
}
