import {
  ActivatedRouteSnapshot,
  DetachedRouteHandle,
  RouteReuseStrategy,
} from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable()
export class AppRouteReuseStrategy implements RouteReuseStrategy {
  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return false;
  }
  store(
    route: ActivatedRouteSnapshot,
    detachedTree: DetachedRouteHandle
  ): void { }
  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return false;
  }
  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    return null;
  }
  shouldReuseRoute(
    future: ActivatedRouteSnapshot,
    curr: ActivatedRouteSnapshot
  ): boolean {
    let reload = future.data['reload'] || curr.data['reload'];

    let reuse =
      typeof future.data['reuse'] != 'undefined' &&
      typeof curr.data['reuse'] != 'undefined' &&
      future.data['reuse'] === curr.data['reuse'];
    // if (reuse) console.debug("CustomReuseStrategy:shouldReuseRoute", reuse);
    return reload ? !reload : future.routeConfig === curr.routeConfig || reuse;
  }
}
