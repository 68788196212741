import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { TranslateService } from '@ngx-translate/core';
import { Action, NgxsOnInit, Selector, State, StateContext } from '@ngxs/store';
import { Observable } from 'rxjs';

import {
  CheckLanguage,
  UpdateLanguage,
  UseLanguage,
  SetDocumentLanguage,
} from './language.actions';
import { LanguageStateModel } from './language.model';
import { GetNavigation } from '@costes/library/store/app';

/**
 * Language State @ NGXS
 */
@State<LanguageStateModel>({
  name: 'language',
  defaults: {
    lang: '',
    availableLanguages: ['fr', 'en', 'es', 'it'],
    defaultLanguage: 'en',
    sites: {
      en: 'hotel_en',
      fr: 'hotel_fr',
      it: 'hotel_it',
      es: 'hotel_es',
    },
  },
})
@Injectable()
export class LanguageState implements NgxsOnInit {
  /**
   * Get the current language code
   * @param state Language state
   */
  @Selector()
  static getLanguage(state: LanguageStateModel) {
    return state.lang;
  }
  @Selector()
  static getCurrentLanguageSiteEndpoint(state: LanguageStateModel) {
    return state.sites[state.lang];
  }
  @Selector()
  static getLanguageSiteEndpoint(state: LanguageStateModel) {
    return (lang: string) => {
      return state.sites[lang];
    };
  }

  /**
   * Get the available Languages
   * @param state Language state
   */
  @Selector()
  static getAvailableLanguages(state: LanguageStateModel) {
    return state.availableLanguages;
  }

  constructor(
    private translate: TranslateService,
    @Inject(DOCUMENT) private document: any
  ) { }

  /**
   * Dispatch CheckSession on start
   * @ignore
   */
  ngxsOnInit(ctx: StateContext<LanguageStateModel>) {
    this.translate.addLangs(ctx.getState().availableLanguages);
    // ctx.dispatch(new CheckLanguage());
  }

  /**
   * Checks the current language
   * @ignore
   */
  @Action(CheckLanguage)
  checkLanguage(ctx: StateContext<LanguageStateModel>) {
    const state = ctx.getState();
    const saved = state.lang;
    if (saved) {
      ctx.dispatch(new UpdateLanguage(saved));
    } else {
      const browswerLang = this.translate.getBrowserLang()
        ? this.translate.getBrowserLang()?.substring(0, 2).toLowerCase()
        : state.defaultLanguage;
      const language =
        state.availableLanguages.find((lang) => lang === browswerLang) ||
        state.defaultLanguage;
      ctx.dispatch(new UpdateLanguage(language));
    }
  }

  /**
   * Update to given language
   * @param ctx State Context
   * @param param1 Contains the language
   * @ignore
   */
  @Action(UpdateLanguage, { cancelUncompleted: true })
  async updateLanguage(
    ctx: StateContext<LanguageStateModel>,
    { lang }: UpdateLanguage
  ) {
    const _lang = ctx.getState().lang;
    if (_lang !== lang) {
      ctx.patchState({ lang: lang });
      ctx.dispatch([new UseLanguage(), new GetNavigation()]);
    } else {
      ctx.dispatch([new UseLanguage()]);
    }
  }

  /**
   * Use the language
   * @param ctx State Context
   * @ignore
   */
  @Action(UseLanguage, { cancelUncompleted: true })
  useLanguage(ctx: StateContext<LanguageStateModel>): Observable<any> {
    const lang = ctx.getState().lang;
    ctx.dispatch([new SetDocumentLanguage(lang)]);
    return this.translate.use(lang);
  }

  /**
   * Set document language
   * @param ctx State Context
   * @ignore
   */
  @Action(SetDocumentLanguage, { cancelUncompleted: true })
  setDocumentLanguage(
    ctx: StateContext<LanguageStateModel>,
    { lang }: SetDocumentLanguage
  ): void {
    this.document.documentElement.lang = lang;
  }
}
