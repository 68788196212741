import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ErrorMessage } from '@costes/library/store/app';
import { LanguageState } from '@costes/library/store/language';

@Injectable({ providedIn: 'root' })
export class ErrorGuard {
  constructor(
    private store: Store,
    private router: Router,
    private translate: TranslateService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const lang = this.store.selectSnapshot(LanguageState.getLanguage);
    if (lang) this.router.navigate(['/', lang]);
    this.store.dispatch(
      new ErrorMessage(
        this.translate.instant('message.page-not-found') ?? 'Page not found'
      )
    );
    return of(false);
  }
}
