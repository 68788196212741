import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
import { HttpBatchLink } from 'apollo-angular/http';
import { InMemoryCache } from '@apollo/client/core';
import { NgModule } from '@angular/core';

import introspectionResult from './types.graphql-gen';

const uri = '/graphql-api'; // <-- add the URL of the GraphQL server here
export function createApollo(httpLink: HttpBatchLink) {
  return {
    link: httpLink.create({ uri, withCredentials: false }),
    cache: new InMemoryCache({
      possibleTypes: introspectionResult.possibleTypes,
      typePolicies: {
        shop_shop_Entry: {
          keyFields: ['siteId', 'id'],
        },
        contact_contact_Entry: {
          keyFields: ['siteId', 'id'],
        },
        home_home_Entry: {
          keyFields: ['siteId', 'id'],
        },
        posts_article_Entry: {
          keyFields: ['siteId', 'id'],
        },
        playlists_playlist_Entry: {
          keyFields: ['siteId', 'id'],
        },
        hotels_hotel_Entry: {
          keyFields: ['siteId', 'id'],
        },
        space_facility_Entry: {
          keyFields: ['siteId', 'id'],
        },
        pages_listingPage_Entry: {
          keyFields: ['siteId', 'id'],
        },
        pages_detailPage_Entry: {
          keyFields: ['siteId', 'id'],
        },
        accomodations_apartment_Entry: {
          keyFields: ['siteId', 'id'],
        },
        accomodations_room_Entry: {
          keyFields: ['siteId', 'id'],
        },
        accomodations_suite_Entry: {
          keyFields: ['siteId', 'id'],
        },
        posts_mediaPost_Entry: {
          keyFields: ['siteId', 'id'],
        },
        pages_streamPage_Entry: {
          keyFields: ['siteId', 'id'],
        },
        pages_infoPage_Entry: {
          keyFields: ['siteId', 'id'],
        },
        shopSections_Category: {
          keyFields: ['siteId', 'id'],
        },
        goods_Product: {
          keyFields: ['siteId', 'id'],
        },
        music_Product: {
          keyFields: ['siteId', 'id'],
        },
      },
    }),
    ssrMode: true,
  };
}

@NgModule({
  imports: [ApolloModule],
  exports: [],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpBatchLink],
    },
  ],
})
export class GraphQLModule { }
