<div class="aside--overlay" [@fadeInOut]="'cart'" (click)="hideCart()"></div>
<aside [@moveInFromRight]="'show'">
  <ng-container *ngIf="cart$ | async as cart; else emptyCart">
    <div class="products" *ngIf="cart.totalQty > 0; else emptyCart">
      <main>
        <article
          class="product"
          *ngFor="
            let item of cart.lineItems;
            let index = index;
            trackBy: trackByFn
          "
          itemscope
          itemtype="http://schema.org/Product"
        >
          <div class="image">
            <costes-cart-image
              [productId]="item.snapshot.productId"
              [variantId]="item.purchasableId"
            ></costes-cart-image>
          </div>
          <div
            class="details"
            itemprop="offers"
            itemscope
            itemtype="http://schema.org/Offer"
          >
            <header>
              <h2 class="inline">
                <div
                  class="title"
                  itemprop="name"
                  [innerHTML]="item.snapshot.description"
                ></div>
                <div
                  class="subtotal"
                  itemprop="price"
                  [attr.content]="
                    item.subtotal | convertPrice : paymentCurrency
                  "
                  [innerText]="item.subtotal | convertPrice : paymentCurrency"
                ></div>
                <meta
                  itemprop="priceCurrency"
                  [attr.content]="paymentCurrency?.iso"
                />
              </h2>
              <div class="price">
                <span
                  [innerText]="item.salePrice | convertPrice : paymentCurrency"
                ></span>
                <span
                  *ngIf="item.snapshot.onSale && item.salePrice !== item.price"
                  [innerText]="item.price | convertPrice : paymentCurrency"
                  [class.striked]="item.price | convertPrice : paymentCurrency"
                ></span>
              </div>
            </header>
            <main>
              <span
                class="sales serif"
                *ngFor="let sale of item.snapshot.sales; let first = first"
              >
                {{ sale.name }}
              </span>
              <div class="quantity">
                <div
                  class="label"
                  [innerText]="
                    ('shop.labels.quantity' | translate) || 'Quantity :'
                  "
                ></div>
                <!-- <input type="number" name="lineItems[{{ item.id }}][qty]" min="0" value="{{ item.qty }}" /> -->
                <div class="qty">
                  {{ item.qty }}
                  <button
                    class="increase"
                    (click)="increaseQty(item.id, item.qty)"
                  >
                    +
                  </button>
                  <button
                    class="decrease"
                    (click)="decreaseQty(item.id, item.qty)"
                  >
                    &ndash;
                  </button>
                </div>
              </div>
              <button class="remove serif" (click)="removeFromCart(item.id)">
                {{ ("buttons.shop.remove-line" | translate) || "Remove Line" }}
              </button>
            </main>
          </div>
        </article>
      </main>
      <footer>
        <!-- <div class="adjustments">
          <div
            class="inline adjustment"
            *ngFor="let adjustment of cart.orderAdjustments"
          >
            <div class="upper">{{ adjustment.type }}</div>
            <div>
              <ng-container *ngIf="adjustment.isEstimated">
                <span class="upper">{{ adjustment.name }}</span>
                <br /><i>{{
                  ("shop.labels.estimated" | translate) || "Estimated"
                }}</i>
              </ng-container>
              <br />({{ adjustment.description }})
            </div>
            <div class="upper">
              {{ adjustment.amount | convertPrice: paymentCurrency }}
            </div>
          </div>
        </div> -->
        <div class="totals upper">
          <!-- {% if not lineItemHasErrors %} -->
          <div
            class="inline item-sub-total"
            *ngIf="cart.itemSubtotal !== cart.totalPrice"
          >
            <div>
              {{
                ("shop.labels.item-sub-total" | translate) || "Item Sub Total"
              }}
            </div>
            <div>
              {{ cart.itemSubtotal | convertPrice : paymentCurrency }}
            </div>
          </div>
          <div class="inline" *ngIf="cart.totalDiscount != 0">
            <div>
              {{ ("shop.labels.total-discount" | translate) || "Discount" }}
            </div>
            <div>
              {{ cart.totalDiscount | convertPrice : paymentCurrency }}
            </div>
          </div>
          <br />
          <div class="inline" *ngIf="cart.estimatedShippingAddressId">
            <div>
              {{ ("shop.labels.total-shipping" | translate) || "Shipping" }}
            </div>
            <div>
              {{ cart.totalShippingCost | convertPrice : paymentCurrency }}
            </div>
          </div>
          <div class="inline" *ngIf="!cart.estimatedShippingAddressId">
            <div>
              {{ ("shop.labels.total-shipping" | translate) || "Shipping" }}
            </div>
            <div>
              <em>{{
                ("shop.labels.shipping-calculation-on-checkout" | translate) ||
                  "on checkout"
              }}</em>
            </div>
          </div>
          <div class="inline" *ngIf="cart.totalTaxIncluded > 0">
            <div>
              {{ ("shop.labels.sub-total" | translate) || "Sub Total" }}
            </div>
            <div>
              {{ cart.totalTaxablePrice | convertPrice : paymentCurrency }}
            </div>
          </div>
          <div class="inline" *ngIf="cart.totalTax > 0">
            <div>{{ ("shop.labels.total-tax" | translate) || "Tax" }}</div>
            <div>{{ cart.totalTax | convertPrice : paymentCurrency }}</div>
          </div>

          <div class="inline" *ngIf="cart.totalTaxIncluded > 0">
            <div>
              {{
                ("shop.labels.total-tax-included" | translate) || "Tax (inc)"
              }}
            </div>
            <div>
              {{ cart.totalTaxIncluded | convertPrice : paymentCurrency }}
            </div>
          </div>

          <h4 class="inline">
            <div>{{ ("shop.labels.total-price" | translate) || "Total" }}</div>
            <div>{{ cart.totalPrice | convertPrice : paymentCurrency }}</div>
          </h4>
          <!-- {% endif %} -->
        </div>
        <!-- <div class="coupon">
          <input type="text" #couponCode name="couponCode" id="couponCode" />
          <button (click)="applyCoupon(couponCode.value)">Apply Coupon</button>
        </div> -->
        <div class="currencies">
          <button
            class="button"
            [class.active]="paymentCurrency?.iso === currency.value.iso"
            *ngFor="let currency of allPaymentCurrencies$ | async | keyvalue"
            (click)="updateCurrency(currency.value.iso)"
            [title]="currency.value.currencyName"
          >
            {{ currency.value.currencyName }}
            {{ currency.value.iso | currencySymbol }}
          </button>
        </div>
        <div class="buttons">
          <!-- {% if not lineItemHasErrors %} -->
          <button class="button button-secondary" (click)="hideCart()">
            {{
              ("shop.labels.continue-shopping" | translate) ||
                "Continue shopping"
            }}
          </button>
          <a class="button button-primary" [href]="checkoutURL$ | async">{{
            ("shop.labels.checkout" | translate) || "Checkout"
          }}</a>
          <!-- <div class="customer-account">
            <costes-registration></costes-registration>
            <costes-login></costes-login>
          </div> -->
          <!-- {% endif %} -->
        </div>
      </footer>
    </div>
  </ng-container>
</aside>
<!-- <pre *ngIf="(cart$ | async) as cart">
    {{ cart | json }}
</pre> -->

<ng-template #emptyCart>
  <p>
    You have no items in your cart, add some on the
    <a [routerLink]="['shop']">products page</a>.
  </p>
  <button class="button button-secondary" (click)="hideCart()">
    {{ ("shop.labels.continue-shopping" | translate) || "Continue shopping" }}
  </button>
</ng-template>
