import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';

import { GetHome, ContentState } from '@costes/library/store/content';

@Injectable({ providedIn: 'root' })
export class FrontpageResolver {
  constructor(private store: Store, private router: Router) { }

  resolve(route: ActivatedRouteSnapshot): Observable<any> | Promise<any> | any {
    return new Promise((resolve, reject) =>
      this.store.dispatch(new GetHome()).subscribe({
        next: (x) => {
          // console.debug("Completed Action Succesfully", x);
        },
        error: (err) => {
          console.error('An error occurred', err);
          resolve(null);
        },
        complete: () => {
          resolve(
            this.store.selectSnapshot(ContentState.getHomeContent)(
              route.data['lang']
            )
          );
        },
      })
    );
  }
}
