import {
  trigger,
  state,
  animate,
  keyframes,
  style,
  group,
  transition,
  query,
  stagger,
  animateChild,
} from '@angular/animations';

export const fadeInOut = trigger('fadeInOut', [
  state('void', style({ opacity: 0, 'pointer-events': 'none' })),
  state('show', style({ opacity: 1 })),
  state('info', style({ opacity: 1 })),
  state('cart', style({ opacity: 1 })),

  transition('* => cart', [animate('0.9s cubic-bezier(0.5, 0.1, 0.25, 1)')]),
  transition('* => show, * => info', [
    animate('1.2s 0.3s cubic-bezier(0.5, 0.1, 0.25, 1)'),
    query('@staggerFadeInOut', animateChild(), { optional: true }),
  ]),
  transition('info => void', [
    group([
      query('@staggerFadeInOut', animateChild(), { optional: true }),
      animate('0.3s cubic-bezier(0.5, 0.1, 0.25, 1)'),
    ]),
  ]),
  transition('* => void', [
    group([
      query('@staggerFadeInOut', animateChild(), { optional: true }),
      animate('1.2s 0.6s cubic-bezier(0.5, 0.1, 0.25, 1)'),
    ]),
  ]),
]);
export const fadeInOutText = trigger('fadeInOutText', [
  state('void', style({ opacity: 0, color: 'white' })),
  state('show', style({ opacity: 1, color: 'white' })),
  state('hidden', style({ opacity: 1, color: 'white' })),
  state('onHover', style({ opacity: 1, color: 'black' })),
  state('mixedContent', style({ opacity: 1, color: 'black' })),
  state('*', style({ opacity: 1, color: 'white' })),
  transition('* => void', [animate('1.2s cubic-bezier(0.5, 0.1, 0.25, 1)')]),
  transition(
    '* => show, * => hidden, * => onHover, * => mixedContent, * => *',
    [animate('1.2s 0.6s cubic-bezier(0.5, 0.1, 0.25, 1)')]
  ),
]);
// export const moveHeadline = trigger('moveHeadline', [
//   state(
//     'void',
//     style({
//       transform: 'translate3d(0,0,0)',
//       'font-size': '*',
//       'letter-spacing': '*',
//     })
//   ),
//   state(
//     'home-fade',
//     style({
//       transform: 'translate3d(0,calc((var(--vh, 1vh) * 50) - 100% - 3rem),0)',
//       'font-size': '2rem',
//       'font-weight': 'normal',
//       'letter-spacing': '0.4rem',
//     })
//   ),
//   state(
//     '*',
//     style({
//       transform: 'translate3d(0,0,0)',
//       'font-size': '*',
//       'letter-spacing': '*',
//     })
//   ),
//   transition('void => home-fade', [
//     query(
//       ':self',
//       style({
//         transform: 'translate3d(0,calc((var(--vh, 1vh) * 50) - 100% - 3rem),0)',
//         'font-size': '2rem',
//         'font-weight': 'normal',
//         'letter-spacing': '0.4rem',
//       })
//     ),
//     query('@fadeInOutText', animateChild(), {
//       optional: true,
//     }),
//   ]),
//   transition('void => *', [
//     query(
//       ':self',
//       style({
//         transform: 'translate3d(0,0,0)',
//         'font-size': '*',
//         'letter-spacing': '*',
//       })
//     ),
//     query('@fadeInOutText', animateChild(), {
//       optional: true,
//     }),
//   ]),
//   transition('* => void', [
//     query('@fadeInOutText', animateChild(), { optional: true }),
//     animate('0.1s linear'),
//   ]),
// ]);

export const staggerFadeInOut = trigger('staggerFadeInOut', [
  state('void', style({ opacity: 0, 'pointer-events': 'none' })),
  state('show', style({ opacity: 1 })),
  transition('* => show', [
    query(':self', style({ opacity: 0 })),
    query(
      'costes-grid-item:enter, costes-mosaic-item:enter, costes-product-list-item:enter, costes-promobanner-item:enter',
      style({ opacity: 0, 'pointer-events': 'auto' }),
      { optional: true }
    ),
    query('costes-product-list-item:leave, costes-promobanner-item:leave', style({ opacity: 1 }), {
      optional: true,
    }),
    query(
      'costes-product-list-item:leave, costes-promobanner-item:leave',
      animate(
        '0.3s cubic-bezier(0.5, 0.1, 0.25, 1)',
        style({ opacity: 0, 'pointer-events': 'none' })
      ),
      { optional: true }
    ),
    query(
      ':self',
      animate('0.6s cubic-bezier(0.5, 0.1, 0.25, 1)', style({ opacity: 1 }))
    ),
    query(
      'costes-grid-item:enter, costes-mosaic-item:enter, costes-product-list-item:enter, costes-promobanner-item:enter',
      stagger(
        70,
        animate('0.6s cubic-bezier(0.5, 0.1, 0.25, 1)', style({ opacity: 1 }))
      ),
      { optional: true }
    ),
  ]),
  transition('* => void', [
    query(':self', animate('0.6s cubic-bezier(0.5, 0.1, 0.25, 1)')),
  ]),
]);

export const scaleFadeInOut = trigger('scaleFadeInOut', [
  state(
    'void',
    style({ opacity: 0, transform: 'scale(0.8) skewX(5deg) skewY(-5deg)' })
  ),
  state('show', style({ opacity: 1, transform: 'scale(1)' })),
  transition('* => show', [
    animate('1.2s 0.3s cubic-bezier(0.5, 0.1, 0.25, 1)'),
  ]),
  transition('* => void', [animate('.6s cubic-bezier(0.5, 0.1, 0.25, 1)')]),
]);

export const showHideGallery = trigger('showHideGallery', [
  state('void', style({ opacity: 0 })),
  state('show', style({ opacity: 1 })),
  // transition("* => show", [animate("1.5s 1s cubic-bezier(0.5, 0.1, 0.25, 1)")]),
  transition('* => show', [animate('1s cubic-bezier(0.5, 0.1, 0.25, 1)')]),
  transition('show => *', [animate('1.5s 1s cubic-bezier(0.5, 0.1, 0.25, 1)')]),
]);
export const showHidePanelBackground = trigger('showHidePanelBackground', [
  state('void', style({ opacity: 0 })),
  state('show', style({ opacity: 1 })),
  // transition("* => show", [animate("1.5s 1s cubic-bezier(0.5, 0.1, 0.25, 1)")]),
  transition('* => show', [animate('0.6s ease')]),
  transition('show => *', [animate('0.6s 0.6s ease')]),
]);
// Workaround to let GSAP close the navigation
export const showHideSideNote = trigger('showHideSideNote', [
  state('void', style({ 'pointer-events': 'none' })),
  state('show', style({ 'pointer-events': 'none' })),
  // transition("* => show", [animate("1.5s 1s cubic-bezier(0.5, 0.1, 0.25, 1)")]),
  transition('show => *', [animate('.6s linear')]),
]);

export const moveInFromTop = trigger('moveInFromTop', [
  state('void', style({ transform: 'translate3d(0,-100%,0)' })),
  state('*', style({ transform: 'translate3d(0,0,0)' })),
  // transition("* => show", [animate("1.5s 1s cubic-bezier(0.5, 0.1, 0.25, 1)")]),
  transition('void => *', [animate('1s cubic-bezier(0.5, 0.1, 0.25, 1)')]),
  transition('* => void', [animate('0.6s cubic-bezier(0.5, 0.1, 0.25, 1)')]),
]);
export const moveInFromRight = trigger('moveInFromRight', [
  state('void', style({ transform: 'translate3d(100%,0,0)' })),
  state('*', style({ transform: 'translate3d(0,0,0)' })),
  state('show', style({ transform: 'translate3d(0,0,0)' })),
  state('hide', style({ transform: 'translate3d(100%,0,0)' })),
  // transition("* => show", [animate("1.5s 1s cubic-bezier(0.5, 0.1, 0.25, 1)")]),
  transition('void => *', [animate('0.6s cubic-bezier(0.5, 0.1, 0.25, 1)')]),
  transition('hide => show', [animate('0.6s cubic-bezier(0.5, 0.1, 0.25, 1)')]),
  transition('* => void', [animate('0.3s cubic-bezier(0.5, 0.1, 0.25, 1)')]),
  transition('show => hide', [animate('0.3s cubic-bezier(0.5, 0.1, 0.25, 1)')]),
]);

export const cartAnimation = trigger('cartAnimation', [
  state('void', style({})),
  state('*', style({})),
  // transition("* => show", [animate("1.5s 1s cubic-bezier(0.5, 0.1, 0.25, 1)")]),
  transition('void => *', [
    group([
      query('@moveInFromRight', animateChild(), {
        optional: true,
      }),
      query('@fadeInOut', animateChild(), {
        optional: true,
      }),
    ]),
  ]),
  transition('* => void', [
    group([
      query('@moveInFromRight', animateChild(), {
        optional: true,
      }),
      query('@fadeInOut', animateChild(), {
        optional: true,
      }),
    ]),
  ]),
]);
