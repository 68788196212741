import { Pipe, PipeTransform } from '@angular/core';
import { Source, VimeoSource } from 'typings';

@Pipe({
  name: 'filterSource',
  standalone: true,
})
export class FilterSourcePipe implements PipeTransform {
  private currentWidthOrHeight = 0;

  transform(media: Array<Source | VimeoSource>, width: number | null = 0, height: number | null = 0, type: 'default' | 'vimeo' = "default"): any[] {
    const widthOrHeight = width || height || 0;
    const checkWidthOrHeight = width ? 'width' : 'height';
    if (media && media.length > 0 && widthOrHeight) {
      if (widthOrHeight >= this.currentWidthOrHeight) {
        this.currentWidthOrHeight = width || 0;
      }

      let _mediaGroups = type == 'vimeo' ? this.groupBy(media, 'type') : this.groupBy(media, 'mimeType');

      const _media = Object.entries(_mediaGroups).map(([key, value]) => {
        return value.reduce((a: Source | VimeoSource, b: Source | VimeoSource) => {
          return Math.abs(b[checkWidthOrHeight] - this.currentWidthOrHeight) <
            Math.abs(a[checkWidthOrHeight] - this.currentWidthOrHeight)
            ? b
            : a;
        });
      });
      if (_media.length > 0) {
        return _media;
      }
      return media.reverse();
    } else {
      return media;
    }

  }

  groupBy(arr: Array<Source | VimeoSource>, property: string): { [key: string]: any } {
    return arr.reduce((memo: any, x: any) => {
      if (!memo[x[property]]) {
        memo[x[property]] = [];
      }
      memo[x[property]].push(x);
      return memo;
    }, {});
  }
}
