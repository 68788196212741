import { LanguageUrls } from 'typings';
import {
  NavigationStateModel,
  NavigationName,
  NavigationResponseObject,
} from './navigation.state';

export class ToggleNavigation {
  public static readonly type = '[Navigation] Toogle Navigation';
  constructor(public navName: NavigationName) {}
}
export class OpenNavigation {
  public static readonly type = '[Navigation] Open Navigation';
  constructor(public navName: NavigationName) {}
}
export class CloseNavigation {
  public static readonly type = '[Navigation] Close Navigation';
  constructor(public navName?: NavigationName) {}
}

export class SetOnPageNavigation {
  public static readonly type = '[Content] Set OnPageNavigation';
  constructor(public navigation: any, public navState: boolean) {}
}
export class SetLanguageNavigation {
  public static readonly type = '[Content] Set Language Switcher Navigation';
  constructor(public languageUrls: LanguageUrls) {}
}

export class GetNavigation {
  public static readonly type = '[Content] Get Navigation';
  constructor() {}
}
export class GetNavigationSuccess {
  public static readonly type = '[Content] Retrieving Navigation';
  constructor(public navigation: NavigationResponseObject) {}
}
