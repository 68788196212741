import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { PlatformLocation } from '@angular/common';
import { fromEvent } from 'rxjs';

import { CheckPlatformService } from './check-platform.service';

export const topMargin = 30;
/**
 * A service that scrolls document elements into view
 */
@Injectable({ providedIn: 'root' })
export class ScrollService {
  private _topOffset?: number | null;
  private _topOfPageElement?: Element;

  // Offset from the top of the document to bottom of any static elements
  // at the top (e.g. toolbar) + some margin
  get topOffset() {
    if (!this._topOffset) {
      const toolbar = this.document.querySelector('md-toolbar.app-toolbar');
      this._topOffset = ((toolbar && toolbar.clientHeight) || 0) + topMargin;
    }
    return this._topOffset;
  }

  get topOfPageElement() {
    if (!this._topOfPageElement) {
      this._topOfPageElement =
        this.document.getElementById('top-of-page') || this.document.body;
    }
    return this._topOfPageElement;
  }

  scrollToElementById(id: string, block: ScrollLogicalPosition = 'end') {
    let element = this.document.getElementById(id) || undefined;
    try {
      this.scrollToElement(element, block);
    } catch (e) { }
  }

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private location: PlatformLocation,
    private checkPlatform: CheckPlatformService
  ) {
    // On resize, the toolbar might change height, so "invalidate" the top offset.
    if (this.checkPlatform.isPlatformBrowser) {
      fromEvent(window, 'resize').subscribe(() => (this._topOffset = null));
    }
  }

  /**
   * Scroll to the element with id extracted from the current location hash fragment.
   * Scroll to top if no hash.
   * Don't scroll if hash not found.
   */
  scroll() {
    const hash = this.getCurrentHash();
    const element = hash ? this.document.getElementById(hash) : undefined;
    element ? this.scrollToElement(element) : this.scrollToTop();
  }

  /**
   * Scroll to the element.
   * Don't scroll if no element.
   */
  scrollToElement(element?: Element, block: ScrollLogicalPosition = 'end') {
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: block });
    }
  }

  /** Scroll to the top of the document. */
  scrollToTop() {
    this.scrollToElement(this.topOfPageElement);
    // window.scrollTo(0, 0);
  }

  /**
   * Return the hash fragment from the `PlatformLocation`, minus the leading `#`.
   */
  private getCurrentHash() {
    return this.location.hash.replace(/^#/, '');
  }
}
