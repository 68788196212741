import { Injectable } from '@angular/core';
import { State, Action, Store, Selector, StateContext } from '@ngxs/store';
import {
  PlayRadio,
  PauseRadio,
  SetNewSrc,
  SetRadioPlayerState,
} from './radio.actions';
import { Item } from 'typings';
import { SendGoogleAnalyticsEvent } from '@costes/library/store/app';

export type PlayerState =
  | 'init'
  | 'loadedmetadata'
  | 'playing'
  | 'pause'
  | 'waiting'
  | 'error';

export interface RadioStateModel {
  state: PlayerState;
  src: string;
  title: string;
  link?: Item;
}

@State<RadioStateModel>({
  name: 'radio',
  defaults: {
    state: 'init',
    src: '',
    title: 'Radio Costes',
    link: undefined,
  },
})
@Injectable()
export class RadioState {
  constructor(private store: Store) { }

  @Selector()
  static getState(state: RadioStateModel) {
    return state.state;
  }

  @Selector()
  static getSrc(state: RadioStateModel) {
    return state.src;
  }
  @Selector()
  static getTitle(state: RadioStateModel) {
    return state.title;
  }
  @Selector()
  static getLink(state: RadioStateModel) {
    return state.link;
  }

  @Action(PlayRadio)
  playRadio({ dispatch }: StateContext<RadioStateModel>, action: PlayRadio) {
    dispatch(
      new SendGoogleAnalyticsEvent('clic_radio', 'Musique', 'Play Radio')
    );
  }

  @Action(PauseRadio)
  pauseRadio({ dispatch }: StateContext<RadioStateModel>, action: PauseRadio) {
    dispatch(
      new SendGoogleAnalyticsEvent('clic_radio', 'Musique', 'Pause Radio')
    );
  }

  @Action(SetNewSrc)
  setNewSrc(
    { setState }: StateContext<RadioStateModel>,
    { src, title, link }: SetNewSrc
  ) {
    return setState({ src, title, link, state: 'init' });
  }

  @Action(SetRadioPlayerState)
  setRadioPlayerState(
    { patchState }: StateContext<RadioStateModel>,
    { state }: SetRadioPlayerState
  ) {
    patchState({ state: state });
  }
}
