<nav *ngIf="webLinks$ | async as webLinks">
  <ul>
    <li
      *ngFor="let link of webLinks.items"
      (click)="
        sendGoogleAnalyticsEvent(
          'clic_reseaux_sociaux',
          'engagement',
          link.text
        )
      "
    >
      <a
        class="nav-link-item"
        href="{{ link.url }}"
        target="{{ link.target }}"
        rel="{{ link.rel }}"
      >
        <span *ngIf="!link.icon" [innerHTML]="link.text"></span>
        <picture itemprop="image" [title]="link.text" *ngIf="link.icon as icon">
          <img
            ngSrc="{{ icon.url | replace }}"
            ngSrcset="20w, 40w"
            [width]="icon.width"
            [height]="icon.height"
            [alt]="icon.altText"
            sizes="20px"
          />
        </picture>
      </a>
    </li>
  </ul>
</nav>
