import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { IfChangesDirective } from '@costes/library/directives/if-changes';

import { CheckPlatformService } from '../../services/check-platform.service';
import { DimensionsService } from '../../services/dimensions.service';
import { InterfaceService } from '../../services/interface.service';
import { ScrollService } from '../../services/scroll.service';

const MODULES = [
  // Do NOT include UniversalModule, HttpModule, or JsonpModule here
  CommonModule,
  RouterModule,
  // FormsModule,
  // ReactiveFormsModule
  TranslateModule,
  IfChangesDirective,
];

const PIPES: any = [
  // put pipes here
];

const COMPONENTS: any = [

  // MoveToHeadDirective,
];

const PROVIDERS = [
  CheckPlatformService,
  DimensionsService,
  InterfaceService,
  ScrollService,
  CurrencyPipe
];

@NgModule({
  imports: [...MODULES],
  declarations: [...PIPES, ...COMPONENTS],
  exports: [...MODULES, ...PIPES, ...COMPONENTS],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [...PROVIDERS],
    };
  }
}
