import { NgModule } from '@angular/core';

import { AddToCartMessageComponent } from './add-to-cart-message.component';
import { SharedModule } from '@costes/library';
import { CartImageComponent } from '@costes/library/components/shop';

@NgModule({
  declarations: [AddToCartMessageComponent],
  imports: [SharedModule, CartImageComponent],
  exports: [AddToCartMessageComponent],
  providers: [],
})
export class AddToCartMessageModule { }
