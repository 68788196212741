import { CommonModule } from '@angular/common';
import { Component, OnInit, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ContentState } from '@costes/library/store/content';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'costes-breadcrumb',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit {
  @Select(ContentState.getPageContent) page$!: Observable<any>;
  constructor() { }

  ngOnInit() { }
}
