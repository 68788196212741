<ol
  class="wrapper"
  *ngIf="page$ | async as page"
  itemscope
  itemtype="https://schema.org/BreadcrumbList"
>
  <li
    *ngFor="
      let link of page.breadcrumbs?.data;
      let index = index;
      let last = last
    "
    itemprop="itemListElement"
    itemscope
    itemtype="https://schema.org/ListItem"
  >
    <a
      [routerLink]="[link.uri]"
      [fragment]="link.fragment"
      [queryParams]="link.queryParams"
      [target]="link.target"
      [rel]="link.rel"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: true }"
      [ariaCurrentWhenActive]="'page'"
      itemscope
      itemtype="https://schema.org/WebPage"
      itemprop="item"
      [attr.itemid]="link.url"
    >
      <span itemprop="name" [innerHTML]="link.text"></span>
    </a>
    <svg *ngIf="!last" width="16px" height="28px" viewBox="0 0 16 28">
      <g stroke="none" stroke-width="1" fill="none" stroke-linecap="round">
        <polyline
          class="arrow"
          transform="translate(16, 28) rotate(180)"
          vector-effect="non-scaling-stroke"
          points="16 0 0 14 16 28"
        ></polyline>
      </g>
    </svg>
    <meta itemprop="position" [content]="index + 1" />
  </li>
</ol>
