import {
  Directive,
  ElementRef,
  OnInit,
  OnChanges,
  Input,
  OnDestroy,
  AfterViewInit,
  inject,
} from '@angular/core';
import { Router } from '@angular/router';
import { CheckPlatformService } from '@costes/library';

@Directive({
  selector: '[dynamicInnerHtml]',
  standalone: true
})
export class DynamicInnerHtmlDirective implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  private containerElement: HTMLElement;

  private router = inject(Router);
  private checkPlatform = inject(CheckPlatformService);

  constructor(
    containerElement: ElementRef,
  ) {
    this.containerElement = containerElement.nativeElement;
  }

  @Input() content: any;
  @Input() active: boolean = true;

  viewInitialized: boolean = false;

  ngOnInit() {
    // only init when we are in browser
    if (this.checkPlatform.isPlatformBrowser) {
      this.updateDynamicContent(this.content);
    } else {
      this.addStaticContent(this.content);
    }
  }
  ngOnChanges() {
    if (this.checkPlatform.isPlatformBrowser) {
      this.updateDynamicContent(this.content);
    }
  }
  ngOnDestroy() {
    if (this.checkPlatform.isPlatformBrowser) {
      this.removeEventListener();
    }
  }
  ngAfterViewInit() {
    this.viewInitialized = true;
    this.updateDynamicContent(this.content);
  }

  addStaticContent(content: any) {
    content = this.deactivateSrc(content);
    this.containerElement.innerHTML = content;
  }

  updateDynamicContent(content: any) {
    this.removeEventListener();
    content = this.updateDynamicSrc(content);
    this.containerElement.innerHTML = content;
    this.addEventListener();
  }

  // EventListener Add/Remove
  addEventListener() {
    // query anchors
    const anchors = this.containerElement.querySelectorAll('a[href]');
    Array.from(anchors).forEach((el) => {
      el.addEventListener('click', (e) => this.dynamicLinkHandler(el, e), {
        passive: false,
      });
    });
  }
  removeEventListener() {
    const anchors = this.containerElement.querySelectorAll('a[href]');
    Array.from(anchors).forEach((el) => {
      el.removeEventListener('click', (e) => this.dynamicLinkHandler(el, e));
    });
  }

  // iFrame De/Activation
  updateDynamicSrc(content: string) {
    if (!this.active) {
      return this.deactivateSrc(content);
    } else {
      return this.viewInitialized
        ? this.activateSrc(content)
        : this.deactivateSrc(content);
    }
  }
  activateSrc(content: string) {
    if (!content) return content;
    let _content = content;
    _content = _content.replace('src="" data-src=', 'src=');
    _content = _content.replace('srcset="" data-srcset=', 'srcset=');
    return _content;
  }
  deactivateSrc(content: string) {
    if (!content) return content;
    let _content = content;
    _content = _content.replace('src=', 'src="" data-src=');
    _content = _content.replace('srcset=', 'srcset="" data-srcset=');
    return _content;
  }

  // Dymamic Link Handler
  dynamicLinkHandler(el: any, e: any) {
    if (
      el.getAttribute('target') != '_blank' &&
      el.getAttribute('href').substring(0, 4) != 'tel:' &&
      el.getAttribute('href').substring(0, 7) != 'mailto:'
    ) {
      e.preventDefault();
      let url = el.getAttribute('href');
      url = url.replace(/https?:\/\/[^\/]+/i, '');
      url = url.replace(/http?:\/\/[^\/]+/i, '');
      url = url.replace(/\/$/, '');
      this.router.navigateByUrl(url);
    }
  }
}
