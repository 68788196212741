import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlSerializer } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';

import { SendGoogleAnalyticsEvent } from '@costes/library/store/app';
import { GetPage } from '@costes/library/store/content';
import { LanguageState } from '@costes/library/store/language';


@Injectable({ providedIn: 'root' })
export class ProductResolver {
  constructor(
    private store: Store,
    private router: Router,
    private serializer: UrlSerializer
  ) { }

  resolve(route: ActivatedRouteSnapshot): Observable<any> | Promise<any> | any {
    // const url = this.store.selectSnapshot(RouterState.state).url;
    const uriSegments = route.pathFromRoot
      .map((v) => v.url.map((segment) => segment.toString()).join('/'))
      .filter((v) => v !== '')
      .join('/')
      .split('/');
    const fullUrl = uriSegments.join('/');
    uriSegments.shift(); // needed to we loose the lang from the full url
    const lang = route.data['lang'] ?? uriSegments + '';
    const uriSnapshot = uriSegments.join('/');

    const tree = this.router.createUrlTree([fullUrl], {
      queryParams: route.queryParams,
    });
    const cacheKey = this.serializer.serialize(tree);

    return new Promise((resolve, reject) =>
      this.store
        .dispatch(new GetPage(lang, uriSnapshot, route.queryParams, cacheKey))
        .subscribe({
          next: (x) => {
            // console.debug("Completed Action Succesfully", x);
          },
          error: (err) => {
            console.error('received error! Great"');
            const lang = this.store.selectSnapshot(LanguageState.getLanguage);
            this.router.navigate(['/', lang]);
            reject();
          },
          complete: () => {
            this.store.dispatch(
              new SendGoogleAnalyticsEvent(
                'view_item',
                'shop',
                'cart',
                'click',
                10
              )
            );
            resolve(null);
          },
        })
    );
  }
}
