<ng-container *ngIf="service">
  <form [formGroup]="form">
    <div class="wrapper">
      <div
        class="field"
        [ngClass]="input.class"
        *ngFor="let input of service.params"
      >
        <label [for]="input.key" [innerHTML]="input.name | translate"></label>
        <input
          [id]="input.key"
          [formControlName]="input.key!"
          [type]="input.type"
          [attr.inputmode]="input.inputmode ?? 'text'"
          [placeholder]="input.placeholder ?? '' | translate"
          [attr.placeholder]="input.placeholder ?? '' | translate"
          [value]="input.value"
          [min]="input.type == 'date' ? today : 0"
          [max]="input.max ?? 999"
          [required]="input.required ?? false"
        />
        <span *ngIf="input.hint" [innerHtml]="input.hint | translate"></span>
      </div>
    </div>
    <a
      [href]="bookingLink"
      class="button book"
      target="_blank"
      [innerHTML]="service.button | translate"
    ></a>
  </form>
</ng-container>
