<ng-container *ngIf="bookingNavigation$ | async as bookingNavigation">
  <div
    class="aside--overlay"
    [@fadeInOut]="bookingNavigation.state ? 'cart' : 'void'"
    (click)="closeNavigation()"
  ></div>
  <aside [@moveInFromRight]="bookingNavigation.state ? 'show' : 'hide'">
    <ul>
      <li *ngFor="let service of bookingServices">
        <h3 [innerHTML]="service.name | translate"></h3>
        <div [ngSwitch]="service.type">
          <costes-booking-form *ngSwitchCase="'form'" [config]="service" />
          <div *ngSwitchCase="'entry'">
            <a
              [routerLink]="service.url | translate"
              [fragment]="service.fragment ?? '' | translate"
              class="button book"
              [innerHTML]="service.button | translate"
            >
            </a>
          </div>
          <div *ngSwitchDefault>
            <a
              [href]="service.url | translate"
              class="button book"
              target="_blank"
              rel="noopener noreferrer"
              [innerHTML]="service.button | translate"
            >
            </a>
          </div>
        </div>
        <div
          class="note"
          *ngIf="service.note"
          dynamicInnerHtml
          [content]="service.note | translate"
        ></div>
      </li>
    </ul>
    <div
      class="slideout-note"
      *ngIf="bookingNote"
      [innerHTML]="bookingNote | translate | safe"
    ></div>
  </aside>
</ng-container>
