import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductListComponent } from './product-list.component';
import { ProductListItemComponent } from './product-list-item/product-list-item.component';
import { PromobannerItemComponent } from './promobanner-item/promobanner-item.component';

@NgModule({
  declarations: [ProductListComponent],
  imports: [CommonModule, ProductListItemComponent, PromobannerItemComponent],
  exports: [
    ProductListComponent,
    ProductListItemComponent,
    PromobannerItemComponent,
  ],
})
export class ProductListModule {}
