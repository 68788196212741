import { Component, OnInit, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { filter, debounceTime } from 'rxjs/operators';
import { UntilDestroy } from '@ngneat/until-destroy';
import { AppState, ErrorMessageDismiss } from '@costes/library/store/app';


@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'costes-error-message',
  templateUrl: './error-message.component.html',
  styleUrls: ['./error-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorMessageComponent implements OnInit, OnDestroy {
  @Select(AppState.errorMessage) errorMessage$!: Observable<any>;

  constructor(private store: Store) { }

  ngOnInit() {
    // Auto Dismiss of messsages after n-Seconds
    this.errorMessage$
      .pipe(
        filter((error) => error != null),
        debounceTime(5000)
      )
      .subscribe((error) => {
        // console.log(error, "DISSMISS FIRED");
        this.store.dispatch(new ErrorMessageDismiss());
      });
  }
  ngOnDestroy() { }

  public dismiss() {
    this.store.dispatch(new ErrorMessageDismiss());
  }
}
