<a
  [routerLink]="'/' + (lang$ | async) + '/' + product.uri"
  [queryParams]="product.queryParams"
  queryParamsHandling="merge"
>
  <costes-media
    [data]="showProductImage ? product.productImage : product.coverMedia"
    [class.fullscreen]="product.coverImageFitToGrid && !showProductImage"
    sizes="(min-width: 768px) 40vw, 90vw"
  ></costes-media>
</a>
<div class="info">
  <div class="title">
    <h1 [innerHTML]="product.title"></h1>
    <div>&nbsp;&middot;&nbsp;</div>
    <div class="price">
      <span
        *ngIf="
          product.pricing.defaultPrice !== product.pricing.defaultSalePrice
        "
        [innerText]="
          product.pricing.defaultSalePrice | convertPrice : paymentCurrency
        "
      ></span>
      <span
        [innerText]="
          product.pricing.defaultPrice | convertPrice : paymentCurrency
        "
        [class.striked]="
          product.pricing.defaultPrice !== product.pricing.defaultSalePrice
        "
      ></span>
    </div>
  </div>
  <button class="cart" (click)="addToCart(product.defaultPurchasableId)">
    {{ ("buttons.shop.addToCart" | translate) || "Add to Cart" }}
  </button>
</div>
