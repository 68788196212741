import {
  Component,
  OnInit,
  Input,
  AfterViewInit,
  ViewChild,
  ElementRef,
  OnDestroy,
  Output,
  EventEmitter,
  inject,
  InjectionToken,
  HostListener,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
} from '@angular/core';
import { MediaItem, Image, Video, Source, VimeoSource } from 'typings';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable, firstValueFrom } from 'rxjs';
import { map, distinctUntilChanged, combineLatestWith } from 'rxjs/operators';
import { gsap, Sine } from 'gsap';
import { NgxResize, provideNgxResizeOptions } from 'ngx-resize';
import type { NgxResizeResult } from 'ngx-resize';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { DrawBlurhash } from '@ynmstudio/utils/blurhash';
import type Player from '@vimeo/player';
import { Select } from '@ngxs/store';
import { CheckPlatformService, DimensionsService } from '@costes/library';
import { FitParentElementDirective } from '@costes/library/directives/fit-parent-element';
import { FilterSourcePipe } from '@costes/library/pipes/filter-source';
import { FocalpointPipe } from '@costes/library/pipes/focalpoint';
import { ReplacePipe } from '@costes/library/pipes/replace';
import { SafePipe } from '@costes/library/pipes/safe';
import { AppState } from '@costes/library/store/app';
import { IfChangesDirective } from '@costes/library/directives/if-changes';


export const MEDIA_DELAY = new InjectionToken<number>('mediaDelay', {
  providedIn: 'root',
  factory: () => 6000,
});


@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'costes-media',
  standalone: true,
  imports: [
    CommonModule,
    NgOptimizedImage,
    FilterSourcePipe,
    FocalpointPipe,
    ReplacePipe,
    DrawBlurhash,
    SafePipe,
    FitParentElementDirective,
    IfChangesDirective
  ],
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.scss'],
  providers: [
    FilterSourcePipe,
    provideNgxResizeOptions({ emitInitialResult: true, emitInZone: false }),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  hostDirectives: [{ directive: NgxResize, outputs: ['ngxResize'] }],
})
export class MediaComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() data?: MediaItem;
  @Input() sizes: string = '100vw';

  @Input() autoplay: boolean = true;
  @Input() priority: boolean = false;


  _mixed = false;
  @Input('mixed') set mixed(value: boolean) {
    // This will prevent that the mixed property is changed once it was set to true
    if (!!this._mixed && !value) return;
    this._mixed = value;
    this.cdRef.markForCheck();
  }
  get mixed() {
    return this._mixed;
  }


  @Input() fill: boolean = true;


  @ViewChild('videoElRef') videoElRef?: ElementRef;
  @ViewChild('iframeElRef') iframeElRef?: ElementRef;
  @ViewChild('poster') posterElRef?: ElementRef;

  @Output() showNext = new EventEmitter<void>();

  @HostListener('ngxResize', ['$event'])
  onResize(event: NgxResizeResult) {
    this.currentViewportWidth = Math.ceil(event.width * event.dpr);
    this.currentViewportHeight = Math.ceil(event.height * event.dpr);
    this.setVideoSource();
  }

  tweenPosterO = { hide: 1, canPlay: 0 };

  image?: Image;
  video?: Video;

  videoDuration = 0;


  delay?: number = inject(MEDIA_DELAY);
  init: boolean = false;
  shouldPlay: boolean = false;

  /**
   * New (Un-)Mute Logic
   * Allow to toggle the muted state of the video
   */
  allowToggleMuted$ = new BehaviorSubject<boolean>(false);
  @Select(AppState.muted) _muted$!: Observable<boolean>;
  muted$ = this._muted$.pipe(combineLatestWith(this.allowToggleMuted$), map(([muted, allowToggleMuted]) => allowToggleMuted ? muted : true));
  private toggleMuted = (muted: boolean) => {
    if (this.videoElRef) this.videoElRef.nativeElement.muted = muted;
    this.reloadVimeoIframe(muted);
  }

  constructor(
    private checkPlatform: CheckPlatformService,
    private dimensionsService: DimensionsService,
    private filterSourcePipe: FilterSourcePipe,
    private cdRef: ChangeDetectorRef
  ) {
    this.muted$.pipe(untilDestroyed(this)).subscribe((muted) => this.toggleMuted(muted))
  }

  public currentViewportWidth?: number | null = null;
  public currentViewportHeight?: number | null = null;

  ngOnInit() {
    this.dimensionsService.dimensions
      .pipe(
        map((dimensions) => dimensions.landscape),
        distinctUntilChanged(),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.setImageSource();
        this.setVideoSource();
      });
  }
  ngAfterViewInit() {
    if (!this.checkPlatform.isPlatformBrowser) return;
    if (this.autoplay) {
      this.play();
    }
    if (this.mixed) {
      gsap.set(this.tweenPosterO, {
        hide: 0,
      });
    }
    this.startRender();
  }

  async ngOnDestroy(): Promise<void> {
    this.stopRender();
    if (this.pausePromise) await this.pausePromise;
    if (this.playPromise) await this.playPromise;
    if (this.player) {
      this.player.off('loaded');
      this.player.off('play');
      this.player.off('pause');
      this.player.destroy();
    }
  }

  async setImageSource() {
    if (!this.data) return;
    let image: Image;
    if (this.data.image && this.data.verticalImage) {
      image = this.dimensionsService.isLandscape ? this.data.image : this.data.verticalImage;
    } else {
      image = this.dimensionsService.isLandscape
        ? this.data.image || this.data.verticalImage
        : this.data.image || this.data.verticalImage;
    }
    this.image = image;
  }

  currentVideoSourceWidth?: number;
  currentVideoSourceHeight?: number;
  async setVideoSource() {
    this.checkedIfVimeoIFrame = false;
    if (!this.data) return;
    let video: Video;

    if (this.data.video && this.data.verticalVideo) {
      video = this.dimensionsService.isLandscape ? this.data.video : this.data.verticalVideo;
    } else {
      video = this.dimensionsService.isLandscape
        ? this.data.video ?? this.data.verticalVideo
        : this.data.verticalVideo ?? this.data.video;
    }
    if (!video?.media?.sources?.length && !video?.embed?.files?.length) {
      this.video = video;
      if (!this.checkedIfVimeoIFrame) this.checkIfVimeoIFrame();
      return;
    };

    if (this.playPromise) await this.playPromise;
    let newSourceWidth, newSourceHeight;

    if (video.media?.sources) {
      // DEFAULT VIDEO
      const sources = this.filterSourcePipe.transform(video.media?.sources, this.currentViewportWidth, this.currentViewportHeight, 'default');

      newSourceWidth = sources.at(0)?.width;
      newSourceHeight = sources.at(0)?.height;

      if (this.currentViewportWidth && this.currentVideoSourceWidth === newSourceWidth && this.currentViewportHeight && this.currentVideoSourceHeight === newSourceHeight) return;

      this.video = {
        ...video,
        media: {
          ...video.media,
          sources: this.currentViewportWidth ? sources : [],
        }
      }
    } else if (video?.embed?.files) {
      // VIMEO
      const files = this.filterSourcePipe.transform(video.embed?.files ?? [], this.currentViewportWidth, this.currentViewportHeight, 'vimeo');
      newSourceWidth = files.at(0)?.width;
      newSourceHeight = files.at(0)?.height;

      if (this.currentViewportWidth && this.currentVideoSourceWidth === newSourceWidth && this.currentViewportHeight && this.currentVideoSourceHeight === newSourceHeight) return;

      this.video = {
        ...video,
        embed: {
          ...video.embed,
          files: files,
        }
      }
    }
    this.allowToggleMuted$.next(this.video?.allowAudio ?? false);

    this.cdRef.detectChanges();
    if (newSourceWidth) {
      this.videoElRef?.nativeElement.load()
    };
    if (this.autoplay || this.playing) {
      this.play();
    }
    this.currentVideoSourceWidth = newSourceWidth;
    this.currentVideoSourceHeight = newSourceHeight;
  }

  playPromise?: Promise<any>;
  pausePromise?: Promise<any>;
  hideTimer: any;

  player?: Player; // FOR VIMEO IFRAME

  public async play() {
    clearTimeout(this.hideTimer);
    if (this.videoElRef || this.iframeElRef) {
      this.shouldPlay = true;
      try {
        if (this.videoElRef) this.videoElRef.nativeElement.muted = await firstValueFrom(this.muted$);
        if (this.pausePromise) await this.pausePromise;

        if (this.videoElRef) this.playPromise = this.videoElRef?.nativeElement.play();
        if (this.player) this.playPromise = this.player.play();
        if (this.playPromise) {
          await this.playPromise;
          this.playing = true;
          if (!this.shouldPlay) return;
          if (this.videoDuration > 0) {
            this.hideTimer = setTimeout(() => {
              this.showNext.emit();
            }, Math.max(1, (this.videoDuration - 1)) * 1000);
          } else {
            this.hideTimer = setTimeout(() => {
              this.showNext.emit();
            }, this.delay);

          }
        }
      } catch (error) {
        console.debug(error);
      }
    } else {
      this.hideTimer = setTimeout(() => {
        this.showNext.emit();
      }, this.delay);
    }
  }
  public async pause() {
    clearTimeout(this.hideTimer);
    if (this.videoElRef || this.iframeElRef) {
      this.shouldPlay = false;
      try {
        if (this.playPromise) await this.playPromise;
        if (this.videoElRef) this.pausePromise = this.videoElRef?.nativeElement.pause();
        if (this.player) this.pausePromise = this.player?.pause();
        if (this.pausePromise) await this.pausePromise;
        this.playing = false;
        if (this.videoElRef) this.videoElRef.nativeElement.currentTime = 0;
        if (this.player) this.player.setCurrentTime(0);
      } catch (error) {
        console.debug(error);
      }
    }
  }
  onCanPlay = () => {
    gsap.set(this.tweenPosterO, {
      canPlay: 1,
    });
    this.player?.off('loaded');
  }

  private _playing: boolean = false;
  set playing(state: boolean) {
    this._playing = state;
    this.cdRef.detectChanges();
  }
  get playing() {
    return this._playing;
  }

  urlNotEmpty(source: Source): boolean {
    return source?.url?.length > 0;
  }


  _isLoaded: boolean = false;
  set isLoaded(state: boolean) {
    this._isLoaded = state;
    this.cdRef.detectChanges();
  }
  get isLoaded() {
    return this._isLoaded;
  }

  onLoad(event: any) {
    this.isLoaded = true;
  }


  onMetadata(e: any, video: HTMLVideoElement) {
    this.videoDuration = video.duration || 0;
    this.isLoaded = true;
  }

  private checkedIfVimeoIFrame: boolean = false;
  async checkIfVimeoIFrame(muted?: boolean) {
    this.cdRef.detectChanges(); // SOMEHOW THE IFRAME IS NOT AVAILABLE ON THE FIRST CHECK OTHERWISE
    if (!this.video || !this.iframeElRef) {
      this.checkedIfVimeoIFrame = true;
      return;
    }

    if (this.video.kind === 'embeddedAsset' && this.video.embed?.iframeSrcBackground?.includes('vimeo')) {
      const Player = (await import('@vimeo/player')).default;
      this.player = new Player(this.iframeElRef.nativeElement);
      this.videoDuration = await this.player.getDuration();
      const globalMutedState = muted ?? await firstValueFrom(this.muted$);
      await this.player.setMuted(globalMutedState);
      await this.player.setVolume(globalMutedState ? 0 : 1);
      this.player.on('loaded', this.onCanPlay);
      this.player.on('play', () => {
        this.playing = true;
      });
      this.player.on('pause', () => {
        this.playing = false;
      });
    }
    this.checkedIfVimeoIFrame = true;
    if (this.playing) this.play();
  }
  private async reloadVimeoIframe(muted: boolean = true) {
    if (!this.player) return;
    if (await firstValueFrom(this.allowToggleMuted$)) {
      await this.player.destroy();
      await this.checkIfVimeoIFrame(muted);
      if (this.shouldPlay) this.play();
    }
  }

  public showVideo() {
    clearTimeout(this.hideTimer);

    gsap.killTweensOf(this.tweenPosterO);
    gsap.to(this.tweenPosterO, {
      duration: 0.75,
      hide: 1,
      delay: 0.25,
      ease: Sine.easeOut,
      onStart: this.onAnimationCompletePlay,
    });
  }
  public hideVideo() {
    clearTimeout(this.hideTimer);

    gsap.killTweensOf(this.tweenPosterO);
    gsap.to(this.tweenPosterO, {
      duration: 0.5,
      hide: 0,
      ease: Sine.easeIn,
      onComplete: this.onAnimationCompletePause,
    });

  }
  onAnimationCompletePlay = () => {
    // console.error('onAnimationCompletePlay');
    this.startRender();
    this.play()
  };
  onAnimationCompletePause = () => {
    this.pause();
    this.stopRender();
  };

  /**
   * Render Function
   */
  rAFrameNumber: number = 0;

  startRender = () => {
    this.render();
  };
  stopRender = () => {
    if (!this.checkPlatform.isPlatformBrowser) return;
    clearTimeout(this.hideTimer);
    cancelAnimationFrame(this.rAFrameNumber);
  };

  render = () => {
    if (!this.posterElRef) {
      this.stopRender();
      return;
    }
    gsap.set(this.posterElRef.nativeElement, {
      opacity: 1 - this.tweenPosterO.canPlay * this.tweenPosterO.hide,
      force3D: true,
    });
    this.rAFrameNumber = requestAnimationFrame(() => this.render());
  };

  trackBySourceId(index: number, item: Source) {
    return item.sourceId;
  }
  trackByFileRendition(index: number, item: VimeoSource) {
    return item.rendition;
  }


}
