import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replace',
  standalone: true
})
export class ReplacePipe implements PipeTransform {
  transform(
    value: string,
    searchValue: string | RegExp = /^(?:\/\/|[^\//]+)*\//,
    replaceValue: string = '/'
  ): string {
    if (!value) return '';
    /**
     * @TODO Hotfix to delete hash from url
     * @BODY delete when fragment is part of link object
     */
    let url = value.replace(searchValue, replaceValue);
    const hashIndex = url.indexOf('#');
    if (hashIndex > -1) {
      url = url.slice(0, hashIndex);
    }
    return url;
  }
}
