<button (click)="showVolume()">
  <!-- <costes-play-pause-icon state="pause"></costes-play-pause-icon> -->
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 12V8C3 7.44772 3.44772 7 4 7H6.58579C6.851 7 7.10536 6.89464 7.29289 6.70711L11.2929 2.70711C11.9229 2.07714 13 2.52331 13 3.41421V16.5858C13 17.4767 11.9229 17.9229 11.2929 17.2929L7.29289 13.2929C7.10536 13.1054 6.851 13 6.58579 13H4C3.44772 13 3 12.5523 3 12Z"
      *ngIf="volume > 0"
    />
    <g *ngIf="!(volume > 0)">
      <path
        d="M2.70711 3.12132C2.31658 2.7308 2.31658 2.09763 2.70711 1.70711C3.09763 1.31658 3.7308 1.31658 4.12132 1.70711L8.17489 5.76067L13 10.5858L18.2635 15.8492C18.654 16.2398 18.654 16.8729 18.2635 17.2635C17.8729 17.654 17.2398 17.654 16.8492 17.2635L2.70711 3.12132Z"
      />
      <path
        d="M3 12V8C3 7.44772 3.44772 7 4 7H4.58579C4.851 7 5.10536 7.10536 5.29289 7.29289L12.7071 14.7071C12.8946 14.8946 13 15.149 13 15.4142V16.5858C13 17.4767 11.9229 17.9229 11.2929 17.2929L7.29289 13.2929C7.10536 13.1054 6.851 13 6.58579 13H4C3.44772 13 3 12.5523 3 12Z"
      />
      <path
        d="M8.17489 5.76067L13 10.5858V3.03086C13 2.23095 12.115 1.76648 11.4621 2.18753C11.413 2.21921 11.3705 2.25995 11.3311 2.30312L8.17489 5.76067Z"
      />
    </g>
  </svg>
</button>

<div
  class="volume-box"
  [ngClass]="{
    show: show
  }"
>
  <input
    #volumeBar
    tabindex="0"
    type="range"
    name="volume"
    id="volume"
    min="0"
    max="1"
    value="0.5"
    step="0.01"
    [(ngModel)]="volume"
    (ngModelChange)="setVolume($event)"
    (wheel)="wheelSetVolume($event)"
    (keydown.enter)="blurVolume($event)"
    (keydown.escape)="blurVolume($event)"
  />
</div>

<audio
  *ngFor="let track of audioTracks$ | async | keyvalue; trackBy: trackByFn"
  soundscape
  [data]="track.value"
></audio>
