import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class CheckPlatformService {
  constructor(
    @Inject(PLATFORM_ID)
    private platformId: Object
  ) {
    this.checkIsPlatformBrowser();
    this.checkIfIsTouchDevice();
    this.checkIOS();
  }

  private _isPlatformBrowser!: boolean;
  private checkIsPlatformBrowser() {
    this._isPlatformBrowser = isPlatformBrowser(this.platformId);
  }
  public get isPlatformBrowser() {
    return this._isPlatformBrowser;
  }

  private _isTouchDevice!: boolean | number;
  private checkIfIsTouchDevice() {
    if (!this.isPlatformBrowser) {
      this._isTouchDevice = false;
      return;
    }

    this._isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints;
  }
  public get isTouchDevice() {
    return this._isTouchDevice;
  }

  private _iOS!: boolean;
  public checkIOS() {
    if (!this.isPlatformBrowser) {
      this._iOS = false;
      return;
    }
    this._iOS =
      [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod',
      ].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes('Mac') && 'ontouchend' in document);
  }
  public get iOS() {
    return this._iOS;
  }
}
