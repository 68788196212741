<ng-container *ngIf="message$ | async as message">
  <div class="message" *ifChanges="message" [@moveInFromTop]="'show'">
    <ng-container [ngSwitch]="message.action">
      <ng-container *ngSwitchCase="'add_to_cart'">
        <div class="image">
          <costes-cart-image
            [productId]="message.productId"
            [variantId]="message.purchasableId"
          ></costes-cart-image>
        </div>
        <div class="info">
          <div class="product upper">
            {{ message.qty }}X {{ message.title }}
          </div>
          <div class="continue-shopping serif">
            {{
              ("shop.labels.was-added-to-cart" | translate) ||
                "a bien été ajouté à votre panier"
            }}
          </div>
        </div>
        <div class="buttons">
          <!-- {% if not lineItemHasErrors %} -->
          <button class="button button-secondary" (click)="dismiss()">
            {{
              ("shop.labels.continue-shopping" | translate) ||
                "Continue shopping"
            }}
          </button>
          <button class="button button-primary" (click)="showCart()">
            {{ ("shop.labels.show-cart" | translate) || "Voir votre panier" }}
          </button>
          <!-- <div class="customer-account">
                  <costes-registration></costes-registration>
                  <costes-login></costes-login>
                </div> -->
          <!-- {% endif %} -->
        </div>
      </ng-container>

      <p class="default" *ngSwitchDefault>
        {{ message }}
      </p>
    </ng-container>
  </div>
</ng-container>
