<ng-container *ngIf="banner().linkUrl as link" [ngSwitch]="link.type">
  <ng-container
    *ngSwitchCase="'entryLink'"
    [ngTemplateOutlet]="internalLink"
    [ngTemplateOutletContext]="{ link: link }"
  ></ng-container>
  <ng-container
    *ngSwitchCase="'categoryLink'"
    [ngTemplateOutlet]="internalLink"
    [ngTemplateOutletContext]="{ link: link }"
  ></ng-container>
  <ng-container
    *ngSwitchCase="'productLink'"
    [ngTemplateOutlet]="internalLink"
    [ngTemplateOutletContext]="{ link: link }"
  ></ng-container>
  <ng-container
    *ngSwitchDefault
    [ngTemplateOutlet]="externalLink"
    [ngTemplateOutletContext]="{ link: link }"
  ></ng-container>
</ng-container>
<costes-media
  *ngIf="banner().coverMedia"
  [data]="banner().coverMedia"
  [class.fullscreen]="banner().coverImageFitToGrid"
  sizes="(min-width: 768px) 66vmin, 100vw"
></costes-media>
<div class="info" *ngIf="banner().headline || banner().text">
  <h1 [innerHTML]="banner().headline"></h1>
  <div [innerHtml]="banner().text"></div>
</div>
<ng-template #internalLink let-link="link">
  <a
    [routerLink]="[link.uri]"
    [fragment]="link.fragment"
    [queryParams]="link.queryParams"
    target="{{ link.target }}"
    rel="{{ link.rel }}"
  >
  </a>
</ng-template>
<ng-template #externalLink let-link="link">
  <a href="{{ link.url }}" target="{{ link.target }}" rel="{{ link.rel }}"> </a>
</ng-template>
