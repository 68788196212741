import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  NgZone,
  OnInit,
  ViewChild,
} from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';
import { ProductListItemComponent } from '../product-list/product-list-item/product-list-item.component';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { SharedModule, DimensionsService } from '@costes/library';

@Component({
  selector: 'costes-similar-products',
  standalone: true,
  imports: [
    SharedModule,
    InlineSVGModule,
    ProductListItemComponent,
    TranslateModule,
  ],
  templateUrl: './similar-products.component.html',
  styleUrls: ['./similar-products.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimilarProductsComponent implements OnInit, AfterViewInit {
  @Input('products') similarProducts: Array<any> = [];

  isMobile: boolean = true;
  hasOverflow: boolean = false;

  constructor(
    private ngZone: NgZone,
    private dimensionsService: DimensionsService,
    private cdRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.dimensionsService.dimensions.subscribe(
      (dimensions) => (this.isMobile = dimensions.isMobile)
    );
  }
  ngAfterViewInit(): void {
    this.onResize();
  }

  @ViewChild('similarProductWrapper', { read: ElementRef })
  wrapper?: ElementRef;

  @HostListener('window:resize') onResize() {
    if (!this.wrapper) return;
    this.hasOverflow =
      this.wrapper.nativeElement.scrollWidth >
      this.wrapper.nativeElement.offsetWidth;
    this.scrollWidth = this.wrapper.nativeElement.offsetWidth;
    this.cdRef.detectChanges();
  }
  scrollWidth: number = 0;
  deltaLeft: number = 0;
  deltaRight: number = 1;

  @HostListener('wheel', ['$event']) onMouseWheel(event: WheelEvent) {
    if (this.isMobile) return;
    this.ngZone.runOutsideAngular(() => {
      this.scrollList((event.deltaY + event.deltaX) / 2);
    });
  }

  scrollList(amount: number, behavior: string = 'auto') {
    if (!this.wrapper) return;
    this.wrapper.nativeElement.scrollLeft += amount;
    this.deltaLeft = this.wrapper.nativeElement.scrollLeft;
    this.deltaRight =
      this.wrapper.nativeElement.scrollWidth -
      this.wrapper.nativeElement.offsetWidth -
      this.wrapper.nativeElement.scrollLeft;

    this.wrapper.nativeElement.scrollTo({
      left: this.deltaLeft,
      behavior,
    });
    this.cdRef.detectChanges();
  }
  scrollRight() {
    this.scrollList(this.scrollWidth * 0.3, 'smooth');
  }
  scrollLeft() {
    this.scrollList(this.scrollWidth * -0.3, 'smooth');
  }
}
