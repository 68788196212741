import { Component, Inject, Renderer2, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  Select,
  Actions,
  ofActionDispatched,
  Store,
  ofActionSuccessful,
} from '@ngxs/store';
import { Observable } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { distinctUntilChanged, pairwise, startWith, tap } from 'rxjs/operators';
import { RouterNavigation, RouterDataResolved } from '@ngxs/router-plugin';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';

import { cartAnimation } from '@costes/library/animations';
import { Event, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { environment } from '../environments/environment';
import { HideCart, ShopState } from '@costes/library/store/shop';
import { OnAppLoadService, CheckPlatformService } from '@costes/library';
import { LayoutName, ActivateNewLayout, ShowLayoutOverlays, LayoutState, CloseNavigation } from '@costes/library/store/app';
import { ContentState } from '@costes/library/store/content';

declare let gtag: Function;

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'costes-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [cartAnimation],
})
export class AppComponent implements OnInit, OnDestroy {
  @Select(ContentState.getCurrentSection)
  currentSection$!: Observable<string>;

  @Select(ShopState.showCart) showCart$!: Observable<boolean>;

  layoutAnimationState$?: Observable<LayoutName | undefined>;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private translate: TranslateService,
    private actions$: Actions,
    private store: Store,
    private onAppLoad: OnAppLoadService,
    public checkPlatform: CheckPlatformService,
    private router: Router
  ) {
    // const langs = ["fr", "en", "es", "it"];
    // this.translate.addLangs(langs);
    // const lang =
    //   langs.find(lang => lang === this.translate.getBrowserLang()) || "en";
    // this.translate.setDefaultLang(lang);
    // this.translate.use(lang).subscribe(lang => {
    //   console.log(this.translate.currentLang, this.translate.defaultLang);
    // });
    this.currentSection$
      .pipe(startWith(''), pairwise())
      .subscribe((currentSection) => {
        if (currentSection[0])
          this.renderer.removeClass(this.document.body, currentSection[0]);
        if (currentSection[1])
          this.renderer.addClass(this.document.body, currentSection[1]);
      });

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        // console.warn("event instanceof NavigationStart");
      }
      if (event instanceof NavigationEnd) {
        // this.renderer.removeClass(this.document.body, "navigationOpened");
        // // this.aRef.tick();
        // console.warn("event instanceof NavigationEnd");
        if (this.checkPlatform.isPlatformBrowser) {
          // setTimeout(() => {
          //   // this.scrollService.scroll();
          // }, 3000);
          this.store.dispatch([
            new ActivateNewLayout(),
            new ShowLayoutOverlays(),
          ]);
          // setTimeout(() => {
          //   this.scrollService.scrollToElementById("footer-nav-item--active");
          //   // this.scrollService.scroll();
          // }, 500);

          // Google Analytics
          gtag('config', environment.googleAnalytics, {
            page_path: event.urlAfterRedirects,
          });
        }
      }
    });
    /**
     * Navigation Animation
     */
    // this.currentSection$.pipe(distinctUntilChanged()).subscribe((section) => {
    //   section === "shop" ? this.setTopPos() : this.setBottomPos();
    // });
  }
  ngOnInit() {
    this.layoutAnimationState$ = this.store
      .select(LayoutState.getLayoutAnimationState)
      .pipe(
        distinctUntilChanged(),
        tap((state) => console.debug('layoutAnimationState', state))
      );
    // gsap.set(this.tweenObjP, {
    //   fromBottom: this.store.selectSnapshot(ContentState.getCurrentSection) === "shop" ? 1 : 0,
    // });

    this.actions$
      .pipe(ofActionDispatched(RouterNavigation), untilDestroyed(this))
      .subscribe(() => {
        // console.warn("ofActionDispatched(RouterNavigation)");
        this.store.dispatch(new HideCart());
      });
    // this.actions$
    //   .pipe(ofActionSuccessful(RouterNavigation), untilDestroyed(this))
    //   .subscribe(() => {
    //     // console.warn("ofActionSuccessful(RouterNavigation)");
    //   });
    this.actions$
      .pipe(ofActionSuccessful(RouterDataResolved), untilDestroyed(this))
      .subscribe(() => {
        // console.warn("ofActionSuccessful(RouterDataResolved)");
        this.store.dispatch(new CloseNavigation());
      });
  }
  ngOnDestroy() { }

  ////

  _animationState: any;
  set animationState(event) {
    this._animationState = [event.toState, event.phaseName].join('-');
  }
  get animationState() {
    return this._animationState;
  }

  animationStarted(event: AnimationEvent) {
    this.animationState = event;
  }
  animationStopped(event: AnimationEvent) {
    this.animationState = event;
  }
}
