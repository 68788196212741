import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { LanguageGuard } from '@costes/library/guards';

const routes: Routes = [
  // {
  //   path: ':lang',
  //   loadChildren: () => import('./main/main.module').then((m) => m.MainModule),
  //   canActivate: [LanguageGuard],
  // },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // preloadingStrategy: PreloadAllModules,
      initialNavigation: 'disabled', // inital navigatin done in APP_INITIALIZER
      onSameUrlNavigation: 'reload',
      scrollPositionRestoration: 'disabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
