import { DOCUMENT } from '@angular/common';
import {
  Injectable,
  HostListener,
  Inject,
  Renderer2,
  InjectionToken,
  RendererFactory2,
} from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { filter, firstValueFrom } from 'rxjs';

export const ON_APP_LOAD_CLASS = new InjectionToken<string>('OnAppLoad');

@Injectable({ providedIn: 'root' })
export class OnAppLoadService {
  private renderer: Renderer2;

  @HostListener('window:beforeunload', ['$event'])
  unloadHandler(event: BeforeUnloadEvent) {
    event.preventDefault();
    // Your logic on beforeunload
    this.renderer.addClass(this.document.body, this.className);
  }

  constructor(
    @Inject(ON_APP_LOAD_CLASS) private className: string,
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    private rendererFactory: RendererFactory2
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
    if (!this.document.body.classList.contains(this.className)) {
      console.error(
        `Body is missing the '${this.className}' class for OnAppLoadDirective`
      );
      return;
    }
    this.waitForInitialNavigation();
  }
  async waitForInitialNavigation() {
    await firstValueFrom(this.router.events.pipe(filter((event: Event) => event instanceof NavigationEnd))).catch((err) => { console.error(err); this.init() });
    this.init();
  }
  init() {
    this.renderer.removeClass(this.document.body, this.className);
    this.renderer.addClass(this.document.body, 'initialized');
    // @TODO make this app agnostic. currently this is tailored to plage palace #intro element and the animation duration for it
    setTimeout(() => {
      this.renderer.addClass(this.document.body, 'intro-played');
    }, 3000);
  }
}
