import { Pipe, PipeTransform } from "@angular/core";
import { getCurrencySymbol } from "@angular/common";

@Pipe({
  name: "currencySymbol",
  standalone: true,
})
export class CurrencySymbolPipe implements PipeTransform {
  transform(iso: string, format: "wide" | "narrow" = "wide"): string {
    return getCurrencySymbol(iso, format);
  }
}
