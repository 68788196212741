import { Injectable } from '@angular/core';
import { State, Action, Selector, StateContext } from '@ngxs/store';
import {
  PlaySoundscape,
  PauseSoundscape,
  SetVolume,
  SetAudioTrack,
  SetSoundscapePlayerState,
} from './soundscape.actions';
import { Tracks } from 'typings';
import { SendGoogleAnalyticsEvent } from '@costes/library/store/app';

export type PlayerState = 'playing' | 'pause';


export interface SoundscapeStateModel {
  state: PlayerState;
  tracks: Tracks;
  activeTrack?: number;
  volume: number;
}

@State<SoundscapeStateModel>({
  name: 'soundscape',
  defaults: {
    state: 'pause',
    tracks: {},
    activeTrack: undefined,
    volume: 0.0,
  },
})
@Injectable()
export class SoundscapeState {

  @Selector()
  static getState(state: SoundscapeStateModel) {
    return state.state;
  }

  @Selector()
  static getVolume(state: SoundscapeStateModel) {
    return state.volume;
  }
  @Selector()
  static getTracks(state: SoundscapeStateModel) {
    return state.tracks;
  }
  @Selector()
  static getActiveTrack(state: SoundscapeStateModel) {
    return state.activeTrack;
  }

  @Action(PlaySoundscape)
  playSoundscape(
    { dispatch }: StateContext<SoundscapeStateModel>,
    action: PlaySoundscape
  ) {
    dispatch(
      new SendGoogleAnalyticsEvent(
        'clic_soundscape',
        'Musique',
        'Play Soundscape'
      )
    );
  }

  @Action(PauseSoundscape)
  pauseSoundscape(
    { dispatch }: StateContext<SoundscapeStateModel>,
    action: PauseSoundscape
  ) {
    dispatch(
      new SendGoogleAnalyticsEvent(
        'clic_soundscape',
        'Musique',
        'Pause Soundscape'
      )
    );
  }

  @Action(SetSoundscapePlayerState)
  setSoundscapePlayerState(
    { patchState }: StateContext<SoundscapeStateModel>,
    { state }: SetSoundscapePlayerState
  ) {
    return patchState({ state: state });
  }

  @Action(SetVolume)
  setVolume(
    { patchState }: StateContext<SoundscapeStateModel>,
    { volume }: SetVolume
  ) {
    return patchState({ volume: Math.min(Math.max(volume, 0), 1) });
  }

  @Action(SetAudioTrack)
  setAudioTrack(
    { getState, patchState }: StateContext<SoundscapeStateModel>,
    { track }: SetAudioTrack
  ) {
    const state = getState();
    if (!(`${track.id}` in state.tracks)) {
      state.tracks[track.id] = track;
    }
    return patchState({ tracks: state.tracks, activeTrack: track.id });
  }
}
