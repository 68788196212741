import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PlayPauseIconComponent } from "./play-pause-icon.component";

@NgModule({
  declarations: [PlayPauseIconComponent],
  imports: [CommonModule],
  exports: [PlayPauseIconComponent],
})
export class PlayPauseIconModule {}
