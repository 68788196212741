import {
  UpdateCartBody,
  UpdateCartResponse,
  Image,
  CartMessage,
} from "typings";

export class GetCsrfToken {
  public static readonly type = "[Shop] Get CSRF Token";
  constructor() { }
}
export class GetAllCurrencies {
  public static readonly type = "[Shop] Get All Currencies";
  constructor() { }
}
export class RehydrateCart {
  public static readonly type = "[Shop] Rehydrate Cart";
  constructor(public number: string) { }
}
export class AddToCart {
  public static readonly type = "[Shop] Add To Cart";
  constructor(
    public purchasableId: number,
    public qty: number = 1,
    public title?: string,
    public image?: Image,
    public productId?: number
  ) { }
}

export class ApplyCoupon {
  public static readonly type = "[Shop] Apply Coupon";
  constructor(public couponCode: string) { }
}
export class ClearCart {
  public static readonly type = "[Shop] Clear Cart";
  constructor() { }
}
export class UpdateCart {
  public static readonly type = "[Shop] Update Cart";
  constructor(public body: UpdateCartBody) { }
}

export class UpdateCartSuccess {
  public static readonly type = "[Shop] Updating Cart";
  constructor(public resp: UpdateCartResponse) { }
}

export class UpdateCartError {
  public static readonly type = "[Shop] Error while updating Cart";
  constructor(public resp: UpdateCartResponse) { }
}

export class ToggleCart {
  public static readonly type = "[Shop] Toggle Cart";
  constructor() { }
}
export class ShowCart {
  public static readonly type = "[Shop] Show Cart";
  constructor() { }
}
export class HideCart {
  public static readonly type = "[Shop] Hide Cart";
  constructor() { }
}

export class UpdateCurrency {
  public static readonly type = "[Shop] Update Currency";
  constructor(public code: string) { }
}

export class RegisterUser {
  public static readonly type = "[Shop] Register User";
  constructor(
    public username: string,
    public email: string,
    public password: string
  ) { }
}
export class RegisterUserSuccess {
  public static readonly type = "[Shop] Registering User";
  constructor(public resp: any) { }
}

export class LoginUser {
  public static readonly type = "[Shop] Login User";
  constructor(public loginName: string, public password: string) { }
}
export class LoginUserSuccess {
  public static readonly type = "[Shop] Logging In User";
  constructor(public resp: any) { }
}

export class SetCartMessage {
  public static readonly type = "[Shop] Message From Shop";
  constructor(public message: CartMessage) { }
}
export class DismissCartMessage {
  public static readonly type = "[Shop] Dismiss Message";
  constructor() { }
}
