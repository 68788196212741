import { Injectable, ApplicationRef } from '@angular/core';
import { State, Action, Selector, StateContext } from '@ngxs/store';
import {
  SetNewLayout,
  ActivateNewLayout,
  HideLayoutOverlays,
  ShowLayoutOverlays,
  HomepageVisited,
} from './layout.actions';

export type LayoutName =
  | 'panels'
  | 'grid'
  | 'mosaic'
  | 'streamPage'
  | 'infoPage'
  | 'detailPage'
  | 'space'
  | 'accomodations'
  | 'playlists'
  | 'home';

export type PanelName = 'details' | 'topics' | 'booking';

export type LayoutStateInterface<T> = {
  content: T;
  state: boolean;
};

export interface LayoutStateModel {
  currentLayout?: LayoutName;
  nextLayout?: LayoutName;
  layouts?: {
    [K in LayoutName]: LayoutStateInterface<any>;
  };
  showLayoutOverlays: boolean;
  showPanel?: PanelName;
  homepageVisited: boolean;
}

@State<LayoutStateModel>({
  name: 'layout',
  defaults: {
    currentLayout: undefined,
    nextLayout: undefined,
    layouts: undefined,
    showLayoutOverlays: false,
    showPanel: undefined,
    homepageVisited: false,
  },
})
@Injectable()
export class LayoutState {
  constructor(private aRef: ApplicationRef) {}

  // @Selector()
  // static getContent(state: LayoutStateModel) {
  //   return (layout: LayoutName) => {
  //     return state.layouts[layout].content;
  //     // if ([state.currentLayout, state.nextLayout].includes(layout) && state.layouts[layout].state) {
  //     //   return state.layouts[layout].content;
  //     // }
  //   };
  // }
  // @Selector()
  // static getCurrentContent(state: LayoutStateModel) {
  //   return state.layouts[state.currentLayout].state ? state.layouts[state.currentLayout].content : null;
  // }

  @Selector()
  static showLayout(state: LayoutStateModel) {
    return (layout: LayoutName) => {
      return (
        [state.currentLayout, state.nextLayout].includes(layout) &&
        layout &&
        state.layouts &&
        state.layouts[layout].state
      );
    };
  }

  // @Selector()
  // static getTitle(state: LayoutStateModel) {
  //   return state.layouts[state.currentLayout].state
  //     ? state.layouts[state.currentLayout].content.headline
  //       ? state.layouts[state.currentLayout].content.headline
  //       : state.layouts[state.currentLayout].content.title
  //     : "";
  // }

  // @Selector()
  // static getBreadcrumb(state: LayoutStateModel) {
  //   return state.layouts[state.currentLayout].state ? [{ title: "Dummy Breadcrumb" }, { title: "Dummy Breadcrumb" }] : [];
  //   // return state.layouts[state.currentLayout].state ? state.layouts[state.currentLayout].content.breadcrumb : [];
  // }

  @Selector()
  static showLayoutOverlays(state: LayoutStateModel) {
    return state.showLayoutOverlays;
  }

  @Selector()
  static homepageVisited(state: LayoutStateModel) {
    return state.homepageVisited;
  }

  // @Selector()
  // static showPanel(state: LayoutStateModel) {
  //   return state.showPanel;
  // }
  @Selector()
  static getLayoutAnimationState(state: LayoutStateModel) {
    return state.nextLayout || state.currentLayout;
    // return state.nextLayout && state.currentLayout ? `${state.currentLayout}-${state.nextLayout}` : state.nextLayout || state.currentLayout;
    // return `${state.currentLayout}-${state.nextLayout}`;
  }
  // @Selector()
  // static getPanelContent(state: LayoutStateModel) {
  //   if (!state.layouts[state.currentLayout].state) return null;
  //   return state.layouts[state.currentLayout].content[state.showPanel];
  // }

  @Action(SetNewLayout)
  setNewLayout(
    { setState, getState }: StateContext<LayoutStateModel>,
    { page }: SetNewLayout
  ) {
    const state = getState();
    const layoutName = this.getLayoutName(page);

    return setState({
      ...state,
      nextLayout: layoutName,
      // layouts: {
      //   ...state.layouts,
      //   [layoutName]: {
      //     content: page,
      //     state: true
      //   }
      // },
      showLayoutOverlays: false,
      showPanel: undefined,
    });
  }

  // @Action(HideOldLayout)
  // hideOldLayout({ getState, setState }: StateContext<LayoutStateModel>, {}: HideOldLayout) {
  //   const state = getState();
  //   if (state.currentLayout) {
  //     setState({
  //       ...state,
  //       layouts: {
  //         ...state.layouts,
  //         [state.currentLayout]: {
  //           ...state.layouts[state.currentLayout],
  //           state: state.currentLayout === state.nextLayout ? true : false
  //         }
  //       }
  //     });
  //   }
  // }
  @Action(ActivateNewLayout)
  activateNewLayout(
    { setState, getState }: StateContext<LayoutStateModel>,
    {}: ActivateNewLayout
  ) {
    const state = getState();
    // console.debug("ActivateNewLayout", state.currentLayout, state.nextLayout);
    setState({
      ...state,
      currentLayout: state.nextLayout || state.currentLayout,
      nextLayout: undefined,
    });
  }

  @Action(HideLayoutOverlays)
  hideLayoutOverlays(
    { setState, getState }: StateContext<LayoutStateModel>,
    {}: HideLayoutOverlays
  ) {
    const state = getState();

    return setState({
      ...state,
      showLayoutOverlays: false,
    });
  }
  @Action(ShowLayoutOverlays)
  showLayoutOverlays(
    { setState, getState }: StateContext<LayoutStateModel>,
    {}: ShowLayoutOverlays
  ) {
    const state = getState();
    return setState({
      ...state,
      showLayoutOverlays: true,
    });
  }

  // @Action(TogglePanel)
  // togglePanel({ setState, getState }: StateContext<LayoutStateModel>, { panel }: TogglePanel) {
  //   const state = getState();

  //   return setState({
  //     ...state,
  //     showPanel: panel === state.showPanel ? null : panel
  //   });
  // }

  /**
   * Get layout name of page object
   * @param page page object to search for layout name
   */
  getLayoutName(page: any): LayoutName {
    // prettier-ignore
    const layout = page.section === "pages" ? (page.entryType === "listingPage"
                 ? page.layout
                 : page.entryType)
                 : page.section;
    return layout === 'space' ? 'accomodations' : layout;
  }

  @Action(HomepageVisited)
  homepageVisited(
    { setState, getState }: StateContext<LayoutStateModel>,
    {}: HomepageVisited
  ) {
    const state = getState();
    return setState({
      ...state,
      homepageVisited: true,
    });
  }
}

// BACKUP
// if (page.section === "pages") {
//   if (page.entryType === "listingPage") {
//     return page.layout;
//   } else {
//     return page.entryType;
//   }
// } else {
//   return page.section;
// }
