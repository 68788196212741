import { Pipe, PipeTransform, OnInit, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Currency } from 'typings';
import { CurrencyPipe } from '@angular/common';
import { Store } from '@ngxs/store';

import { ShopState } from '@costes/library/store/shop';
import { LanguageState } from '@costes/library/store/language';

@Pipe({
  name: 'convertPrice',
  standalone: true,
  pure: false,
})
export class ConvertedPricePipe implements PipeTransform {
  currency;
  lang;

  constructor(private store: Store, private currencyPipe: CurrencyPipe) {
    this.currency = toSignal(this.store
      .select(ShopState.getPaymentCurrency))
    this.lang = toSignal(this.store
      .select(LanguageState.getLanguage))
  }

  transform(
    value: number,
    currency: Currency | undefined = this.currency()
  ): string | null {
    if (!currency || !this.lang()) return null;
    const _value = value * currency.rate;
    if (!_value) return _value + '';

    return this.currencyPipe.transform(
      _value,
      currency.alphabeticCode,
      'symbol-narrow',
      '0.0-2',
      this.lang()
    );
  }
}
