import { Injectable } from '@angular/core';
import { State, Action, Selector, StateContext, Store } from '@ngxs/store';
import {
  ToggleNavigation,
  OpenNavigation,
  CloseNavigation,
  GetNavigation,
  GetNavigationSuccess,
  SetOnPageNavigation,
  SetLanguageNavigation,
} from './navigation.actions';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { ErrorMessage } from '../app.actions';
import { throwError } from 'rxjs';
import { DateUpdated } from 'typings';
import { LanguageState } from '@costes/library/store/language';
import { SetNewSrc } from '@costes/library/store/radio';

const Nothing = Symbol('nothing');
type Nothing = typeof Nothing;
type Maybe<T> = T | Nothing;

export type NavigationName =
  | 'bookingMenu'
  | 'mainMenu'
  | 'contactInfo'
  | 'onPageMenu'
  | 'languageMenu'
  | 'footerMenu'
  | 'streamLink'
  | 'streamLinkEntry'
  | 'subMenu'
  | 'webLinks';

export type LangObject = {
  lang: string;
  url: string;
};

export type NavigationResponseObject = {
  [K in NavigationName]?: any;
};

export type NavigationStateInterface<T> = {
  items: T;
  state: boolean;
};
export type ContactInfoInterface = {
  items: any[] | {};
  state: boolean;
  id: number;
  type: string;
  dateUpdated: DateUpdated;
  headline: string;
  locationAddress?: {
    coords: {
      lat: string;
      lng: string;
    };
    street: string;
    number: string;
    city: string;
    county: string;
    postcode: string;
    state: string;
    country: string;
    line1: string;
    line2: string;
    line3: string;
    htmlNational: string;
    htmlInternational: string;
  };
  phoneNumber?: {
    countryCode: string;
    prefix: number;
    number: string;
    international: string;
    national: string;
    e164: string;
    rfc3966: string;
  };
  emailAddress: string;
};

export type NavigationStateModel = {
  [K in NavigationName]?: any;
};

@State<NavigationStateModel>({
  name: 'navigation',
  defaults: {
    mainMenu: {
      items: [],
      state: false,
    },
    bookingMenu: {
      items: [],
      state: false,
    },
    contactInfo: {
      items: {},
      state: false,
    },
    onPageMenu: {
      items: [],
      state: false,
    },
    languageMenu: {
      items: [] as LangObject[],
      state: false,
    },
    footerMenu: {
      items: [],
      state: false,
    },
    streamLink: {
      items: {} as {},
      state: false,
    },
    streamLinkEntry: {
      items: {} as {},
      state: false,
    },
  },
})
@Injectable()
export class NavigationState {
  constructor(
    private http: HttpClient,
    private store: Store,
  ) { }

  @Selector()
  public static getNavigationContent(state: NavigationStateModel) {
    return state;
  }
  @Selector()
  static getContactInfo(state: NavigationStateModel) {
    return state.contactInfo;
  }
  @Selector()
  static getWebLinks(state: NavigationStateModel) {
    return state.webLinks;
  }
  @Selector()
  static getNavigation(state: NavigationStateModel) {
    return (navName: NavigationName) => {
      return state[navName];
    };
  }

  @Selector()
  static getNavigationState(state: NavigationStateModel) {
    return (navName: NavigationName) => {
      return state[navName].state;
    };
  }

  @Action(ToggleNavigation)
  public toggleNavigation(
    { setState }: StateContext<NavigationStateModel>,
    { navName }: ToggleNavigation
  ) {
    setState(this.toggleNavigationState(navName));
  }

  @Action(OpenNavigation)
  public openNavigation(
    { setState }: StateContext<NavigationStateModel>,
    { navName }: OpenNavigation
  ) {
    setState(this.setNavigationState(navName, true));
  }
  @Action(CloseNavigation)
  public closeNavigation(
    { setState }: StateContext<NavigationStateModel>,
    { navName }: CloseNavigation
  ) {
    if (navName) {
      setState(this.setNavigationState(navName, false));
    } else {
      setState(this.setNavigationState('mainMenu', false));
      setState(this.setNavigationState('bookingMenu', false));
      setState(this.setNavigationState('bookingMenu', false));
    }
  }

  setNavigationState(name: NavigationName, newValue: boolean) {
    return (state: NavigationStateModel) => ({
      ...state,
      [name]: { ...state[name], state: newValue },
    });
  }
  toggleNavigationState(name: NavigationName) {
    return (state: NavigationStateModel) => ({
      ...state,
      [name]: { ...state[name], state: !state[name].state },
    });
  }

  /**
   * Set OnPage Navigation
   * @param navigation  New or Empty Array of OnPage Navigation Items
   * @param navState Should we show the OnPage Navigation
   */
  @Action(SetOnPageNavigation)
  public setOnPageNavigation(
    { getState, setState }: StateContext<NavigationStateModel>,
    { navigation, navState }: SetOnPageNavigation
  ) {
    const state = getState();
    setState({
      ...state,
      onPageMenu: {
        items: navigation,
        state: navState,
      },
    });
  }

  /**
   * Set Language Switch Navigation Items
   * @param navigation  Set translated URLS for current page
   */
  @Action(SetLanguageNavigation)
  public setLanguageNavigation(
    { getState, setState }: StateContext<NavigationStateModel>,
    { languageUrls }: SetLanguageNavigation
  ) {
    const state = getState();
    let newLanguageUrls: Array<LangObject> = [];
    if (languageUrls)
      Object.keys(languageUrls).forEach((key) => {
        if (!languageUrls[key]) return;
        newLanguageUrls.push({
          lang: key.substring(0, 2).toLowerCase(),
          url: languageUrls[key] as any,
        })
      }
      );
    setState({
      ...state,
      languageMenu: {
        items: newLanguageUrls,
        state: languageUrls ? true : false,
      },
    });
  }

  /**
   * Get Navigation Object from Backend
   */
  @Action(GetNavigation)
  public getNavigation(
    { dispatch }: StateContext<NavigationStateModel>,
    { }: GetNavigation
  ) {
    const currentLanguageSiteEndpoint = this.store.selectSnapshot(
      LanguageState.getCurrentLanguageSiteEndpoint
    );

    return this.http.get('/api/config/navigation.json', {
      withCredentials: true,
      params: {
        site: currentLanguageSiteEndpoint,
      },
    })
      .pipe(
        map((resp) => {
          return dispatch(new GetNavigationSuccess(resp));
        }),
        catchError((error) => {
          dispatch(new ErrorMessage(error));
          return throwError(
            () => new Error('Error while receiving navigation')
          );
        })
      );
  }

  @Action(GetNavigationSuccess)
  public getNavigationSuccess(
    { getState, setState }: StateContext<NavigationStateModel>,
    { navigation }: GetNavigationSuccess
  ) {
    let state = getState();
    Object.keys(navigation).forEach((key) => {
      state = {
        ...state,
        [key]: {
          ...state[key as NavigationName],
          items: navigation[key as NavigationName],
        },
      };
    });
    if (navigation.streamLink) {
      this.store.dispatch(
        new SetNewSrc(
          navigation.streamLink.url,
          navigation.streamLink.title,
          navigation.streamLinkEntry
        )
      );
    }
    setState(state);
    // this.aRef.tick();
  }
}
