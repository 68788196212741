import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  HostBinding,
} from '@angular/core';
import { Product, Currency } from 'typings';
import { Store, Select } from '@ngxs/store';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';

import { AddToCart, ShopState } from '@costes/library/store/shop';
import { LanguageState } from '@costes/library/store/language';
import { MediaComponent } from '@costes/library/components/media';
import { SharedModule } from '@costes/library';
import { ConvertedPricePipe } from '@costes/library/pipes/converted-price';

@UntilDestroy()
@Component({
  selector: 'costes-product-list-item',
  standalone: true,
  imports: [SharedModule, MediaComponent, ConvertedPricePipe],
  templateUrl: './product-list-item.component.html',
  styleUrls: ['./product-list-item.component.scss'],
})
export class ProductListItemComponent implements OnInit, OnDestroy {
  @Input('data') product!: Product;
  @Input() showProductImage: boolean = false;

  @HostBinding('class') get classes() {
    return `${this.product.itemSize} ${this.product.coverMedia?.image?.orientation ?? this.product.coverMedia?.video?.orientation}`;
  }

  paymentCurrency?: Currency;

  @Select(LanguageState.getLanguage) lang$!: Observable<string>;

  constructor(private store: Store) {
    this.store
      .select(ShopState.getPaymentCurrency)
      .subscribe((currency) => (this.paymentCurrency = currency));
  }

  ngOnInit() {
    // console.log(this.product);
  }

  addToCart(purchasableId: number) {
    // console.log(this.product);
    this.store.dispatch(
      new AddToCart(
        purchasableId,
        1,
        this.product?.title,
        this.product?.coverMedia.image,
        this.product?.id
      )
    );
  }

  ngOnDestroy() { }
}
