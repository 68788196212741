import { Component, OnInit, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { filter, debounceTime } from 'rxjs/operators';
import { UntilDestroy } from '@ngneat/until-destroy';
import { CartMessage } from 'typings';


import { moveInFromTop } from '@costes/library/animations';
import { ShopState, DismissCartMessage, ShowCart } from '@costes/library/store/shop';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'costes-add-to-cart-message',
  templateUrl: './add-to-cart-message.component.html',
  styleUrls: ['./add-to-cart-message.component.scss'],
  animations: [moveInFromTop],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddToCartMessageComponent implements OnInit, OnDestroy {
  @Select(ShopState.getCartMessage) message$!: Observable<CartMessage>;

  constructor(private store: Store) { }

  ngOnInit() {
    // Auto Dismiss of messsages after n-Seconds
    this.message$
      .pipe(
        filter((message) => message != null),
        debounceTime(5000)
      )
      .subscribe((message) => {
        this.store.dispatch(new DismissCartMessage());
      });
  }
  ngOnDestroy() { }

  public dismiss() {
    this.store.dispatch(new DismissCartMessage());
  }
  public showCart() {
    this.store.dispatch([new DismissCartMessage(), new ShowCart()]);
  }
}
