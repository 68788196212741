<header>
  <a [routerLink]="['/', currentLang$ | async]">
    <ng-content></ng-content>
  </a>
</header>
<costes-breadcrumb class="smll"></costes-breadcrumb>
<aside>
  <nav
    role="navigation"
    id="mainNav--wrapper"
    [attr.aria-label]="'aria-label.main-navigation' | translate"
    *ngIf="mainNavigation$ | async as mainNavigation"
  >
    <button
      class="as-link"
      [class.active]="mainNavigation.state"
      aria-haspopup="menu"
      (click)="toggleNavigation('mainMenu')"
    >
      <div>{{ "buttons.menu" | translate }}</div>
    </button>
    <div
      #navWrapper
      class="nav-wrapper"
      id="navWrapper"
      role="menu"
      aria-modal="true"
      (click)="closeNavigation()"
      (ngxResize)="repositionNavigation($event)"
    >
      <div id="mainNavWrapper" #mainNavWrapper>
        <ul id="mainNav">
          <li
            *ngFor="let link of mainNavigation.items"
            [ngSwitch]="link.navLink.type"
          >
            <ng-container
              *ngSwitchCase="'entryLink'"
              [ngTemplateOutlet]="internalLink"
              [ngTemplateOutletContext]="{ link: link.navLink }"
            ></ng-container>
            <ng-container
              *ngSwitchCase="'categoryLink'"
              [ngTemplateOutlet]="internalLink"
              [ngTemplateOutletContext]="{ link: link.navLink }"
            ></ng-container>
            <ng-container
              *ngSwitchDefault
              [ngTemplateOutlet]="externalLink"
              [ngTemplateOutletContext]="{ link: link.navLink }"
            ></ng-container>
          </li>
        </ul>
        <div
          id="langNavMobile"
          [class.hidden]="!(langNavigation$ | async)?.state"
        >
          <ng-container
            *ngFor="
              let item of (langNavigation$ | async)?.items;
              let last = last
            "
          >
            <a
              class="nav-link-item"
              rel="alternate"
              [hreflang]="item.lang"
              [lang]="item.lang"
              [routerLink]="[item.url | replace]"
              routerLinkActive="active"
              [title]="'buttons.language.' + item.lang | translate"
              [innerHTML]="item.lang"
            ></a
            ><span *ngIf="!last" aria-hidden="true">/</span>
          </ng-container>
        </div>
        <div id="subNav" class="smll">
          <ul>
            <li
              *ngFor="let link of (subNavigation$ | async)?.items"
              [ngSwitch]="link.navLink.type"
            >
              <ng-container
                *ngSwitchCase="'entryLink'"
                [ngTemplateOutlet]="internalLink"
                [ngTemplateOutletContext]="{ link: link.navLink }"
              ></ng-container>
              <ng-container
                *ngSwitchCase="'categoryLink'"
                [ngTemplateOutlet]="internalLink"
                [ngTemplateOutletContext]="{ link: link.navLink }"
              ></ng-container>
              <ng-container
                *ngSwitchDefault
                [ngTemplateOutlet]="externalLink"
                [ngTemplateOutletContext]="{ link: link.navLink }"
              ></ng-container>
            </li>
          </ul>
        </div>
        <div id="footerMobile">
          <ng-container *ngIf="isMobile$ | async">
            <ng-container *ngIf="contactInfo$ | async as contactInfo">
              <p
                [innerHTML]="
                  contactInfo?.items?.locationAddress?.htmlInternational
                "
              ></p>
              <p>
                <a
                  [href]="contactInfo.items.phoneNumber?.rfc3966"
                  (click)="
                    sendGoogleAnalyticsEvent(
                      'afficher_telephone',
                      'Prospect',
                      'navigation'
                    )
                  "
                  >tel: {{ contactInfo.items.phoneNumber?.international }}</a
                >
                <br />
                <a
                  href="mailto:{{ contactInfo.items.emailAddress }}"
                  (click)="
                    sendGoogleAnalyticsEvent(
                      'afficher_email',
                      'Prospect',
                      'navigation'
                    )
                  "
                  >{{ contactInfo.items.emailAddress }}</a
                >
              </p>
              <p class="social" *ngIf="webLinks$ | async as webLinks">
                <span
                  *ngFor="let link of webLinks.items"
                  (click)="
                    sendGoogleAnalyticsEvent(
                      'clic_reseaux_sociaux',
                      'engagement',
                      link.text
                    )
                  "
                >
                  <ng-container
                    [ngTemplateOutlet]="externalLink"
                    [ngTemplateOutletContext]="{ link: link }"
                  ></ng-container>
                </span>
              </p>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </nav>
</aside>
<aside>
  <nav
    id="langNav"
    class="smll"
    role="navigation"
    [attr.aria-label]="'aria-label.language-switch' | translate"
  >
    <ng-container *ngIf="langNavigation$ | async as langNavigation">
      <ul *ngIf="langNavigation.state">
        <li *ngFor="let item of langNavigation.items; let last = last">
          <a
            class="nav-link-item"
            rel="alternate"
            [hreflang]="item.lang"
            [lang]="item.lang"
            [routerLink]="[item.url | replace]"
            routerLinkActive="active"
            [title]="'buttons.language.' + item.lang | translate"
            [innerHTML]="item.lang"
          ></a
          ><span *ngIf="!last" aria-hidden="true">/</span>
        </li>
      </ul>
    </ng-container>
  </nav>

  <nav
    id="bookNav"
    *ngIf="showBookingMenu"
    role="navigation"
    aria-labelledby="bookNav-button"
  >
    <button
      id="bookNav-button"
      class="as-link"
      [class.active]="(bookingNavigation$ | async)?.state"
      (click)="toggleNavigation('bookingMenu')"
    >
      {{ "buttons.book" | translate }}
    </button>
    <ng-content select="[booking]"></ng-content>
  </nav>
</aside>

<ng-template #internalLink let-link="link">
  <a
    class="nav-link-item"
    [routerLink]="[link.uri]"
    routerLinkActive="active"
    [fragment]="link.fragment"
    [queryParams]="link.queryParams"
    [routerLinkActiveOptions]="{ exact: true }"
    [ariaCurrentWhenActive]="'page'"
    target="{{ link.target }}"
    rel="{{ link.rel }}"
    [innerHTML]="link.text"
  >
  </a>
</ng-template>
<ng-template #externalLink let-link="link">
  <a
    class="nav-link-item"
    href="{{ link.url }}"
    target="{{ link.target }}"
    rel="{{ link.rel }}"
  >
    <span [innerHTML]="link.text"></span>
    <svg
      width="8px"
      height="8px"
      viewBox="0 0 8 8"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <g
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
        stroke-linecap="round"
      >
        <g transform="translate(0.000000, 1.000000)">
          <polyline
            id="Path-2"
            points="0 0 6 0 6 6"
            vector-effect="non-scaling-stroke"
          ></polyline>
          <line
            x1="0.5"
            y1="5.5"
            x2="6"
            y2="0"
            id="Path-3"
            vector-effect="non-scaling-stroke"
          ></line>
        </g>
      </g>
    </svg>
  </a>
</ng-template>
