import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ShopState, ToggleCart } from '@costes/library/store/shop';
import { LanguageState } from '@costes/library/store/language';

@Component({
  selector: 'costes-shop-nav',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class ShopNavigationComponent implements OnInit {
  @Select(LanguageState.getLanguage)
  currentLang$!: Observable<string>;

  @Select(ShopState.getTotalQty)
  totalQty$!: Observable<number>;

  @Select(ShopState.showCart) showCart$!: Observable<boolean>;

  constructor(private store: Store) { }

  ngOnInit(): void { }

  toggleCart() {
    this.store.dispatch(new ToggleCart());
  }
}
