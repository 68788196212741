<canvas
  *ngIf="image"
  [class.hidden]="isLoaded"
  class="loader"
  drawBlurhash
  width="{{ image.width }}"
  height="{{ image.height }}"
  [blurhash]="image.blurhash"
></canvas>
<picture
  [ngClass]="[
    video ? 'video-item' : 'image-item',
    mixed ? 'mixed' : '',
    image?.extension ?? ''
  ]"
  [attr.itemprop]="video ? 'video' : 'image'"
>
  <ng-container *ngIf="data && (video || image); else noMedia">
    <ng-container *ngIf="!video && image">
      <!-- <source
        *ngIf="image.media.srcset"
        [srcset]="image.media.srcset"
        [sizes]="sizes"
      /> -->
      <img
        ngSrc="{{ image.url | replace }}"
        ngSrcset="320w, 600w, 900w, 1280w, 1600w, 1900w, 2560w"
        [width]="fill ? undefined : image.width"
        [height]="fill ? '' : image.height"
        [fill]="fill ? 'fill' : undefined"
        [alt]="image.altText"
        [sizes]="sizes"
        [style.object-position]="image.focalPoint | focalpoint"
        (load)="onLoad($event)"
        [priority]="priority"
      />
    </ng-container>
    <ng-container *ngIf="video" [ngSwitch]="video.kind">
      <div
        *ngIf="video.poster?.url || video.embed?.poster || image?.url"
        #poster
        class="poster"
      >
        <ng-container *ngIf="mixed">
          <img
            *ngSwitchCase="'video'"
            ngSrc="{{ image?.url || video.poster?.url || '' | replace }}"
            ngSrcset="320w, 600w, 900w, 1280w, 1600w, 1900w, 2560w"
            [width]="fill ? undefined : image?.width || video.width || '1920'"
            [height]="
              fill ? undefined : image?.height || video.height || '1080'
            "
            [fill]="fill ? true : undefined"
            [sizes]="sizes"
            alt=""
            [priority]="priority"
            (load)="onLoad($event)"
          />
          <img
            *ngSwitchCase="'embeddedAsset'"
            [src]="image?.url || video.embed?.poster || video.poster?.url || ''"
            [width]="fill ? undefined : video.height || image?.width || '1920'"
            [height]="fill ? undefined : video.width || image?.height || '1080'"
            [sizes]="sizes"
            alt=""
            [loading]="priority ? 'eager' : 'lazy'"
            (load)="onLoad($event)"
          />
        </ng-container>
        <ng-container *ngIf="!mixed">
          <img
            *ngSwitchCase="'video'"
            ngSrc="{{ video.poster?.url || image?.url || '' | replace }}"
            ngSrcset="320w, 600w, 900w, 1280w, 1600w, 1900w, 2560w"
            [width]="fill ? undefined : video.width || image?.width || '1920'"
            [height]="
              fill ? undefined : video.height || image?.height || '1080'
            "
            [fill]="fill ? true : undefined"
            [sizes]="sizes"
            alt=""
            [priority]="priority"
            (load)="onLoad($event)"
          />
          <ng-container *ngSwitchCase="'embeddedAsset'">
            <img
              *ngIf="video.embed?.files?.length"
              [src]="
                video.embed?.poster || video.poster?.url || image?.url || ''
              "
              [width]="
                fill ? undefined : video.height || image?.width || '1920'
              "
              [height]="
                fill ? undefined : video.width || image?.height || '1080'
              "
              [sizes]="sizes"
              alt=""
              (load)="onLoad($event)"
            />
          </ng-container>
        </ng-container>
      </div>
      <video
        #videoElRef
        *ngSwitchCase="'video'"
        poster="{{ video.poster?.url || image?.url }}"
        preload="metadata"
        [muted]="muted$ | async"
        webkit-playsinline="true"
        playsinline="true"
        loop="true"
        loading="lazy"
        [width]="video.width"
        [height]="video.height"
        (canplay)="onCanPlay()"
        (loadedmetadata)="onMetadata($event, videoElRef)"
      >
        <ng-container
          *ngFor="
            let source of video.media?.sources;
            trackBy: trackBySourceId;
            let last = last
          "
        >
          <source
            *ngIf="source.url"
            [src]="source.url"
            [type]="source.mimeType"
          />
        </ng-container>
        <source
          [src]="video.url"
          *ngIf="video && currentViewportWidth && !video.media?.sources?.length"
          [type]="video.mimeType"
        />
        Your browser does not support the video tag.
      </video>
      <ng-container *ngSwitchCase="'embeddedAsset'">
        <video
          #videoElRef
          *ngIf="video.embed?.files?.length; else noEmbedFiles"
          poster="{{ video.embed?.poster || video.poster?.url || image?.url }}"
          preload="auto"
          [muted]="muted$ | async"
          webkit-playsinline="true"
          playsinline="true"
          loop="true"
          loading="lazy"
          [width]="video.embed?.width"
          [height]="video.embed?.height"
          (canplay)="onCanPlay()"
          (loadedmetadata)="onMetadata($event, videoElRef)"
        >
          <ng-container
            *ngFor="
              let file of video.embed?.files;
              trackBy: trackByFileRendition;
              let last = last
            "
          >
            <source *ngIf="file.link" [src]="file.link" [type]="file.type" />
          </ng-container>
        </video>
        <ng-template #noEmbedFiles>
          <div class="embed">
            <iframe
              *ifChanges="
                (muted$ | async) ||
                (video.embed?.iframeSrcBackground || '' | safe : 'resourceUrl')
              "
              #iframeElRef
              [src]="
                video.embed?.iframeSrcBackground || '' | safe : 'resourceUrl'
              "
              [width]="video.embed?.width"
              [height]="video.embed?.height"
              frameborder="0"
              allowfullscreen
              allow="autoplay"
              (load)="onLoad($event)"
            ></iframe>
          </div>
        </ng-template>
      </ng-container>
    </ng-container>
  </ng-container>
</picture>

<ng-template #noMedia>
  <div class="noMedia"></div>
  <!-- <svg
    width="118px"
    height="90px"
    viewBox="0 0 118 90"
    version="1.1"
    class="noMedia"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Symbols-"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g id="Placeholder" transform="translate(1.000000, 1.000000)">
        <g id="Group">
          <rect id="Rectangle" x="0" y="0" width="116" height="88"></rect>
          <rect id="Rectangle" x="11" y="9" width="94" height="70"></rect>
          <circle id="Oval" cx="30" cy="29" r="7"></circle>
          <path
            d="M11,71.1381597 C20.5961647,61.4370104 26.5399195,55.4241776 28.8312644,53.0996612 C32.2682818,49.6128866 38.9968144,50.5507347 41.3376652,53.0996612 C42.8982324,54.7989455 48.452344,60.8117783 58,71.1381597"
            id="Path-2"
          ></path>
          <path
            d="M34.5,79 C40.2798578,76.3622315 48.1131911,73.7416181 58,71.1381597 C67.8868089,68.5347013 83.5534756,66.2554188 105,64.300312"
            id="Path-4"
          ></path>
          <path
            d="M105,58.8193526 C88.3289394,41.3562761 78.7693123,31.4164919 76.3211185,29 C72.6488279,25.3752622 69.0899977,25.3752622 65.5558592,29 C63.1997668,31.4164919 55.1270355,39.4497122 41.3376652,53.0996612"
            id="Path-5"
          ></path>
        </g>
      </g>
    </g>
  </svg> -->
</ng-template>
