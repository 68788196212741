import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
  Renderer2,
  OnDestroy,
  NgZone,
  ChangeDetectionStrategy,
  InjectionToken,
  inject,
  ChangeDetectorRef,
} from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable, firstValueFrom } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { UntilDestroy } from '@ngneat/until-destroy';
import { NgxResize, NgxResizeResult } from 'ngx-resize';
import { gsap, Sine } from 'gsap';

import { NavigationStateInterface, ContactInfoInterface, NavigationState, SendGoogleAnalyticsEvent, NavigationName, CloseNavigation, ToggleNavigation } from '@costes/library/store/app';
import { LanguageState } from '@costes/library/store/language';
import { CheckPlatformService, DimensionsService, } from '@costes/library';
import { BreadcrumbComponent } from '@costes/library/components/breadcrumb';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ReplacePipe } from '@costes/library/pipes/replace';

export const SHOW_BOOKING_MENU = new InjectionToken<boolean>('Show booking menu', {
  providedIn: 'root',
  factory: () => true,
});

@UntilDestroy()
@Component({
  selector: 'costes-header',
  standalone: true,
  imports: [CommonModule, RouterModule, TranslateModule, ReplacePipe, BreadcrumbComponent, NgxResize],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit, AfterViewInit, OnDestroy {
  @Select(LanguageState.getLanguage)
  currentLang$!: Observable<string>;

  bookingNavigation$?: Observable<NavigationStateInterface<any[]>>;
  langNavigation$: Observable<NavigationStateInterface<any[]>>;
  mainNavigation$: Observable<NavigationStateInterface<any[]>>;
  subNavigation$: Observable<NavigationStateInterface<any[]>>;
  contactInfo$: Observable<NavigationStateInterface<ContactInfoInterface>>;
  webLinks$: Observable<NavigationStateInterface<any[]>>;

  @ViewChild('navWrapper') navWrapper?: ElementRef<HTMLElement>;
  @ViewChild('mainNavWrapper') mainNavWrapper?: ElementRef<HTMLElement>;
  navWrapperListenerInstance: any;

  isMobile$: Observable<boolean> = this.dimensionsService.dimensions.pipe(
    map(dimensions => dimensions.isMobile),
    tap((isMobile) => {
      if (!this.checkPlatform.isPlatformBrowser) return;
      this.ngZone.runOutsideAngular(() => this.createMainNavAnimation(isMobile));
    }));

  showBookingMenu: boolean = inject(SHOW_BOOKING_MENU);

  constructor(
    private renderer: Renderer2,
    private store: Store,
    private ngZone: NgZone,
    private checkPlatform: CheckPlatformService,
    private dimensionsService: DimensionsService,
    private cdRef: ChangeDetectorRef
  ) {

    this.bookingNavigation$ = this.store
      .select(NavigationState.getNavigation)
      .pipe(
        map(
          (filterFn: any) =>
            filterFn('bookingMenu') as NavigationStateInterface<any[]>
        )
      );
    this.langNavigation$ = this.store
      .select(NavigationState.getNavigation)
      .pipe(
        map(
          (filterFn: any) =>
            filterFn('languageMenu') as NavigationStateInterface<any[]>
        )
      );
    this.mainNavigation$ = this.store
      .select(NavigationState.getNavigation)
      .pipe(
        map(
          (filterFn: any) =>
            filterFn('mainMenu') as NavigationStateInterface<any[]>
        )
      );
    this.subNavigation$ = this.store
      .select(NavigationState.getNavigation)
      .pipe(
        map(
          (filterFn: any) =>
            filterFn('subMenu') as NavigationStateInterface<any[]>
        )
      );
    this.contactInfo$ = this.store.select(
      NavigationState.getContactInfo
    ) as Observable<NavigationStateInterface<ContactInfoInterface>>;
    this.webLinks$ = this.store.select(
      NavigationState.getWebLinks
    ) as Observable<NavigationStateInterface<any[]>>;

  }

  ngOnInit() { }

  ngAfterViewInit() {
    this.navWrapperListenerInstance = this.renderer.listen(
      this.navWrapper?.nativeElement,
      'click',
      (event) => {
        if (event.target.matches('a')) event.stopImmediatePropagation();
      }
    );

    /**
     * Navigation Animation
     */
    if (!this.checkPlatform.isPlatformBrowser) return;
    this.mainNavigation$
      .pipe(map((mainNavgitation) => mainNavgitation.state))
      .subscribe((state: boolean) =>
        this.ngZone.runOutsideAngular(() =>
          state ? this.mainNavAnimation.play() : this.mainNavAnimation.reverse()
        )
      );
  }
  ngOnDestroy() {
    if (this.navWrapperListenerInstance) this.navWrapperListenerInstance();
  }

  sendGoogleAnalyticsEvent(
    event_name: string,
    event_category: string,
    event_label: string
  ) {
    this.store.dispatch(
      new SendGoogleAnalyticsEvent(event_name, event_category, event_label)
    );
  }

  toggleNavigation(name: NavigationName = 'mainMenu') {
    const closeName = name === 'mainMenu' ? 'bookingMenu' : 'mainMenu';
    this.store.dispatch([
      new CloseNavigation(closeName),
      new ToggleNavigation(name),
    ]);
  }
  closeNavigation() {
    this.store.dispatch(new CloseNavigation());
  }

  mainNavAnimation = gsap.timeline({ paused: true, reversed: false });
  createMainNavAnimation(isMobile: boolean) {
    this.mainNavAnimation.clear();
    this.mainNavAnimation
      .fromTo(
        '#navWrapper',
        { opacity: 0, display: 'none' },
        {
          duration: 0.3,
          opacity: 1,
          display: 'block',
          ease: Sine.easeInOut,
          force3D: true,
          'touch-action': 'initial',
        }
      )
      .fromTo(
        '#mainNav, #langNavMobile, #subNav, #footerMobile',
        { opacity: 0, scale: isMobile ? 1 : 0.95, translateY: isMobile ? 16 : 0 },
        {
          duration: 0.6,
          stagger: 0.1,
          opacity: 1,
          scale: 1,
          translateY: 0,
          ease: 'back',
          force3D: true,
        },
        '-=0.1'
      );
  }

  async repositionNavigation(event: NgxResizeResult) {
    const mainNavBoundingClientRect = this.mainNavWrapper?.nativeElement.getBoundingClientRect();
    const navWrapperBoundingClientRect = this.navWrapper?.nativeElement.getBoundingClientRect();
    const dimensions = await firstValueFrom(this.dimensionsService.dimensions)
    if (!mainNavBoundingClientRect || !navWrapperBoundingClientRect || !dimensions) return;
    if (mainNavBoundingClientRect.height + (dimensions.vH - dimensions.vInnerH) * 2 > dimensions.vInnerH) {
      this.renderer.setProperty(this.mainNavWrapper?.nativeElement, 'style', '')
    } else {
      this.renderer.setProperty(this.mainNavWrapper?.nativeElement, 'style', `--nav-y:${((dimensions.vInnerH - mainNavBoundingClientRect.height) / 2 - navWrapperBoundingClientRect.top)}px`)
    }
  }
}
