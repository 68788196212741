import { ApplicationRef, Injectable } from '@angular/core';
import { State, Action, Selector, StateContext, NgxsOnInit } from '@ngxs/store';
import { HttpErrorResponse } from '@angular/common/http';
import {
  AppInitialized,
  ErrorMessage,
  ErrorMessageDismiss,
  SendGoogleAnalyticsEvent,
  SetMutedState,
  ToggleMuted,
} from './app.actions';
import { NavigationState } from './navigation/navigation.state';
import { CloseNavigation } from './navigation/navigation.actions';
import { LayoutState } from './layout/layout.state';
import { LoadingBarService } from '@ngx-loading-bar/core';

export interface AppStateModel {
  initialized: boolean;
  errorMessage?: string;
  muted: boolean;
}

declare let gtag: Function;

@State<AppStateModel>({
  name: 'app',
  defaults: {
    initialized: true,
    muted: true
  },
  children: [LayoutState, NavigationState],
})
@Injectable()
export class AppState {
  constructor(
    private aRef: ApplicationRef,
    private loadingBar: LoadingBarService
  ) { }

  @Selector()
  public static isInitialized(state: AppStateModel) {
    return state.initialized;
  }
  @Selector()
  public static muted(state: AppStateModel) {
    return state.muted;
  }


  /**
   * Get current error message
   * @param state
   */
  @Selector()
  public static errorMessage(state: AppStateModel) {
    return state.errorMessage;
  }

  @Action(AppInitialized)
  public appInitialized(
    { patchState, dispatch }: StateContext<AppStateModel>,
    { }: AppInitialized
  ) {
    patchState({ initialized: true });
    // this.aRef.tick();
  }

  @Action(ErrorMessage)
  public errorMessage(
    { patchState, dispatch }: StateContext<AppStateModel>,
    { error }: ErrorMessage
  ) {
    console.error(error);
    if (typeof error === typeof HttpErrorResponse) {
      if (error.error.error) {
        if (error.error.error.code) {
          patchState({
            errorMessage: `Error ${error.error.error.code}: ${error.error.error.message}`,
          });
        } else {
          patchState({
            errorMessage: error.error.error,
          });
        }
      } else {
        patchState({
          errorMessage: `Error ${error.error.data.status}: ${error.error.message} (${error.error.code})`,
        });
      }
      // this.aRef.tick();
    } else if (error && error.code && error.message) {
      patchState({
        errorMessage: `Error ${error.code}: ${error.message}`,
      });
    } else if (error && error.name === 'HttpErrorResponse') {
      if (error?.error?.error) {
        if (error.error.error?.code) {
          patchState({
            errorMessage: `Error ${error.error.error.code}: ${error.error.error.message}`,
          });
        } else {
          patchState({
            errorMessage: error.error.error,
          });
        }
      } else {
        patchState({
          errorMessage: `${error.statusText}: ${error.message} [Code ${error.status}]`,
        });
      }
      // this.aRef.tick();
    } else if (typeof error === typeof {}) {
      patchState({
        errorMessage: Object.values(error).join('\n'),
      });
      // this.aRef.tick();
    } else {
      patchState({
        errorMessage: error,
      });
      // this.aRef.tick();
    }
    this.loadingBar.useRef().stop();
  }

  @Action(ErrorMessageDismiss)
  public errorMessageDismiss(
    { patchState, dispatch }: StateContext<AppStateModel>,
    { }: ErrorMessageDismiss
  ) {
    dispatch(new CloseNavigation());
    patchState({
      errorMessage: undefined,
    });
    // this.aRef.tick();
  }

  @Action(SendGoogleAnalyticsEvent)
  public sendGoogleAnalyticsEvent(
    { }: StateContext<AppStateModel>,
    {
      event_name,
      event_category,
      event_label = undefined,
      event_action = undefined,
      event_value = undefined,
    }: SendGoogleAnalyticsEvent
  ) {
    gtag('event', event_name, {
      event_category,
      event_label,
      event_action,
      event_value,
    });
  }

  @Action(SetMutedState)
  public setMutedState(
    { patchState, dispatch }: StateContext<AppStateModel>,
    { muted }: SetMutedState
  ) {
    patchState({
      muted,
    });
    dispatch(
      new SendGoogleAnalyticsEvent(
        'clic_muted',
        'Audio',
        muted ? 'Mute' : 'Unmute'
      )
    );
  }
  @Action(ToggleMuted)
  public toggleMuted({ getState, dispatch }: StateContext<AppStateModel>) {
    const state = getState();
    dispatch([
      new SetMutedState(
        !state.muted
      ),

    ])

  }
}
