import { ApplicationRef, Injectable } from '@angular/core';
import { State, Action, Selector, StateContext, Store } from '@ngxs/store';
import {
  GetPage,
  GetPageSuccess,
  GetHomeSuccess,
  GetHome,
  SetCurrentPageCacheKey,
  SetSectionName,
} from './content.actions';

import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Router, UrlSerializer } from '@angular/router';
import { Navigate } from '@ngxs/router-plugin';

import { LanguageState } from '@costes/library/store/language';
import {
  AppState,
  CloseNavigation,
  ErrorMessage,
  HideLayoutOverlays,
  SetLanguageNavigation,
  SetNewLayout,
  SetOnPageNavigation,
  ShowLayoutOverlays
} from '@costes/library/store/app';



export interface ContentStateModel {
  currentSection: string;
  pages: Map<string, any>;
  cacheKey: string;
}

@State<ContentStateModel>({
  name: 'content',
  defaults: {
    currentSection: 'home',
    pages: new Map(),
    cacheKey: '',
  },
})
@Injectable()
export class ContentState {
  constructor(
    private aRef: ApplicationRef,
    private http: HttpClient,
    private store: Store,
    private router: Router,
    private serializer: UrlSerializer,
  ) { }

  @Selector()
  public static getState(state: ContentStateModel) {
    return state;
  }

  @Selector()
  public static getCurrentSection(state: ContentStateModel) {
    return state.currentSection;
  }

  @Selector()
  static getPageContent(state: ContentStateModel) {
    return state.pages.get(state.cacheKey);
  }
  @Selector()
  static getContent(state: ContentStateModel) {
    return (url: string) => {
      return state.pages.get(url);
    };
  }
  @Selector()
  static getHomeContent(state: ContentStateModel) {
    return (currentLang: string) => {
      const pages = state.pages;
      const page = pages.get(`/${currentLang}-home`);
      return page;
    };
  }

  @Action(SetCurrentPageCacheKey)
  public setCurrentPageCacheKey(
    { getState, setState, dispatch }: StateContext<ContentStateModel>,
    { cacheKey }: SetCurrentPageCacheKey
  ) {
    const state = getState();
    setState({
      ...state,
      cacheKey,
    });
  }

  @Action(SetSectionName)
  setSectionName(
    { patchState }: StateContext<ContentStateModel>,
    { sectionName }: SetSectionName
  ) {
    patchState({ currentSection: sectionName });
  }

  @Action(GetHome, { cancelUncompleted: true })
  public getHome(
    { getState, patchState, dispatch }: StateContext<ContentStateModel>,
    { }: GetHome
  ) {
    const currentLang = this.store.selectSnapshot(LanguageState.getLanguage);
    const currentLanguageSiteEndpoint = this.store.selectSnapshot(
      LanguageState.getCurrentLanguageSiteEndpoint
    );

    const cacheKey = `/${currentLang}-home`;

    dispatch(new HideLayoutOverlays());

    if (
      getState().pages.has(cacheKey) &&
      this.store.selectSnapshot(AppState.isInitialized)
    ) {
      return dispatch(
        new GetHomeSuccess(cacheKey, getState().pages.get(cacheKey))
      );
    } else {
      return this.http.get('/api/home.json', {
        withCredentials: true,
        params: {
          site: currentLanguageSiteEndpoint,
        },
      })
        .pipe(
          map((resp: any) => {
            if (resp['error']) {
              dispatch(new ErrorMessage(resp['error']));
              return throwError(() => new Error('Error while receiving page'));
            } else {
              return dispatch(new GetHomeSuccess(cacheKey, resp));
            }
          }),
          catchError((error) => {
            dispatch(new ErrorMessage(error));
            return throwError(() => new Error('Error while receiving page'));
          })
        );
    }
  }
  @Action(GetHomeSuccess)
  public getHomeSuccess(
    { getState, patchState, dispatch }: StateContext<ContentStateModel>,
    { cacheKey, page }: GetHomeSuccess
  ) {
    const state = getState();
    if (!state.pages) state.pages = new Map();

    state.pages.set(cacheKey, page);

    dispatch(new SetCurrentPageCacheKey(cacheKey));
    dispatch(new SetNewLayout(page));

    patchState(state);

    if (page.navigation && page.navigation.data.length > 0) {
      dispatch(new SetOnPageNavigation(page.navigation.data, true));
    } else {
      dispatch(new SetOnPageNavigation([], false));
    }
    dispatch(new SetLanguageNavigation(page.languageUrls));
    dispatch(new SetSectionName(page.section));
    this.aRef.tick();
  }

  @Action(GetPage, { cancelUncompleted: true })
  public getPage(
    { getState, patchState, dispatch }: StateContext<ContentStateModel>,
    { lang, uri, queryParams, cacheKey }: GetPage
  ) {
    const currentLanguageSiteEndpoint = this.store.selectSnapshot(
      LanguageState.getLanguageSiteEndpoint
    )(lang);

    dispatch(new HideLayoutOverlays());

    if (
      getState().pages.has(cacheKey) &&
      this.store.selectSnapshot(AppState.isInitialized)
    ) {

      return dispatch(
        new GetPageSuccess(cacheKey, getState().pages.get(cacheKey))
      );
    } else {
      return this.http.get('/api/route.json', {
        withCredentials: true,
        params: {
          uri,
          site: currentLanguageSiteEndpoint,
          ...queryParams,
        },
      })
        .pipe(
          map((resp: any) => {
            if (resp['error']) {
              dispatch(new ErrorMessage(resp['error']));
              return throwError(() => new Error('Error while receiving page'));
            } else {
              return dispatch(new GetPageSuccess(cacheKey, resp));
            }
          }),
          catchError((error) => {
            dispatch(new ErrorMessage(error));
            return throwError(() => new Error('Error while receiving page'));
          })
        );
    }
  }
  @Action(GetPageSuccess)
  public getPageSuccess(
    { getState, patchState, dispatch }: StateContext<ContentStateModel>,
    { cacheKey, page }: GetPageSuccess
  ) {
    const state = getState();
    if (!state.pages) state.pages = new Map();

    state.pages.set(cacheKey, page);

    /**
     * Redirect to first child if we have the bridge layout!
     */
    if (page.layout === 'bridge' && page.items && page.items.data.length > 0) {
      dispatch([
        new Navigate([
          page.items.data.at(0).url.replace(/^(?:\/\/|[^\//]+)*\//, '/'),
        ]),
        new ShowLayoutOverlays(),
        new CloseNavigation(),
      ]);
      return;
    }

    dispatch(new SetCurrentPageCacheKey(cacheKey));
    dispatch(new SetNewLayout(page));

    patchState(state);

    if (page.navigation && page.navigation.data.length > 0) {
      dispatch(new SetOnPageNavigation(page.navigation.data, true));
    } else {
      dispatch(new SetOnPageNavigation([], false));
    }
    dispatch(new SetLanguageNavigation(page.languageUrls));
    dispatch(
      new SetSectionName(
        page.section ? page.section : page.shopSections ? 'shop' : 'home'
      )
    );
    this.aRef.tick();
  }
}
