import { DOCUMENT, isPlatformBrowser } from "@angular/common";
import { inject, Inject, Injectable, InjectionToken, PLATFORM_ID } from "@angular/core";

export const ENVIRONMENT = new InjectionToken<Environment>('environment');

export interface Environment {
    production: boolean;
    googleAnalytics: string;
}

@Injectable({
    providedIn: "root"
})
export class LoadAnalyticsService {

    private readonly environment = inject(ENVIRONMENT);

    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        @Inject(DOCUMENT) private document: Document,
    ) { }

    public loadGTM() {
        return new Promise((resolve, reject) => {
            if (!isPlatformBrowser(this.platformId)) return resolve(null);
            if (!this.environment.googleAnalytics) return resolve(null);
            const contentLoaded = () => {
                this.document.removeEventListener('DOMContentLoaded', contentLoaded);
                this.addGTMEventListener();
                resolve(null);
            };
            if (this.document.readyState === 'complete' || this.document.readyState === 'interactive') {
                // if DOMContentLoaded already fired
                // document is already ready to go
                this.addGTMEventListener();
                resolve(null);
            } else {
                // if DOMContentLoaded is not fired then we are listening the event.
                this.document.addEventListener('DOMContentLoaded', contentLoaded, {
                    passive: true
                });
            }
        });
    }

    private addGTMEventListener() {
        if (!isPlatformBrowser(this.platformId)) return;
        // In production mode
        if (this.environment.production) {
            (window as any)['dataLayer'] = [];
            /** init gtm after 3500 seconds - this could be adjusted */
            setTimeout(() => this.initGTM(this.environment), 3500);

            document.addEventListener('scroll', this.initGTMOnEvent.bind(this), {
                passive: true
            });
            document.addEventListener('mousemove', this.initGTMOnEvent.bind(this), {
                passive: true
            }); document.addEventListener('touchstart', this.initGTMOnEvent.bind(this), {
                passive: true
            });
        }
        // In development mode
        else {
            const scriptGTM = document.createElement('script');
            scriptGTM.innerHTML =
                "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':\n" +
                "new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],\n" +
                "j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=\n" +
                "'https://www.googletagmanager.com/gtag/js?id='+i+dl;f.parentNode.insertBefore(j,f);\n" +
                "})(window,document,'script','dataLayer','" +
                this.environment.googleAnalytics +
                "');";
            document.body.appendChild(scriptGTM);
        }

    }

    private initGTMOnEvent(event: any) {
        this.initGTM();
        event.currentTarget.removeEventListener(event.type, this.initGTMOnEvent); // remove the event listener that got triggered
    }
    private initGTM(environment: Environment = this.environment) {
        if ((window as any)['gtmDidInit']) {
            return;
        }
        (window as any)['gtmDidInit'] = true; // flag to ensure script does not get added to DOM more than once.
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;

        script.onload = () => {
            if (environment.production) {
                // console.log('gtm.start', new Date().getTime());
                (window as any)['dataLayer'].push({ event: 'gtm.js', 'gtm.start': new Date().getTime(), 'gtm.uniqueEventId': 0 });
            }; // This part ensures PageViews is always tracked.
        };

        script.src = 'https://www.googletagmanager.com/gtag/js?id=' + environment.googleAnalytics;
        document.head.appendChild(script);
    }
}