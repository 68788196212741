import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { Audio } from 'typings';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { FormsModule } from '@angular/forms';
import { A11yModule } from '@angular/cdk/a11y';
import { AudioSoundscapeItemDirective } from './audio-soundscape-item.directive';
import { PlayerState, SetVolume, SoundscapeState } from '@costes/library/store/soundscape';
import { PlayPauseIconModule } from '@costes/library';

@Component({
  selector: 'costes-audio-soundscape-player',
  standalone: true,
  imports: [
    CommonModule,
    AudioSoundscapeItemDirective,
    FormsModule,
    A11yModule,
    PlayPauseIconModule,
  ],
  templateUrl: './audio-soundscape-player.component.html',
  styleUrls: ['./audio-soundscape-player.component.scss'],
})
export class AudioSoundscapePlayerComponent implements OnInit, AfterViewInit {
  @Select(SoundscapeState.getTracks)
  audioTracks$!: Observable<Map<number, Audio>>;

  @Select(SoundscapeState.getActiveTrack)
  currentTrack$!: Observable<number>;

  @Select(SoundscapeState.getState)
  playerState$!: Observable<PlayerState>;

  @ViewChildren(AudioSoundscapeItemDirective)
  trackElRefs!: QueryList<AudioSoundscapeItemDirective>;

  @ViewChild('volumeBar', { static: true }) volumeBar?: ElementRef;
  @ViewChild('volumeIndicator', { static: true })
  volumeIndicator?: ElementRef;

  volume: number = 0;
  hideVolumeTimeout?: NodeJS.Timeout;

  constructor(private store: Store) {
    this.store.select(SoundscapeState.getVolume).subscribe((volume) => {
      this.volume = volume;
    });
  }

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    this.currentTrack$.subscribe((trackId) => {
      if (trackId) {
        setTimeout(
          () =>
            this.trackElRefs?.forEach((track) =>
              track.id == trackId ? track.fadeIn() : track.fadeOut()
            ),
          0
        );
      }
    });
  }

  show: boolean = false;
  showVolume() {
    if (!this.trackElRefs?.length) return;
    const state = this.store.selectSnapshot(SoundscapeState.getState);
    if (state == 'pause') this.startSoundscape();
    this.show = !this.show;
    if (this.show) {
      this.volumeBar?.nativeElement.focus();
      this.hideVolumeTimeout = setTimeout(() => {
        this.blurVolume();
      }, 3000);
    }
  }
  blurVolume(event?: Event) {
    this.volumeBar?.nativeElement.blur();
    this.show = false;
  }

  setVolume(volume: number) {
    clearTimeout(this.hideVolumeTimeout);
    this.store.dispatch(new SetVolume(volume));
    this.hideVolumeTimeout = setTimeout(() => {
      this.blurVolume();
    }, 3000);
  }

  startSoundscape() {
    setTimeout(() => {
      const trackId = this.store.selectSnapshot(SoundscapeState.getActiveTrack);
      this.trackElRefs?.forEach((track, index) => {
        (trackId ? track.id == trackId : index > 0)
          ? track.fadeOut()
          : track.fadeIn();
      });
      this.store.dispatch(new SetVolume(0.5));
    }, 0);
  }

  wheelSetVolume(event: WheelEvent) {
    // Normalize volume so its between 0.00 and 1.00
    const volume = this.volume + Math.round(event.deltaY / 10) / 100;
    this.store.dispatch(new SetVolume(volume));
    // event.preventDefault(); // not working for passive events
    event.stopPropagation();
  }

  trackByFn(index: number, item: any) {
    return item.key; // or item.id
  }
}
