import { Component, OnInit } from '@angular/core';
import { Select, Store, Actions, ofActionSuccessful } from '@ngxs/store';

import { Observable } from 'rxjs';
import { Cart, CartErrors, Currency, Currencies, LineItem } from 'typings';

import { map, switchMap } from 'rxjs/operators';
import { UntilDestroy } from '@ngneat/until-destroy';

import { AddToCart, ApplyCoupon, HideCart, ShopState, UpdateCart, UpdateCurrency } from '@costes/library/store/shop';
import { fadeInOut, moveInFromRight } from '@costes/library/animations';
import { SendGoogleAnalyticsEvent } from '@costes/library/store/app';
import { LanguageState } from '@costes/library/store/language';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'costes-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
  animations: [fadeInOut, moveInFromRight],
})
export class CartComponent implements OnInit {
  @Select(ShopState.getCart) cart$!: Observable<Cart>;
  @Select(ShopState.getCartErrors) errors$!: Observable<CartErrors>;
  @Select(ShopState.getAllPaymentCurrency)
  allPaymentCurrencies$!: Observable<Currencies>;

  paymentCurrency?: Currency;

  @Select(LanguageState.getLanguage) language$!: Observable<string>;

  checkoutURL$?: Observable<string>;

  constructor(private store: Store, private actions$: Actions) {
    this.actions$.pipe(ofActionSuccessful(AddToCart)).subscribe();
  }

  ngOnInit() {
    this.checkoutURL$ = this.language$?.pipe(
      map((lang) => {
        return `/${lang}/checkout`;
      })
    );
    this.store
      .select(ShopState.getPaymentCurrency)
      .subscribe((currency) => (this.paymentCurrency = currency));
  }

  removeFromCart(lineItemID: number) {
    this.store
      .dispatch(
        new UpdateCart({
          action: 'commerce/cart/update-cart',
          [`lineItems[${lineItemID}][remove]`]: true,
        })
      )
      .pipe(
        switchMap(() =>
          this.store.dispatch(
            new SendGoogleAnalyticsEvent(
              'remove_from_cart',
              'shop',
              'cart',
              'click',
              10
            )
          )
        )
      );
  }
  increaseQty(lineItemID: number, currentQty: number) {
    this.store
      .dispatch(
        new UpdateCart({
          action: 'commerce/cart/update-cart',
          cartUpdatedNotice: 'Added one more item',
          [`lineItems[${lineItemID}][qty]`]: Number(currentQty) + 1,
        })
      )
      .pipe(
        switchMap(() =>
          this.store.dispatch(
            new SendGoogleAnalyticsEvent(
              'add_to_cart',
              'shop',
              'cart',
              'click',
              10
            )
          )
        )
      );
  }
  decreaseQty(lineItemID: number, currentQty: number) {
    if (currentQty > 0) {
      this.store
        .dispatch(
          new UpdateCart({
            action: 'commerce/cart/update-cart',
            [`lineItems[${lineItemID}][qty]`]: Number(currentQty) - 1,
          })
        )
        .pipe(
          switchMap(() =>
            this.store.dispatch(
              new SendGoogleAnalyticsEvent(
                'remove_from_cart',
                'shop',
                'cart',
                'click',
                10
              )
            )
          )
        );
    } else {
      this.removeFromCart(lineItemID);
    }
  }
  updateNote(lineItemID: string, note: string) {
    this.store.dispatch(
      new UpdateCart({
        action: 'commerce/cart/update-cart',
        [`lineItems[${lineItemID}][note]`]: note,
      })
    );
  }

  applyCoupon(couponCode: string) {
    this.store.dispatch(new ApplyCoupon(couponCode));
  }

  updateCurrency(iso: string) {
    this.store.dispatch(new UpdateCurrency(iso));
  }
  hideCart() {
    this.store.dispatch(new HideCart());
  }
  trackByFn(index: number, item: LineItem) {
    return item.id; // or item.id
  }
}
