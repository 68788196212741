import { Component, OnInit } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';

import { NavigationStateInterface, NavigationState, SendGoogleAnalyticsEvent } from '@costes/library/store/app';
import { ReplacePipe } from '@costes/library/pipes/replace';

@Component({
  selector: 'costes-social-media',
  standalone: true,
  imports: [CommonModule, NgOptimizedImage, ReplacePipe],
  templateUrl: './social-media.component.html',
  styleUrls: ['./social-media.component.scss'],
})
export class SocialMediaComponent implements OnInit {
  webLinks$: Observable<NavigationStateInterface<any[]>>;

  constructor(private store: Store) {
    this.webLinks$ = this.store.select(
      NavigationState.getWebLinks
    ) as Observable<NavigationStateInterface<any[]>>;
  }

  ngOnInit(): void { }

  sendGoogleAnalyticsEvent(
    event_name: string,
    event_category: string,
    event_label: string
  ) {
    this.store.dispatch(
      new SendGoogleAnalyticsEvent(event_name, event_category, event_label)
    );
  }
}
