import { HttpErrorResponse } from '@angular/common/http';

export class AppInitialized {
  public static readonly type = '[App] App Initialized';
  constructor() { }
}
export class ErrorMessage {
  public static readonly type = '[ERROR] An Error Occured';
  constructor(public error: HttpErrorResponse | any) { }
}
export class ErrorMessageDismiss {
  public static readonly type = '[ERROR] Dismiss Message';
  constructor() { }
}

export class SendGoogleAnalyticsEvent {
  public static readonly type = '[App] Send Google Analytics Event';
  constructor(
    public event_name: string,
    public event_category: string,
    public event_label?: string,
    public event_action?: string,
    public event_value?: number
  ) { }
}
export class SendGoogleEcommerceEvent {
  public static readonly type = '[App] Send Google Ecommerce Event';
  constructor(
    public event_name: string,
    public currency: string,
    public value: number,
    public items: Array<any>,
    public tax?: number,
    public shipping?: number,
    public coupon?: string,
    public shipping_tier?: string,
    public payment_type?: string
  ) { }
}

export class SetMutedState {
  public static readonly type = '[App] Set Muted State';
  constructor(public muted: boolean) { }
}
export class ToggleMuted {
  public static readonly type = '[App] Toggle Muted';
  constructor() { }
}