<!-- <form method="post" accept-charset="UTF-8" (submit)="register()">
  <input type="hidden" name="action" value="users/save-user" />
  <input type="hidden" name="redirect" value="" />

  <h3><label for="username">Username</label></h3>
  <input id="username" type="text" name="username" />

  <h3><label for="email">Email</label></h3>
  <input id="email" type="text" name="email" />

  <h3><label for="password">Password</label></h3>
  <input id="password" type="password" name="password" />

  <input type="submit" value="Register" />
</form> -->

<button class="button button-secondary" (click)="register()">Register</button>
<div class="error" [innerHtml]="userErrors$ | async"></div>
