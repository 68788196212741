import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, Route, UrlSegment } from '@angular/router';
import { Store } from '@ngxs/store';
import { of, Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { CheckLanguage, LanguageState, UpdateLanguage } from '@costes/library/store/language';

@Injectable({ providedIn: 'root' })
export class LanguageGuard {
  constructor(private store: Store, private router: Router) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const lang = route.data['lang'] ?? route.paramMap.get('lang');
    // const lang = route.paramMap.get('lang');
    if (!lang) {
      this.store.dispatch(new CheckLanguage());

      this.store.selectOnce(LanguageState.getLanguage).subscribe((lang) => {
        if (lang) this.router.navigate(['/', lang]);
        return of(false);
      });
    } else {
      const availableLanguages = this.store.selectSnapshot(
        LanguageState.getAvailableLanguages
      );

      if (availableLanguages.find((availableLang) => availableLang === lang)) {
        this.store.dispatch(new UpdateLanguage(lang));
        return of(true);
      } else {
        this.store.dispatch(new CheckLanguage());

        this.store.select(LanguageState.getLanguage).subscribe((lang) => {
          if (lang) this.router.navigate(['/', lang]);
          return of(false);
        });
      }
    }
    return of(false);
  }
  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> {
    this.store.dispatch(new CheckLanguage());

    return this.store.selectOnce(LanguageState.getLanguage).pipe(
      mergeMap((lang) => {
        if (lang) this.router.navigate(['/', lang]);
        return of(false);
      })
    );
  }
}
