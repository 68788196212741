import { Audio } from 'typings';
import { PlayerState } from './soundscape.state';

export class PlaySoundscape {
  static readonly type = '[Soundscape] Play Soundscape';
  constructor() { }
}

export class PauseSoundscape {
  static readonly type = '[Soundscape] Pause Soundscape';
  constructor() { }
}

export class SetSoundscapePlayerState {
  static readonly type = '[Soundscape] New State';
  constructor(public state: PlayerState) { }
}

export class SoundscapeStateLoadedMetaData {
  static readonly type = '[Soundscape] Has Loaded Metadata';
  constructor() { }
}

export class SoundscapeStatePlaying {
  static readonly type = '[Soundscape] Is Playing';
  constructor() { }
}

export class SoundscapeStatePaused {
  static readonly type = '[Soundscape] Is Paused';
  constructor() { }
}

export class SetVolume {
  static readonly type = '[Soundscape] Set Volume';
  constructor(public volume: number) { }
}
export class SetAudioTrack {
  static readonly type = '[Soundscape] Set new audio track';
  constructor(public track: Audio) { }
}

// export type PlayerState = "init" | "loadedmetadata" | "playing" | "pause" | "waiting" | "error";
