import {
  Directive,
  Renderer2,
  ElementRef,
  HostListener,
  OnInit,
  PLATFORM_ID,
  Inject,
  Input,
  OnDestroy,
} from '@angular/core';
import { Store } from '@ngxs/store';
import { gsap, Sine } from 'gsap';

import { isPlatformBrowser } from '@angular/common';
import { Audio } from 'typings';
import { CheckPlatformService } from '@costes/library';
import { SetSoundscapePlayerState, SetVolume, SoundscapeState } from '@costes/library/store/soundscape';

@Directive({
  selector: 'audio[soundscape]',
  standalone: true,
})
export class AudioSoundscapeItemDirective implements OnInit, OnDestroy {
  tweenObj = { volume: 0 };
  tweenCanPlayObj = { canPlay: 0 };
  tweenGlobalObj = { volume: 0.5 };

  @Input() src?: string;

  @Input() id?: number;

  @Input() data?: Audio;

  constructor(
    private checkPlatform: CheckPlatformService,
    private el: ElementRef,
    private store: Store,
    @Inject(PLATFORM_ID)
    private platformId: Object
  ) { }

  ngOnInit() {
    assertNoConflictingSrc(this);
    if (!isPlatformBrowser(this.platformId)) return;
    // this.store
    //   .select<string>(RadioState.getSrc)
    //   .pipe(distinctUntilChanged())
    //   .subscribe((src) => {
    //     this.renderer.setAttribute(this.el.nativeElement, 'src', '');
    //     this.renderer.setAttribute(this.el.nativeElement, 'src', src);
    //     this.el.nativeElement.load();
    //   });

    if (this.data?.url) {
      this.el.nativeElement.src = this.data.url;
      this.el.nativeElement.type = this.data.mimeType;
      this.el.nativeElement.load();
      this.el.nativeElement.loop = true;
      this.el.nativeElement.volume = this.tweenObj.volume;
    }
    if (!this.id) {
      this.id = this.data?.id;
    }
    this.start();

    this.store.select(SoundscapeState.getVolume).subscribe((volume) => {
      gsap.killTweensOf(this.tweenGlobalObj);
      gsap.to(this.tweenGlobalObj, {
        volume: volume,
        duration: 0.5,
        ease: Sine.easeInOut,
      });
      if (volume > 0 && this.paused) {
        this.play();
      } else if (volume === 0) {
        this.pause();
      }
    });
  }

  async ngOnDestroy(): Promise<void> {
    this.stop();
    if (this.pausePromise) await this.pausePromise;
    if (this.playPromise) await this.playPromise;
  }

  paused: boolean = true;

  playPromise?: Promise<any>;
  pausePromise?: Promise<any>;

  public async fadeIn() {
    gsap.killTweensOf(this.tweenObj);
    gsap.to(this.tweenObj, {
      volume: 1,
      duration: 3,
      ease: Sine.easeInOut,
      onStart: this.onAnimationStartPlay,
    });
  }
  public async fadeOut() {
    gsap.killTweensOf(this.tweenObj);
    gsap.to(this.tweenObj, {
      volume: 0,
      duration: 3,
      ease: Sine.easeInOut,
      onComplete: this.onAnimationCompletePause,
    });
  }

  private async play() {
    try {
      if (this.pausePromise) await this.pausePromise;
      this.playPromise = this.el.nativeElement?.play();
      if (this.playPromise) {
        await this.playPromise;
        this.store.dispatch(new SetSoundscapePlayerState('playing'));
        this.paused = false;
      }
    } catch (error) {
      console.error(error);
      this.store.dispatch([
        new SetVolume(0),
        new SetSoundscapePlayerState('pause'),
      ]);
      this.paused = true;
    }
  }
  private async pause() {
    try {
      if (this.playPromise) await this.playPromise;
      this.pausePromise = this.el.nativeElement?.pause();
      if (this.pausePromise) await this.pausePromise;
      this.paused = true;
      this.el.nativeElement.currentTime = 0;
    } catch (error) {
      console.error(error);
      this.store.dispatch([
        new SetVolume(0),
        new SetSoundscapePlayerState('pause'),
      ]);
    }
  }

  onAnimationStartPlay = () => {
    this.play();
  };
  onAnimationCompletePause = () => {
    this.pause();
  };

  @HostListener('canplay') onCanPlay() {
    gsap.killTweensOf(this.tweenCanPlayObj);
    gsap.to(this.tweenCanPlayObj, {
      canPlay: 1,
      duration: 3,
      ease: Sine.easeInOut,
    });
  }

  /**
   * Render Function
   */
  rAFrameNumber: number = 0;

  start = () => {
    this.render();
  };
  stop = () => {
    if (!this.checkPlatform.isPlatformBrowser) return;
    cancelAnimationFrame(this.rAFrameNumber);
  };

  render = () => {
    if (!this.el.nativeElement) return this.stop();
    gsap.set(this.el.nativeElement, {
      volume:
        this.tweenCanPlayObj.canPlay *
        this.tweenObj.volume *
        this.tweenGlobalObj.volume,
    });

    this.rAFrameNumber = requestAnimationFrame(() => this.render());
  };
}

function assertNoConflictingSrc(dir: AudioSoundscapeItemDirective) {
  if (dir.src) {
    console.error(
      'The AudioSoundscapeItemDirective directive sets `src` itself based on the value of `data` To fix this, please remove the `src` attribute.'
    );
  }
}
