import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit, Signal, signal } from '@angular/core';
import { Promobanner } from 'typings';
import { MediaComponent } from '@costes/library/components/media';
import { SharedModule } from '@costes/library';

@Component({
  selector: 'costes-promobanner-item',
  standalone: true,
  imports: [SharedModule, MediaComponent],
  templateUrl: './promobanner-item.component.html',
  styleUrls: ['./promobanner-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PromobannerItemComponent implements OnInit {
  readonly banner = signal<Promobanner>(null!);
  @Input('data') set _banner(value: Promobanner) {
    this.banner.set(value);
  }

  @HostBinding('class') get classes() {
    return `${this.banner().itemSize ?? ''} ${this.banner().coverMedia?.image?.orientation ?? this.banner().coverMedia?.video?.orientation
      } ${this.banner().backgroundColor && this.banner().coverImageFitToGrid
        ? 'outline'
        : ''
      }`;
  }
  @HostBinding('style.background-color') get backgroundColor() {
    return this.banner().backgroundColor ?? '';
    // return this.banner().coverMedia ? '' : (this.banner().backgroundColor ?? '');
  }
  @HostBinding('style.outline-color') get outlineColor() {
    return this.banner().backgroundColor ?? '';
  }

  constructor() { }

  ngOnInit(): void { }
}
