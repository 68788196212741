import { Details } from "typings";
import { LayoutStateModel, LayoutName, PanelName } from "./layout.state";

export class SetNewLayout {
  public static readonly type = "[Layout] Set New Layout";
  constructor(public page: any) {}
}

// export class HideOldLayout {
//   public static readonly type = "[Layout] Hide Old Layout";
//   constructor() {}
// }

export class ActivateNewLayout {
  public static readonly type = "[Layout] Activate New Layout";
  constructor() {}
}

export class HideLayoutOverlays {
  public static readonly type = "[Layout] Hide Layout Overlays";
  constructor() {}
}

export class ShowLayoutOverlays {
  public static readonly type = "[Layout] Show Layout Overlays";
  constructor() {}
}

// export class TogglePanel {
//   public static readonly type = "[Layout] Toggle Panel";
//   constructor(public panel: PanelName) {}
// }
export class HomepageVisited {
  public static readonly type = "[Layout] User visited the homepage";
  constructor() {}
}
