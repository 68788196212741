import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';

import { Store } from '@ngxs/store';
import { firstValueFrom } from 'rxjs';

import {
  LoadAnalyticsService,
} from '@costes/library';

import {
  GetAllRoutesGQL,
  EntryRouteConfigFragment,
  CategoryRouteConfigFragment,
  ProductRouteConfigFragment,
} from '@costes/library/graphql';

import {
  ShopResolver,
  FrontpageResolver,
  PageResolver,
  ProductResolver,
} from '@costes/library/resolvers';
import { CheckLanguage, LanguageState } from '@costes/library/store/language';
import { ErrorGuard, LanguageGuard } from '@costes/library/guards';

export function init(app: AppService): any {
  return () => app.initRoutes();
}

@Injectable({
  providedIn: 'root',
})
export class AppService {
  private router = this.injector.get(Router);
  private store = this.injector.get(Store);
  private loadAnalyticsService = this.injector.get(LoadAnalyticsService);

  constructor(
    private injector: Injector,
    private getAllRoutesGQL: GetAllRoutesGQL,
  ) { }

  /*
    controls the loading of the backend content api endpoint
    and returns a resolved promise if everthing is fine
  */
  initRoutes(): Promise<any> {
    return new Promise((resolve, reject) =>
      Promise.all([this.addInitialRedirect(), this.loadBackendData(), this.loadAnalyticsService.loadGTM()]).then(
        () => {
          this.router.initialNavigation();
          // console.debug('initRoutes() resolved');
          return resolve(null);
        }
      )
    );
  }

  /*
    load content data from the backend for the
    unauthenticated user and stores this to the localStorage item called content
  */
  private loadBackendData(): Promise<any> {
    return new Promise((resolve, reject) => {
      // console.debug('loadBackendData()');
      this.getAllRoutesGQL
        .fetch(
          {},
          {
            fetchPolicy: 'cache-first',
          }
        )
        .subscribe(
          (response: any) => {
            // console.debug('loadBackendData()', response);

            response.data?.entries?.forEach((entry: EntryRouteConfigFragment) =>
              this.addRoutes(entry)
            );
            response.data?.categories?.forEach(
              (entry: CategoryRouteConfigFragment) => this.addRoutes(entry)
            );
            response.data?.products?.forEach(
              (entry: ProductRouteConfigFragment) => this.addRoutes(entry)
            );

            // response.data?.categories?.map((entry: any) => this.addRoutes(entry));
            // console.debug('loadBackendData() resolved');
            resolve(true);
          },
          (err: any) => {
            reject(err);
          }
        );
    });
  }

  private async addInitialRedirect() {
    await firstValueFrom(this.store.dispatch(new CheckLanguage()));

    const lang = this.store.selectSnapshot(LanguageState.getLanguage);
    this.router.config?.splice(0, 0, {
      path: '**',
      canActivate: [ErrorGuard],
    });
    this.router.config?.splice(0, 0, {
      path: '',
      redirectTo: lang,
      pathMatch: 'full',
    });
  }

  /*
    this function injects the routes into the angular route
    and delivers and state also an guard for registred and unregistered content
  */
  private addRoutes(
    entry:
      | EntryRouteConfigFragment
      | CategoryRouteConfigFragment
      | ProductRouteConfigFragment
  ): void {
    if (entry.url) {
      const relativeUrl = entry.url
        ?.replace(/^(?:\/\/|[^/]+)*\//, '')
        .replace(/\/$/, '')
        .replace(/#.*$/, '')
        .replace(/\?.*$/, '');
      // console.log(this.router.config);
      switch (entry.__typename) {
        case 'home_home_Entry':
          this.router.config?.splice(0, 0, {
            path: relativeUrl,
            loadChildren: () =>
              import('./page/page.module').then((m) => m.PageModule),
            data: {
              frontpage: true,
              id: entry.canonicalId,
              siteId: entry.siteId,
              lang: entry.language,
              reuse: 'page',
            },
            resolve: {
              page: FrontpageResolver,
            },
            canActivate: [LanguageGuard],
          });
          break;

        case 'shop_shop_Entry':
        case 'shopSections_Category':
          // console.log(relativeUrl);
          this.router.config?.splice(0, 0, {
            path: relativeUrl,
            loadChildren: () =>
              import('./shop/shop.module').then((m) => m.ShopModule),
            data: {
              id: entry.id,
              siteId: entry.siteId,
              lang: entry.language,
              reuse: 'shop',
            },
            resolve: {
              page: ShopResolver,
            },
            runGuardsAndResolvers: 'paramsOrQueryParamsChange',
            canActivate: [LanguageGuard],
          });
          break;

        case 'goods_Product':
        case 'music_Product':
        case 'services_Product':
          // console.log(relativeUrl);
          this.router.config?.splice(0, 0, {
            path: relativeUrl,
            loadChildren: () =>
              import('./product/product.module').then((m) => m.ProductModule),
            data: {
              id: entry.id,
              siteId: entry.siteId,
              lang: entry.language,
            },
            resolve: {
              page: ProductResolver,
            },
            runGuardsAndResolvers: 'paramsOrQueryParamsChange',
            canActivate: [LanguageGuard],
          });
          break;

        default:
          this.router.config?.splice(0, 0, {
            path: relativeUrl,
            loadChildren: () =>
              import('./page/page.module').then((m) => m.PageModule),
            data: {
              id: (entry as any)?.canonicalId ?? entry.id,
              siteId: entry.siteId,
              lang: entry.language,
              reuse: 'page',
            },
            resolve: {
              page: PageResolver,
            },
            runGuardsAndResolvers: 'paramsOrQueryParamsChange',
            canActivate: [LanguageGuard],
          });
          break;
      }
    }
  }
}
