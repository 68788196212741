import {
  Injectable,
  NgZone,
  Inject,
  Renderer2,
  RendererFactory2,
} from '@angular/core';
// import * as verge from 'verge';
const verge = require('verge');
import { BehaviorSubject, fromEvent } from 'rxjs';
import { CheckPlatformService } from './check-platform.service';
import { DOCUMENT } from '@angular/common';

export type Dimensions = {
  vW: number;
  vH: number;
  vInnerH: number;
  landscape: boolean;
  remSizeInPixel: number;
  isMobile: boolean;
};

@Injectable({ providedIn: 'root' })
export class DimensionsService {
  private renderer!: Renderer2;

  private dimensionsState = new BehaviorSubject<Dimensions>({
    vW: 0,
    vH: 0,
    vInnerH: 0,
    landscape: true,
    remSizeInPixel: 16,
    isMobile: true,
  });

  get isLandscape() {
    return this.dimensionsState.value.landscape;
  }
  get isMobile() {
    return this.dimensionsState.value.isMobile;
  }

  public dimensions = this.dimensionsState.asObservable();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private ngZone: NgZone,
    private checkPlatform: CheckPlatformService,
    private rendererFactory: RendererFactory2
  ) {
    if (!this.checkPlatform.isPlatformBrowser) return;

    this.renderer = this.rendererFactory.createRenderer(null, null);
    fromEvent(window, 'resize').subscribe(() =>
      this.ngZone.runOutsideAngular(() => this.getDimensions())
    );
    this.ngZone.runOutsideAngular(() => this.getDimensions());
  }
  private oldVw?: number;
  private oldVh?: number;
  private oldLanscape?: boolean;
  private oldRemSizeInPixel?: number;
  private oldIsMobile?: boolean;
  private getDimensions() {
    const vW = verge.viewportW();
    const vH = verge.viewportH();
    const landscape = 1 < verge.aspect();
    const remSizeInPixel = parseFloat(
      // of the computed font-size, so in px
      getComputedStyle(
        // for the root <html> element
        this.document.documentElement
      ).fontSize
    );
    const vInnerH = vH - 6 * remSizeInPixel;

    const isMobile = vW < 768 ? true : false;

    this.ngZone.run(() => {
      this.dimensionsState.next({
        vW,
        vH,
        vInnerH,
        landscape,
        remSizeInPixel,
        isMobile,
      });
      if (
        this.oldVw === vW &&
        this.oldVh === vH &&
        this.oldLanscape === landscape &&
        this.oldRemSizeInPixel === remSizeInPixel &&
        this.oldIsMobile === isMobile
      ) {
        return;
      }
      // console.log(
      //   this.oldVw,
      //   vW,
      //   this.oldVh,
      //   vH,
      //   this.oldLanscape,
      //   landscape,
      //   this.oldRemSizeInPixel,
      //   remSizeInPixel,
      //   this.oldIsMobile,
      //   isMobile
      // );
      if (
        this.oldVw !== vW ||
        !this.checkPlatform.iOS ||
        (this.checkPlatform.iOS && this.oldVh! <= vH)
      ) {
        this.renderer.setAttribute(
          this.document.body,
          'style',
          `--vh: ${vH / 100}px`
        );
      }
      this.oldVw = vW;
      this.oldVh = vH;
      this.oldLanscape = landscape;
      this.oldRemSizeInPixel = remSizeInPixel;
      this.oldIsMobile = isMobile;
    });
  }
}
