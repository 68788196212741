import { PlayerState } from './radio.state';
import { Item } from 'typings';

export class PlayRadio {
  static readonly type = '[Radio] Play Radio';
  constructor() {}
}

export class PauseRadio {
  static readonly type = '[Radio] Pause Radio';
  constructor() {}
}

export class SetNewSrc {
  static readonly type = '[Radio] New Src';
  constructor(
    public src: string,
    public title: string = 'Radio Costes',
    public link: Item
  ) {}
}

export class SetRadioPlayerState {
  static readonly type = '[Radio] New State';
  constructor(public state: PlayerState) {}
}

export class RadioStateLoadedMetaData {
  static readonly type = '[Radio] Has Loaded Metadata';
  constructor() {}
}

export class RadioStatePlaying {
  static readonly type = '[Radio] Is Playing';
  constructor() {}
}

export class RadioStatePaused {
  static readonly type = '[Radio] Is Paused';
  constructor() {}
}
export class RadioStateWaiting {
  static readonly type = '[Radio] Is Buffering';
  constructor() {}
}
export class RadioStateError {
  static readonly type = '[Radio] Has Errored';
  constructor() {}
}

// export type PlayerState = "init" | "loadedmetadata" | "playing" | "pause" | "waiting" | "error";
