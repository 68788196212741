import {
  CommonModule,
  ImageLoaderConfig,
  IMAGE_LOADER,
  registerLocaleData,
} from '@angular/common';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { APP_ID, APP_INITIALIZER, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {

  ENVIRONMENT,

  LoadAnalyticsService,
  OnAppLoadService,
  ON_APP_LOAD_CLASS,
  SharedModule,

} from '@costes/library';
import { SoundscapeState } from '@costes/library/store/soundscape';
import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { RouteReuseStrategy } from '@angular/router';

import localeEs from '@angular/common/locales/es';
import localeFr from '@angular/common/locales/fr';
import localeIt from '@angular/common/locales/it';

import { InlineSVGModule } from 'ng-inline-svg-2';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxsModule, Store } from '@ngxs/store';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';

import {
  BrowserAnimationsModule,
} from '@angular/platform-browser/animations';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { LoadingBarModule } from '@ngx-loading-bar/core';

import { environment } from '../environments/environment';
import { AppRouteReuseStrategy } from './app-routing.reuse-strategy';


import {
  CraftSecurityInterceptor,
  CraftPreviewInterceptor,
  // RadioState,
  // RadioPlayerDirective,
} from '@costes/library';
import { GraphQLModule } from '@costes/library/graphql';
import { BookingSlideoutComponent, BookingServiceItem, BOOKING_SERVICES } from '@costes/library/components/booking';
import { AudioSoundscapePlayerComponent } from '@costes/library/components/audio-soundscape-player';
import { ShopState } from '@costes/library/store/shop';
import { AppState, LayoutState, NavigationState } from '@costes/library/store/app';
import { ContentState } from '@costes/library/store/content';
import { LanguageState } from '@costes/library/store/language';

import { AppService, init } from './app.service';
import { LogoComponent } from './utils/components/logo/logo.component';
import { FooterComponent } from '@costes/library/components/footer';
import { HeaderComponent } from '@costes/library/components/header';
import { AddToCartMessageModule, ErrorMessageModule } from '@costes/library/components/messages';
import { CartModule } from '@costes/library/components/shop';
import { SocialMediaComponent } from '@costes/library/components/social-media';

const translateFactory = (http: HttpClient) =>
  new TranslateHttpLoader(http, './assets/i18n/', '.json?v=1.0.8');

registerLocaleData(localeEs, 'es');
registerLocaleData(localeFr, 'fr');
registerLocaleData(localeIt, 'it');

const plagePalaceBookingServices: Array<BookingServiceItem> = [
  {
    type: "form",
    name: 'booking.room.name',
    button: 'booking.room.button',
    url: 'booking.room.url',
    params: [
      {
        key: 'arrivalDate',
        name: 'booking.room.arrivalDate.name',
        type: 'date',
        format: 'YYYY-MM-dd',
        validate: 'from',
      },
      {
        key: 'departureDate',
        name: 'booking.room.departureDate.name',
        type: 'date',
        format: 'YYYY-MM-dd',
        validate: 'to',
      },
      {
        key: 'selectedAdultCount',
        name: 'booking.room.selectedAdultCount.name',
        type: 'number',
        inputmode: 'numeric',
        max: 10,
        value: 2,
        placeholder: 'booking.room.selectedAdultCount.placeholder',
        class: "full"
      },
      {
        key: 'selectedChildCount',
        name: 'booking.room.selectedChildCount.name',
        type: 'number',
        inputmode: 'numeric',
        max: 10,
        value: 0,
        placeholder: 'booking.room.selectedChildCount.placeholder',
        hint: 'booking.room.selectedChildCount.hint',
        class: "full"
      },
      // {
      //   key: 'selectedChildCount',
      //   type: 'number',
      //   max: 10,
      //   placeholder: 'Children',
      // },
      // {
      //   key: 'selectedInfantCount',
      //   type: 'number',
      //   max: 10,
      //   placeholder: 'Infants',
      // }
    ],
    // note: 'booking.room.note'
  },
  // {
  //   type: "form",
  //   name: 'booking.beach.name',
  //   button: 'booking.beach.button',
  //   url: 'booking.beach.url',
  //   params: [
  //     {
  //       key: 'pax',
  //       name: 'booking.beach.pax.name',
  //       type: 'number',
  //       inputmode: 'numeric',
  //       max: 14,
  //       value: 2,
  //       placeholder: 'booking.beach.pax.placeholder',
  //     },
  //     {
  //       key: 'day',
  //       name: 'booking.beach.day.name',
  //       type: 'date',
  //       format: 'YYYY-MM-dd',
  //       validate: 'from',
  //     },

  //   ],
  // },
  {
    type: "link",
    name: 'booking.restaurant.name',
    button: 'booking.restaurant.button',
    url: 'booking.restaurant.url',
    note: 'booking.restaurant.note'
  },
  {
    type: "link",
    name: 'booking.spa.name',
    button: 'booking.spa.button',
    url: 'booking.spa.url',
    note: 'booking.spa.note'
  },
  // {
  //   type: "entry",
  //   name: 'Book a massage',
  //   button: 'Call the spa',
  //   url: '/fr',
  // }
]

@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HammerModule,

    // NoopAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    GraphQLModule,
    TranslateModule.forRoot({
      useDefaultLang: true,
      loader: {
        provide: TranslateLoader,
        useFactory: translateFactory,
        deps: [HttpClient],
      },
    }),
    NgxsModule.forRoot(
      [
        AppState,
        LayoutState,
        NavigationState,
        LanguageState,
        ContentState,
        ShopState,
        ///////// FEATURES /////////
        // RadioState,
        SoundscapeState,
      ],
      {
        // developmentMode: !environment.production
      }
    ),
    NgxsStoragePluginModule.forRoot({ key: ['shop'] }),
    NgxsRouterPluginModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot({
      disabled: environment.production,
    }),

    InlineSVGModule.forRoot(),
    SharedModule.forRoot(),
    LoadingBarRouterModule,
    LoadingBarModule,
    HeaderComponent,
    LogoComponent,
    FooterComponent,
    // CartModule,
    AddToCartMessageModule,
    ///////// FEATURES /////////
    AudioSoundscapePlayerComponent,
    SocialMediaComponent,
    ErrorMessageModule,
    BookingSlideoutComponent
    ///////// FEATURES /////////
    // RadioPlayerDirective,
  ],
  providers: [
    OnAppLoadService,
    {
      provide: APP_ID,
      useValue: 'plage-palace'
    },
    {
      provide: ON_APP_LOAD_CLASS,
      useFactory: () => 'fade-out',
    },
    {
      provide: RouteReuseStrategy,
      useClass: AppRouteReuseStrategy,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CraftSecurityInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CraftPreviewInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: init,
      deps: [AppService, Store, LoadAnalyticsService],
      multi: true,
    },
    {
      provide: BOOKING_SERVICES,
      useValue: plagePalaceBookingServices
    },
    {
      provide: ENVIRONMENT,
      useValue: environment,
    },
    {
      provide: IMAGE_LOADER,
      useValue: (config: ImageLoaderConfig) => {
        return `https://costes-group.imgix.net/${config.src}?auto=format,compress&w=${config.width?.toString() ?? 375}`
      }
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
